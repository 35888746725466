<template>
  <CrmFormLayout :title="formType === 1 ? '添加抬头' : '编辑抬头'" @close="close">
    <el-form ref="form" label-position="top" :show-message="true" :rules="formType === 3 ? rulese : rules" :model="formData">
      <div class="mali-edit__form input-fix">
        <el-row :gutter="80">
          <el-col :span="12">
            <el-form-item label="抬头" prop="consigneeShortName">
              <el-select
                v-model="formData.consigneeShortName"
                placeholder="请输入抬头"
                class="mali-full__input"
                filterable
                remote
                @change="getupheadList('')"
                :filter-method="getupheadList"
              >
                <el-option v-for="item in headList" :key="item.businessId" :label="item.shortName" :value="item.shortName"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
      </div>
    </el-form>
    <div class="crm-button__group">
      <button class="crm-form__button primary" @click="submit" v-if="formType < 3">保存</button>
      <button class="crm-form__button cancel" @click="close" v-if="formType < 3">取消</button>
    </div>
  </CrmFormLayout>
</template>

<script>
export default {
  components: {},
  props: {
    data: {
      // 回填表单数据
      type: [Object],
      default: function () {
        return {};
      },
    },
    formType: {
      // 表单操作类型 1 新增 2 修改 3查看 查看没有操作按钮
      type: [String, Number],
      default: 1,
    },
    source: {
      // 任务类型(来源)
      type: [String, Number],
      default: 1,
    },
    eventId: {
      // 事件ID
      type: [String, Number],
      default: 1,
    },
    businessNo: {
      // 商机编号
      type: [String, Number],
      default: '',
    },
    BusinessId: {
      // 商机ID
      type: [String],
      default: '',
    },
    customerId: {
      // 客户ID
      type: [String, Number],
      default: '',
    },
    customerName: {
      // 客户名
      type: [String],
      default: '',
    },
  },
  data() {
    return {
      defaultTxt: '根据不同类型显示不同的名字,参考原型',
      signDate: '超级玛丽',
      contactId: null,
      rulese: '',
      headList: [],
      formData: {
        consigneeShortName: '',
        businessId: this.eventId,
        businessNo: this.businessNo,
        operateType: this.formType,
      },
      rules: {
        consigneeShortName: [
          {
            required: true,
            message: '请输入抬头',
            trigger: 'blur',
          },
        ],
        email: [
          {
            required: true,
            message: '请输入邮箱',
            trigger: 'blur',
          },
        ],
      },
    };
  },
  methods: {
    getupheadList(value) {
      this.headList = [];
      this.ajax({
        type: 'POST',
        url: 'malicrm/consignee/queryByName',
        data: {
          portName: value,
          consigneeType: '2',
        },
        loading: true,
        success: (res) => {
          if (res.code === 200) {
            this.headList = res.result;
          } else {
            this.errorTip(res.message);
          }
        },
      });
    },
    submit() {
      // 提交操作
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.submitForm();
        } else {
          return false;
        }
      });
      // 保存操作,调用新增
    },
    submitForm() {
      // 提交表单
      const params = Object.assign({}, this.formData);
      this.ajax({
        type: 'POST',
        url: '/malicrm/business/operateCustConsignee',
        data: {
          ...params,
        },
        loading: true,
        success: (res) => {
          if (res.code === 200) {
            if (this.formType === 1) {
              this.successTip('保存成功', 2000);
            } else {
              this.successTip('编辑成功', 2000);
            }
            this.$emit('close', true);
          } else {
            this.errorTip(res.message);
          }
        },
      });
    },
    close() {
      // 取消操作
      this.$emit('close');
    },
  },
  created() {
    if (this.formType > 1) {
      // 修改查看就回填数据过来
      let database = JSON.stringify(this.data);
      database = JSON.parse(database);
      for (const key in database) {
        if (database[key] === '-') {
          database[key] = '';
        }
      }
      this.formData.consigneeShortName = database.shortName;
      this.formData.id = database.id;
    }
    this.getupheadList('');
  },
};
</script>
<style scope>
.username-div {
  width: 420px;
  height: 40px;
  color: #000;
  background: rgba(255, 255, 255, 1);
  border: 1px solid rgba(227, 228, 230, 1);
  border-radius: 2px;
}
.username-div-active {
  width: 420px;
  height: 40px;
  line-height: 40px;
  background: #f5f7fa;
  border: 1px solid rgba(227, 228, 230, 1);
  border-radius: 2px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding: 0 10px;
  color: #1a1a1a;
}
.contact-item2-input {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  /* height: 40px; */
}
.contact-item2-input .el-input__inner {
  height: 40px !important;
}
/* input[disabled]{color:rgb(0, 0, 0)!important;opacity:1!important;} */
.input-fix input[disabled],
.input-fix input:disabled,
.input-fix input.disabled {
  color: #333 !important;
  -webkit-text-fill-color: #333 !important;
  -webkit-opacity: 1 !important;
  opacity: 1 !important;
}
.el-textarea.is-disabled .el-textarea__inner {
  background-color: #f5f7fa !important;
  border-color: #e4e7ed !important;
  color: #000 !important;
  cursor: not-allowed !important;
}
.contact-item2-input .username {
  width: 260px;
  height: 40px;
  background: rgba(255, 255, 255, 1);
  border-radius: 2px;
}
.contact-item {
  width: 420px;
  height: 40px;
  background: rgba(255, 255, 255, 1);
  border-radius: 2px;
}
.contact-item .el-input__inner {
  height: 40px !important;
}
.contact-item2-input .line-bg {
  width: 10px;
  height: 2px;
  background: rgba(230, 230, 230, 1);
  border-radius: 1px;
  margin-left: 5px;
  margin-right: 5px;
}
.contact-item2-input .gender {
  width: 140px;
  height: 40px;
  line-height: 40px;
  background: rgba(255, 255, 255, 1);

  border-radius: 2px;
}
</style>
