<template>
  <!--半期货服务费 -->
  <div class="mt-20" v-if="checked">
    <div class="mali-proitem__header">
      {{ serviceName }}
    </div>
    <div class="mali-proitem__flex" v-if="checked && settleType !== 2">
      <div class="mali-flex__label">{{ dataLabel }}</div>
      <div class="mali-flex__form" style="width: 350px">
        <div class="flex-between">
          <div>{{ emptyName(localValue) }}</div>
          <div class="flex-append">{{ append }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    serviceName: {
      type: [String],
      default: '服务名称',
    },
    append: {
      type: [String],
      default: '',
    },
    dataLabel: {
      type: [String],
      default: '标签文本',
    },
    settleType: {
      type: [Number],
    },
    checked: {
      // 是否选择该服务
      type: [Boolean],
      default: true,
    },
    value: {
      // 产品信息
      type: [String, Number],
      default: '',
    },
  },
  computed: {
    localValue() {
      if (!this.value) {
        return null;
      } else {
        return this.value.toString();
      }
    },
  },
  data() {
    return {
      disabled: true,
    };
  },
  methods: {
    emptyName(val) {
      if (val === '' || val === null) {
        return '-';
      } else {
        return val;
      }
    },
  },
};
</script>

<style scoped></style>
