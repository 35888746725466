
import { defineComponent, ref } from 'vue';
import Pagination from '@/components/Pagination/Pagination.vue';
import { setTitleNum, hasPermission } from '@/utils/publicMethods';
import useVisible from '@/hooks/useVisible';
import usePagination from '@/hooks/usePagination';
import ViewForm from './form.vue';
export default defineComponent({
  props: ['eventId', 'eventType', 'scoreName', 'isHidden', 'source', 'status', 'businessNo', 'typeId', 'BusinessId', 'customerName', 'isBusiness'],
  components: { Pagination, ViewForm },
  emits: ['getSize', 'success'],
  setup(props, ctx) {
    const queryData = {
      businessId: props.eventId,
      followerType: 1,
    };
    const { paginationRef, tableData, refashTable, empty } = usePagination();
    const formType = ref(1); // 1新增2编辑 3删除
    const rowData = ref();
    const { visible } = useVisible();
    // 获取表格数据操作
    const getTableDatas = (data: Array<any>, total: number) => {
      tableData.value = data;
      ctx.emit('getSize', setTitleNum('资管跟单员', total));
    };

    // 表单关闭操作，重新触发数据更新
    const closeVisible = (status) => {
      visible.value = false;
      refashTable();
      if (status) {
        ctx.emit('success');
      }
    };
    return {
      tableData,
      queryData,
      getTableDatas,
      closeVisible,
      visible,
      formType,
      paginationRef,
      rowData,
      hasPermission,
      empty,
    };
  },
});
