<template>
  <CrmFormLayout :title="comTitle" @pageShowClose="customClose" :pageShow="pageShow" :isCustomClose="true">
    <div v-show="pageShow == 0" class="product-form-page">
      <el-form ref="form" class="mali-product-from" label-position="top" :show-message="true" :rules="rules" :model="formData">
        <el-row :gutter="80" class="product-section pt_20">
          <el-col :span="12">
            <el-form-item label="业务" prop="category" class="form-item-box">
              <el-select
                style="width: 257px"
                v-model="formData.category"
                class="mali-full__input"
                @visible-change="cateBussinessFocus"
                @change="showSelect"
                placeholder="请选择"
                :disabled="isConsigneeFunding"
              >
                <el-option v-for="item in Options.product_category" :key="item.value" :label="item.label" :value="item.value"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item v-if="formData.category" label="签约主体" prop="contractPrincipalId" class="form-item-box">
              <el-select
                style="width: 257px"
                v-model="formData.contractPrincipalId"
                @change="selectContractPrincipal"
                class="mali-full__input"
                placeholder="请选择"
              >
                <el-option
                  v-for="item in principalNameOption"
                  :key="item.contractPrincipalId"
                  :label="item.contractPrincipalName"
                  :value="String(item.contractPrincipalId)"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <div class="product-section border-none" v-if="showType === 1" style="margin-bottom: 0">
          <table class="productCate__table no-tips category" cellspacing="0" cellpadding="0">
            <thead>
              <tr>
                <th><span>起运港</span></th>
                <th><span class="required">目的港</span></th>
                <th><span class="required">船期</span></th>
                <th><span class="required">Incoterm</span></th>
                <th><span class="required">柜型</span></th>
                <th><span class="required">是否买保险</span></th>
                <th>
                  <div class="required adjust-s" style="line-height: 16px">
                    保证金比例
                    <br />(%)
                  </div>
                </th>
                <th>
                  <div style="line-height: 16px" class="required">计费方式</div>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <el-form-item prop="productQHDCDto.qhdcPortLoading">
                    <el-input v-model="formData.productQHDCDto.qhdcPortLoading" :maxlength="50" class="pd-30" clearable></el-input>
                  </el-form-item>
                </td>
                <td>
                  <el-form-item prop="productQHDCDto.qhdcPortArrival">
                    <el-select
                      v-model="formData.productQHDCDto.qhdcPortArrival"
                      class="mali-full__input"
                      placeholder="  "
                      filterable
                      remote
                      clearable
                      :remote-method="getPortName"
                      @blur="initPortName"
                      :disabled="isConsigneeFunding && formData.isNeedJr"
                    >
                      <el-option v-for="item in portList" :key="item" :label="item" :value="item"></el-option>
                    </el-select>
                  </el-form-item>
                </td>
                <td style="width: 90px">
                  <el-form-item prop="productQHDCDto.qhdcShippingDate">
                    <el-input v-model="formData.productQHDCDto.qhdcShippingDate" :maxlength="15" @blur="changeSpellArkContainerOrDate($event)"></el-input>
                  </el-form-item>
                </td>
                <td>
                  <el-form-item prop="productQHDCDto.qhdcIncoterm">
                    <el-select v-model="formData.productQHDCDto.qhdcIncoterm" @change="changeIcoterm" placeholder=" " clearable>
                      <el-option v-for="item in Options.Incoterm" :key="item.value" :label="item.label" :value="item.value"></el-option>
                    </el-select>
                  </el-form-item>
                </td>
                <!-- 柜型 -->
                <td style="width: 140px">
                  <el-form-item prop="productQHDCDto.qhdcContainerType">
                    <el-select v-model="formData.productQHDCDto.qhdcContainerType" clearable placeholder=" ">
                      <el-option v-for="item in Options.container_type" :key="item.value" :label="item.label" :value="item.value"></el-option>
                    </el-select>
                  </el-form-item>
                </td>
                <!--是否买保险  915修改-->
                <td>
                  <el-form-item prop="productQHDCDto.qhdcIsNeedInsurance">
                    <el-select
                      v-model="formData.productQHDCDto.qhdcIsNeedInsurance"
                      clearable
                      :disabled="formData.productQHDCDto.qhdcIncoterm == 1"
                      placeholder=" "
                    >
                      <el-option label="是" :value="true"></el-option>
                      <el-option label="否" :value="false"></el-option>
                    </el-select>
                  </el-form-item>
                </td>
                <!-- 915修改 -->
                <td>
                  <el-form-item>
                    <el-input
                      @blur="changeAmount($event, 'productQHDCDto', 'qhdcDepositRate')"
                      v-model="formData.productQHDCDto.qhdcDepositRate"
                      clearable
                      maxlength="5"
                    ></el-input>
                  </el-form-item>
                </td>
                <td>
                  <!--计费类型-->
                  <el-form-item prop="productQHDCDto.qhdcContainerType">
                    <el-select
                      @change="settleTypeChangeFun"
                      v-model="formData.settleType"
                      :disabled="formData.contractPrincipalId ? false : true"
                      clearable
                      placeholder=" "
                    >
                      <el-option v-for="item in settleTypeOption" :key="item.settleType" :label="item.settleTypeDesc" :value="item.settleType"></el-option>
                    </el-select>
                  </el-form-item>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div v-if="showType === 1" class="pay-date">
          <h4>支付保证金/定金的时间 <span class="h4-required">*</span></h4>
          <div class="check-box-wrap">
            <el-radio v-model="formData.productQHDCDto.payDepositDate" :label="1">订购单签署完成后的3个工作日内</el-radio>
            <el-radio v-model="formData.productQHDCDto.payDepositDate" :label="2">预计启运前的第7个工作日</el-radio>
          </div>
        </div>
        <div v-if="showType === 1">
          <div class="ele-declaration" style="margin-top: 8px">
            <h4>备注</h4>
            <el-input
              v-model="formData.productQHDCDto.qhdcRemark"
              :maxlength="100"
              :rows="2"
              :autosize="{ minRows: 2 }"
              type="textarea"
              resize="vertical"
            ></el-input>
          </div>
        </div>
        <div class="product-section border-none category" v-if="showType == 1" style="margin-bottom: 0">
          <table cellspacing="0" cellpadding="0" class="productCate__table no-tips" style="width: 100%; margin-bottom: 0; margin-top: 20px">
            <thead>
              <tr>
                <th><span class="required">大类</span></th>
                <th><span class="required">国家</span></th>
                <th><span class="required">厂号</span></th>
                <th><span class="required">供应商</span></th>
                <th><span>供应商合同号</span></th>
                <th>
                  <div class="required adjust-s1" style="line-height: 16px">供应商预付<br />比例(%)</div>
                </th>
                <th><span class="required">付款方式</span></th>
                <th><span class="required">抬头类型</span></th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td style="width: 76px">
                  <el-select v-model="qhdcForm.category" placeholder="  " @change="changeCateGory">
                    <el-option v-for="(item, index) in Options.goods_category_label" :key="index" :label="item.label" :value="item.value"> </el-option>
                  </el-select>
                </td>
                <td style="width: 120px">
                  <el-select v-model="qhdcForm.country" placeholder="  " @change="changeCountry" class="pr-30">
                    <el-option v-for="item in countryList" :key="item.country" :label="item.countryName" :value="item.country"></el-option>
                  </el-select>
                </td>
                <td style="width: 120px">
                  <el-select
                    v-model="qhdcForm.plantNo"
                    @change="changePlantNo"
                    placeholder="  "
                    class="tag-scroll"
                    multiple
                    :multiple-limit="6"
                    popper-class="product-plantnow"
                    collapse-tags
                    style="width: 100%"
                  >
                    <el-option v-for="(item, index) in plantList" :key="index" :label="item" :value="item"></el-option>
                  </el-select>
                </td>
                <td>
                  <el-select
                    v-model="formData.productQHDCDto.qhdcSupplierId"
                    @change="getSupplierList('', 'change')"
                    @focus="focusSupplier"
                    :filter-method="getSupplierList"
                    placeholder="  "
                    clearable
                    :popper-append-to-body="false"
                    class="mali-full__input pr-30"
                    filterable
                    remote
                  >
                    <el-option v-for="item in supplierdataList" :key="item.id" :label="item.supplierName" :value="item.id">
                      <el-tooltip :content="item.supplierName" placement="top-start" :disabled="item.supplierName.length < 28">
                        <span class="over-tootip">{{ item.supplierName }}</span>
                      </el-tooltip>
                    </el-option>
                  </el-select>
                </td>
                <td style="width: 120px">
                  <el-tooltip
                    effect="dark"
                    :content="formData.productQHDCDto.qhdcSupplierContractNo"
                    placement="top-start"
                    :disabled="
                      !formData.productQHDCDto.qhdcSupplierContractNo ||
                      (formData.productQHDCDto.qhdcSupplierContractNo && formData.productQHDCDto.qhdcSupplierContractNo.length <= 10)
                    "
                    class="tootip-over"
                  >
                    <div style="text-align: center; white-space: nowrap; overflow: hidden; text-overflow: ellipsis; width: 110px">
                      {{ filterName(formData.productQHDCDto.qhdcSupplierContractNo) }}
                    </div>
                  </el-tooltip>
                </td>

                <td style="width: 104px">
                  <el-form-item prop="productQHDCDto.qhdcSupplierPaymentRatio">
                    <el-input
                      v-model="formData.productQHDCDto.qhdcSupplierPaymentRatio"
                      maxlength="5"
                      clearable
                      placeholder="  "
                      @blur="changePaymentRatio($event, 'productQHDCDto', 'qhdcSupplierPaymentRatio')"
                    ></el-input>
                  </el-form-item>
                </td>
                <td style="width: 106px">
                  <el-select v-model="formData.productQHDCDto.qhdcPayType" clearable class="mali-full__input pr-30" placeholder="  ">
                    <el-option :label="item.label" :value="item.value" v-for="(item, index) in Options.pay_type" :key="index"></el-option>
                  </el-select>
                </td>
                <td style="width: 110px">
                  <el-select
                    v-model="formData.productQHDCDto.qhdcConsigneeType"
                    clearable
                    :disabled="isConsigneeFunding"
                    class="mali-full__input pr-30"
                    placeholder="  "
                    @change="changeConsigneeType"
                  >
                    <el-option :label="item.label" :value="item.value" v-for="(item, index) in Options.consignee_type" :key="index"></el-option>
                  </el-select>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div v-if="isShowPurchaseTd && showType === 1" class="epic-form-item">
          <span class="epic-form-label">Epic的供应商：</span>
          <el-select
            v-model="formData.productQHDCDto.epicSupplierId"
            @change="getEpicSupplierChange"
            @focus="focusEpicSearch"
            :filter-method="getEpicSupplierList"
            placeholder="  "
            clearable
            :popper-append-to-body="false"
            class="mali-full__input pr-30 epic-form-value"
            filterable
            remote
          >
            <el-option v-for="item in epicSupplierDataList" :key="item.id" :label="item.supplierName" :value="item.id">
              <el-tooltip :content="item.supplierName" placement="top-start" :disabled="item.supplierName.length < 28">
                <span class="over-tootip">{{ item.supplierName }}</span>
              </el-tooltip>
            </el-option>
          </el-select>
        </div>
        <div
          v-if="showType == 2 || showType == 3"
          class="product-section border-none"
          id="storageListTable"
          style="overflow: auto; border-left: 1px solid #e3e4e6; border-right: 1px solid #e3e4e6"
        >
          <table cellspacing="0" cellpadding="0" class="productCate__table no-tips category" style="width: 1600px; margin-bottom: 0; border-right: none">
            <thead>
              <tr>
                <th style="border-left: none">
                  <span class="required">现存冷库名称</span>
                </th>
                <th><span class="required">现存冷库地址</span></th>
                <th><span class="required">柜号</span></th>
                <th><span class="required">保证金比例(%)</span></th>
                <th><span class="required">上游名称</span></th>
                <th><span class="required">上游联系人</span></th>
                <th style="border-right: none"><span>上游联系方式</span></th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td style="border-left: none">
                  <el-form-item label prop="productXHFWDto.xhfwStorageName">
                    <el-select
                      v-model="formStorage"
                      ref="formStorage"
                      value-key="shortName"
                      :remote-method="getStorageList"
                      @blur="initStorageList"
                      @change="getFormStorage"
                      filterable
                      remote
                      clearable
                      placeholder="  "
                      class="mali-full__inputcom"
                      :popper-append-to-body="true"
                    >
                      <el-option v-for="(item, index) in storageList" :key="index" :disabled="!item.id" :value="item" :label="item.shortName"></el-option>
                    </el-select>
                  </el-form-item>
                </td>
                <td style="width: 549px">
                  <el-form-item label style="float: left">
                    <city-select
                      size="small"
                      :readonly="true"
                      :separator="true"
                      @change="getXhfwAddress"
                      :data="xhfwAddress"
                      customClass="area-100"
                    ></city-select>
                  </el-form-item>
                  <el-form-item prop="productXHFWDto.xhfwStorageDetail" label style="float: left; width: 190px; margin-left: 5px">
                    <el-input v-model="formData.productXHFWDto.xhfwStorageDetail" clearable :disabled="true" placeholder="  "></el-input>
                  </el-form-item>
                </td>
                <!-- 柜号 -->
                <td style="width: 190px">
                  <el-form-item label prop="productXHFWDto.xhfwContainerNo">
                    <el-input
                      v-model="formData.productXHFWDto.xhfwContainerNo"
                      clearable
                      :maxlength="11"
                      @blur="changeContainerNo($event, 'productXHFWDto', 'xhfwContainerNo')"
                    ></el-input>
                  </el-form-item>
                </td>
                <td>
                  <el-form-item label>
                    <el-input
                      v-model="formData.productXHFWDto.xhfwDepositRate"
                      maxlength="5"
                      clearable
                      @blur="changeAmount($event, 'productXHFWDto', 'xhfwDepositRate')"
                    >
                    </el-input>
                  </el-form-item>
                </td>
                <td>
                  <el-form-item label prop="productXHFWDto.xhfwUpstreamName" class="no-date-icon">
                    <el-input v-model="formData.productXHFWDto.xhfwUpstreamName" clearable maxlength="60"></el-input>
                  </el-form-item>
                </td>
                <td>
                  <el-form-item label prop="productXHFWDto.xhfwUpstreamContactsName">
                    <el-input
                      v-model="formData.productXHFWDto.xhfwUpstreamContactsName"
                      clearable
                      :maxlength="4"
                      @blur="changeChinese($event, 'productXHFWDto', 'xhfwUpstreamContactsName')"
                    ></el-input>
                  </el-form-item>
                </td>
                <td style="border-right: none">
                  <el-form-item label prop="productXHFWDto.xhfwUpstreamContactsTelephone">
                    <el-input
                      v-model="formData.productXHFWDto.xhfwUpstreamContactsTelephone"
                      clearable
                      :maxlength="30"
                      @blur="changeContacts($event, 'productXHFWDto', 'xhfwUpstreamContactsTelephone')"
                    ></el-input>
                  </el-form-item>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div v-if="showType > 0">
          <div class="total-bar" v-if="showType < 4" style="margin-top: 8px">
            <div style="float: left">
              <span style="margin-right: 30px" class="total-bar__info">总货值({{ unit }})：{{ totalPricedata }}</span>
              <span v-if="showType < 4" class="total-bar__info">总重量(kg)：{{ totalWeightData }}</span>
            </div>
            <div style="display: flex; align-items: center" v-if="showType === 1">
              <div class="export-woolly" @click="changeShowPage(2)">
                <img src="../../../../../assets/images/icon-export.png" alt />
                <span>导入件套</span>
              </div>
              <div style="float: right">
                <span class="total-bar__info">交易币种：</span>
                <el-select v-model="formData.productQHDCDto.qhdcTradeCurrency" class="no-border">
                  <el-option v-for="item in Options.trade_currency" :key="item.value" :label="item.label" :value="item.value"></el-option>
                </el-select>
              </div>
            </div>
          </div>
        </div>
        <table cellspacing="0" cellpadding="0" class="productCate__table no-tips pingming" v-if="showType > 0">
          <thead>
            <tr>
              <th v-if="showType === 1">
                <span class="required">中文品名</span>
              </th>
              <th v-if="showType === 1">
                <span class="required">英文品名</span>
              </th>
              <th v-if="showType === 1" style="min-width: 60px">
                <span class="required">标签</span>
              </th>
              <th class="th-goods-info-w" v-if="showType !== 1">
                <span>货物信息</span>
              </th>
              <!-- 现货代采现货质押 -->
              <th style="min-width: 90px" v-if="showType === 2 || showType === 3">
                <span>二级标准</span>
              </th>
              <th style="min-width: 90px" v-if="showType === 2 || showType === 3">
                <span class="required">件数</span>
              </th>
              <th v-if="showType < 4" style="min-width: 90px">
                <span class="required">价格({{ unit }}/kg)</span>
              </th>
              <th style="min-width: 95px">
                <span class="required">重量(kg)</span>
              </th>
              <th style="min-width: 95px" v-if="showType < 4">
                <span class="">货值({{ unit }})</span>
              </th>
              <th class="th-48">
                <span>操作</span>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, index) in formData.productGoodsDtoList" :key="index + 'goods'">
              <!--期货代采展示 -->
              <td v-if="showType == 1" style="width: 198px">
                <div style="position: relative">
                  <el-autocomplete
                    v-model="item.nameCn"
                    :fetch-suggestions="queryProductLabelCn"
                    @select="handleName($event, item)"
                    value-key="plantNoNameCn"
                    @blur="nameFocus(item)"
                    :disabled="item.labelId && item.labelId !== ''"
                    :popper-append-to-body="false"
                    size="small"
                    popper-class="offer-name nameCn-list"
                    style="width: 100%"
                  >
                    <template v-slot="{ item }">
                      <el-tooltip :content="item.plantNoNameCn" :disabled="item.plantNoNameCn.length < 30">
                        <div class="name-tips">{{ item.plantNoNameCn }}</div>
                      </el-tooltip>
                    </template>
                  </el-autocomplete>
                  <span class="el-icon-circle-close name-close" v-if="item.labelId && item.labelId !== ''" @click="clearNameLabel(item)"></span>
                </div>
              </td>
              <td v-if="showType == 1" style="width: 300px">
                <div style="position: relative">
                  <el-autocomplete
                    v-model="item.nameEn"
                    :fetch-suggestions="queryProductLabelEn"
                    @select="handleName($event, item)"
                    value-key="plantNoNameEn"
                    :disabled="item.labelId && item.labelId !== ''"
                    @blur="nameFocus(item)"
                    :popper-append-to-body="false"
                    class="offer-name nameEn-list"
                    size="small"
                    style="width: 100%"
                  >
                    <template v-slot="{ item }">
                      <el-tooltip :content="item.plantNoNameEn" :disabled="item.plantNoNameEn.length < 30">
                        <div class="name-tips">{{ item.plantNoNameEn }}</div>
                      </el-tooltip>
                    </template>
                  </el-autocomplete>
                  <span class="el-icon-circle-close name-close" v-if="item.labelId && item.labelId !== ''" @click="clearNameLabel(item)"></span>
                </div>
              </td>
              <td v-if="showType == 1" class="img-td" style align="center">
                <img
                  src="../../../../../assets/images/img_icon_biaoqian_s.png"
                  class="goods-tag"
                  @click.stop="clickTag(item.url)"
                  v-if="item.labelId && item.labelId !== '' && item.url !== '' && item.url !== null"
                />
                <img
                  src="../../../../../assets/images/img_icon_biaoqian.png"
                  class="goods-tag"
                  @click.stop="clickTag(null)"
                  v-if="item.labelId && item.labelId !== '' && item.url == null"
                />
              </td>

              <td class="ver-top" style="width: 300px" v-if="showType !== 1">
                <div class="table-goods-wrap" @click="clickProductInfo(item, index)" :title="item.isValid" style="width: 300px">
                  <div class="table-goods-info" :title="item.goodsInfoFormat">
                    {{ item.goodsInfoFormat }}
                  </div>
                  <div v-if="showType == 1">
                    <div v-if="productParam.formType === 1">
                      <img src="../../../../../assets/images/img_icon_biaoqian.png" title class="goods-tag" @click.stop="clickTag('')" />
                    </div>
                    <div v-else>
                      <img
                        v-if="item.isDisable || !item.url"
                        src="../../../../../assets/images/img_icon_biaoqian.png"
                        title
                        class="goods-tag"
                        @click.stop="clickTag('')"
                      />
                      <img
                        v-if="!item.isDisable && item.url"
                        src="../../../../../assets/images/img_icon_biaoqian_s.png"
                        title
                        class="goods-tag"
                        @click.stop="clickTag(item.url)"
                      />
                    </div>
                  </div>
                </div>
              </td>
              <td v-if="showType == 2 || showType == 3">
                <el-form-item>
                  <el-input
                    v-model="item.subLevelStandard"
                    :maxlength="40"
                    clearable
                    class="pd-30"
                    style="width: 120px"
                    @blur="changeSubLevelStandard($event, index, 'subLevelStandard')"
                  ></el-input>
                </el-form-item>
              </td>
              <!-- 件数 -->
              <td v-if="showType == 2 || showType == 3">
                <el-form-item>
                  <el-input
                    v-model="item.packageCount"
                    :maxlength="7"
                    clearable
                    style="width: 100px"
                    class="pd-30"
                    @blur="changePackageCount($event, index, 'packageCount')"
                  >
                  </el-input>
                </el-form-item>
              </td>
              <td v-if="showType !== 4">
                <el-form-item>
                  <el-input
                    v-model="item.unitPrice"
                    @blur="changeWeight($event, index, 'unitPrice')"
                    @clear="restTotal"
                    :maxlength="10"
                    class="pd-30"
                    clearable
                    style="width: 130px"
                  ></el-input>
                </el-form-item>
              </td>
              <td>
                <el-form-item>
                  <el-input
                    v-model="item.weight"
                    @blur="changeWeight($event, index, 'weight')"
                    :maxlength="9"
                    clearable
                    class="pd-30"
                    @clear="restTotal"
                  ></el-input>
                </el-form-item>
              </td>
              <td v-if="showType !== 4">
                <el-form-item>
                  <span>{{ getTotalPrice(item.unitPrice, item.weight) }}</span>
                </el-form-item>
              </td>
              <td class="opt">
                <span class="el-icon-circle-plus-outline" v-if="index === 0" @click="addProduct()"></span>
                <span class="el-icon-remove-outline" v-else @click="removeProduct(index)"></span>
              </td>
            </tr>
          </tbody>
        </table>

        <!--收费标准-->
        <div class="baogan-box" style="margin-top: 6px" v-if="formData.settleType === 2 && showType === 1">
          <h4>收费标准</h4>
          <table cellspacing="0" cellpadding="0" class="contract-table-custom">
            <thead>
              <tr>
                <th style="width: 306px">
                  <span class="required">自支付尾款之日起至甲方付款提货之日止</span>
                </th>
                <th style="width: 305px">
                  <span class="required">服务费(元/公斤)</span>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td style="width: 306px; text-align: center; vertical-align: middle">1-30天</td>
                <td style="width: 305px">
                  <el-form-item>
                    <el-input class="pd-30" v-model="formData.lumpDtos[0].fee" clearable :maxlength="5" @blur="valPayFeeDouble($event, 'fee', 0)"></el-input>
                  </el-form-item>
                </td>
              </tr>
              <tr>
                <td style="width: 306px; text-align: center; vertical-align: middle">1-45天</td>
                <td style="width: 305px">
                  <el-form-item>
                    <el-input class="pd-30" v-model="formData.lumpDtos[1].fee" clearable :maxlength="5" @blur="valPayFeeDouble($event, 'fee', 1)"></el-input>
                  </el-form-item>
                </td>
              </tr>
              <tr>
                <td style="width: 306px; text-align: center; vertical-align: middle">1-60天</td>
                <td style="width: 305px">
                  <el-form-item>
                    <el-input class="pd-30" v-model="formData.lumpDtos[2].fee" clearable :maxlength="5" @blur="valPayFeeDouble($event, 'fee', 2)"></el-input>
                  </el-form-item>
                </td>
              </tr>
              <tr>
                <td style="width: 306px; text-align: center; vertical-align: middle">1-75天</td>
                <td style="width: 305px">
                  <el-form-item>
                    <el-input class="pd-30" v-model="formData.lumpDtos[3].fee" clearable :maxlength="5" @blur="valPayFeeDouble($event, 'fee', 3)"></el-input>
                  </el-form-item>
                </td>
              </tr>
              <tr>
                <td style="width: 306px; text-align: center; vertical-align: middle">1-90天</td>
                <td style="width: 305px">
                  <el-form-item>
                    <el-input class="pd-30" v-model="formData.lumpDtos[4].fee" clearable :maxlength="5" @blur="valPayFeeDouble($event, 'fee', 4)"></el-input>
                  </el-form-item>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div></div>
        <div v-if="showType == 1 && customerContract && formData.settleType == 1">
          <span @click="changeShowPage(1)" style="display: inline-block; margin-top: 20px; color: #2878ff; font-size: 16px; cursor: pointer">合同收费标准</span>
        </div>
        <div class="product-section border-none" style="margin-top: 20px" v-if="showType == 1 && formData.settleType == 1">
          <div class="product-toggle-title">
            <el-checkbox v-model="formData.isNeedHalfQhdc" @change="validateHalfQhdc($event, 'isNeedHalfQhdc')">半期货服务</el-checkbox>
          </div>
          <div class="el-form-item check-inner halfqh" v-if="formData.isNeedHalfQhdc && formData.settleType !== 2">
            <label class="el-form-item__label">服务费</label>
            <el-input :disabled="true" style="width: 350px; margin-left: 20px" v-model="comhalfQhdcServiceFeeDisplay"></el-input>
          </div>
        </div>

        <div class="product-section border-none" style="margin-top: 20px" v-if="showType == 1 && customerContract">
          <div class="product-toggle-title">
            <el-checkbox v-model="formData.isNeedCg" @change="validateContract($event, 'isNeedCg')">国际采购服务</el-checkbox>
          </div>
          <div class="product-toggle-body table" v-if="formData.isNeedCg && formData.settleType !== 2">
            <table cellspacing="0" cellpadding="0" class="productCate__table no-tips">
              <thead>
                <tr>
                  <th width="50%">大类</th>
                  <th width="50%">代采费</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(item, index) in customerContract.cgFeeList" :key="index + 'qhdc'">
                  <template v-if="item.category === qhdcForm.category">
                    <td align="center" style="height: 40px; vertical-align: middle">
                      {{ item.categoryDesc }}
                    </td>
                    <td align="center" style="height: 40px; vertical-align: middle">{{ item.agencyFee }} ({{ item.agencyFeeUnitDesc }})</td>
                  </template>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        <div class="product-section border-none" v-if="showType == 1">
          <div class="product-toggle-title agent-box">
            <el-checkbox v-model="formData.isNeedQg" @change="validateContract($event, 'isNeedQg')">清关服务</el-checkbox>
            <div class="agent" v-if="formData.isNeedQg">
              <span class="agent-info required_gys">报关行/代理</span>
              <el-select v-model="formData.agencyId" :filterable="true" :clearable="true" size="mini" placeholder="请选择报关行/代理" style="width: 59%">
                <el-option v-for="item in agencyArr" :key="item.agencyId" :label="item.shortName" :value="item.agencyId"></el-option>
              </el-select>
            </div>
          </div>
          <div
            class="product-toggle-body table"
            v-if="
              formData.isNeedQg &&
              formData.settleType !== 2 &&
              (formData.productQHDCDto.qhdcContainerType === 1 || formData.productQHDCDto.qhdcContainerType === 2) &&
              customerContract
            "
          >
            <table cellspacing="0" cellpadding="0" class="productCate__table no-tips">
              <thead>
                <tr>
                  <th width="25%">大类</th>
                  <th width="25%">大柜代理费</th>
                  <th width="25%">大柜最低吨数</th>
                  <th width="25%">小柜代理费</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(item, index) in customerContract.agencyFeeList" :key="index + 'qhdc'">
                  <template v-if="item.category === qhdcForm.category">
                    <td align="center" style="height: 40px; vertical-align: middle">
                      {{ item.categoryDesc }}
                    </td>
                    <td align="center" style="height: 40px; vertical-align: middle">{{ item.clearanceFeeLarge }} ({{ item.clearanceFeeLargeUnitDesc }})</td>
                    <td align="center" style="height: 40px; vertical-align: middle">
                      {{ filterName(item.clearanceFeeLargeMinWeight) }}
                    </td>
                    <td align="center" style="height: 40px; vertical-align: middle">{{ item.clearanceFeeSmall }} ({{ item.clearanceFeeSmallUnitDesc }})</td>
                  </template>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="product-section border-none" v-if="showType == 2 || showType == 3" style="margin-top: 20px">
          <div class="product-toggle-title">
            <el-checkbox v-model="formData.isNeedXhfw" @change="validateContract($event, 'isNeedXhfw')">服务费用</el-checkbox>
          </div>
          <div class="el-form-item is-required check-inner halfqh" v-if="formData.isNeedXhfw" style="display: flex; align-items: center">
            <label class="el-form-item__label" style="margin-bottom: 0">服务费（元/吨）</label>
            <el-input
              @blur="changeAmount($event, 'productXHFWDto', 'xhfwServiceFee')"
              :maxlength="12"
              v-model="formData.productXHFWDto.xhfwServiceFee"
              clearable
              style="width: 180px; margin-left: 32px"
            ></el-input>
          </div>
        </div>
        <div class="product-section border-none" v-if="showType >= 1 && showType <= 3">
          <div class="product-toggle-title">
            <el-checkbox
              v-model="formData.isNeedJr"
              :disabled="(isNeedJrshow && formData.category === 1) || (isConsigneeFunding && formData.category === 1)"
              @change="validateContract($event, 'isNeedJr')"
              >供应链金融服务</el-checkbox
            >
          </div>
          <div class="product-toggle-body table" v-if="showType === 1 && formData.isNeedJr && formData.settleType !== 2">
            <table cellspacing="0" cellpadding="0" class="productCate__table no-tips" v-if="showType === 1 && customerContract">
              <thead>
                <tr>
                  <th width="25%">最小天数</th>
                  <th width="25%">最大天数</th>
                  <th width="25%">利率(年化%)</th>
                  <th width="25%">最小使用天数</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(item, index) in customerContract.interestRateConfigList" :key="index">
                  <td align="center" style="height: 40px; vertical-align: middle">
                    {{ filterName(item.configMinDisplay) }}
                  </td>
                  <td align="center" style="height: 40px; vertical-align: middle">
                    {{ filterName(item.configMaxDisplay) }}
                  </td>
                  <td align="center" style="height: 40px; vertical-align: middle">
                    {{ filterName(item.rate) }}
                  </td>
                  <td align="center" style="height: 40px; vertical-align: middle">
                    {{ filterName(item.minUseDaysDisplay) }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="product-toggle-body table" v-if="(showType === 2 || showType === 3) && formData.isNeedJr">
            <table cellspacing="0" cellpadding="0" class="productCate__table no-tips" v-if="showType === 2 || showType === 3">
              <thead>
                <tr>
                  <th width="25%"><span class="required">最小天数</span></th>
                  <th width="25%"><span class="required">最大天数</span></th>
                  <th width="25%"><span class="required">利率(年化%)</span></th>
                  <th width="25%">
                    <span class="required">最小使用天数</span>
                  </th>
                  <th width="25%">
                    <span class="el-icon-circle-plus-outline" style="font-size: 20px" @click="addXhfwJrTable"></span>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(item, index) in xhfwJrTable" :key="index">
                  <td align="center" style="height: 40px; vertical-align: middle">
                    <el-input v-model="item.configMin" placeholder="  " :maxlength="3" @blur="jrDayFormat($event, item, 'configMin')"></el-input>
                  </td>
                  <td align="center" style="height: 40px; vertical-align: middle">
                    <el-input
                      v-model="item.configMax"
                      placeholder="  "
                      :maxlength="3"
                      @blur="jrDayFormat($event, item, 'configMax')"
                      v-if="index !== xhfwJrTable.length - 1"
                    >
                    </el-input>
                  </td>
                  <td align="center" style="height: 40px; vertical-align: middle">
                    <el-input v-model="item.rate" placeholder="  " :maxlength="5" @blur="jrRateFormat($event, item, 'rate')"></el-input>
                  </td>
                  <td align="center" style="height: 40px; vertical-align: middle">
                    <el-input
                      v-model="item.minUseDays"
                      placeholder="  "
                      :maxlength="3"
                      @blur="jrDayFormat($event, item, 'minUseDays')"
                      v-if="index == 0"
                    ></el-input>
                  </td>
                  <td align="center" style="height: 40px; vertical-align: middle">
                    <span class="el-icon-remove-outline" style="font-size: 20px" @click="deleteXhfwJrTable(index)"></span>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="product-section border-none" v-if="showType > 0" style="margin-top: 20px">
          <div class="product-toggle-title">
            <el-checkbox v-model="formData.isNeedCc" class>仓储服务</el-checkbox>
          </div>
          <div class="product-toggle-body border" v-if="formData.isNeedCc">
            <el-row :gutter="40" class="product-section pt_20 bd-4none">
              <el-col :span="6">
                <el-form-item label="仓库名称" :required="showType !== 1">
                  <el-input v-model="formData.productCCDto.ccStorageName" clearable :maxlength="60" class="pd-30"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="10">
                <el-form-item label="仓库地址" prop :required="showType !== 1">
                  <city-select @change="getCcAddress" :data="ccAddress"></city-select>
                </el-form-item>
              </el-col>
              <el-col :span="8" class="color-white">
                <el-form-item label="m" prop="productCCDto.ccAddressDetail">
                  <el-input v-model="formData.productCCDto.ccAddressDetail" clearable maxlength="100" placeholder="  "></el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row class="storage-row">
              <el-col :span="6">
                <el-form-item label="预计入库时间" class="no-date-icon" :required="showType !== 1">
                  <el-date-picker
                    :editable="false"
                    v-model="formData.productCCDto.ccInstoreDate"
                    clearable
                    prefix-icon="null"
                    type="date"
                    placeholder="  "
                  ></el-date-picker>
                </el-form-item>
              </el-col>
              <el-col :span="6" class="days">
                <el-form-item label="期望存储天数" :required="showType !== 1">
                  <el-input
                    v-model="formData.productCCDto.ccStorageDays"
                    clearable
                    :maxlength="4"
                    @blur="changeDays($event, 'productCCDto', 'ccStorageDays')"
                  ></el-input>
                </el-form-item>
              </el-col>
            </el-row>
          </div>
        </div>
        <div class="product-section border-none" v-if="showType > 0" style="margin-bottom: 0">
          <div class="product-toggle-title">
            <el-checkbox v-model="formData.isNeedLy" class>冷运服务</el-checkbox>
          </div>
          <div class="product-toggle-body bd-bottom" v-if="formData.isNeedLy">
            <el-row :gutter="40" class="product-section pt_20 bd-none" style="margin-bottom: 0">
              <el-col :span="6">
                <el-form-item label="发货冷库名称" required>
                  <el-input v-model="formData.productLYDto.lyDepartStorageName" clearable :maxlength="60" class="pd-30"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="10">
                <el-form-item label="发货冷库地址" prop required>
                  <city-select @change="getLyDepart" :data="lyDepartAddress"></city-select>
                </el-form-item>
              </el-col>
              <el-col :span="8" class="color-white">
                <el-form-item label="m" prop="productLYDto.lyDepartDetail">
                  <el-input v-model="formData.productLYDto.lyDepartDetail" clearable :maxlength="100" placeholder="  "></el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row :gutter="40" class="product-section pt_20 bd-none" style="margin-bottom: 0">
              <el-col :span="6">
                <el-form-item label="收货冷库名称" required>
                  <el-input v-model="formData.productLYDto.lyArriveStorageName" clearable :maxlength="60" class="pd-30"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="10">
                <el-form-item label="收货冷库地址" prop required>
                  <city-select @change="getLyArrive" :data="lyArriveAddress"></city-select>
                </el-form-item>
              </el-col>
              <el-col :span="8" class="color-white">
                <el-form-item label="m" prop="productLYDto.lyArriveDetail">
                  <el-input v-model="formData.productLYDto.lyArriveDetail" clearable maxlength="100" placeholder="  "></el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row :gutter="40" class="product-section pt_20 bd-none" style="margin-bottom: 0">
              <el-col :span="6">
                <el-form-item label="发货时间" required class="no-date-icon">
                  <el-date-picker v-model="formData.productLYDto.lyDepartDate" type="datetime" prefix-icon="null" :editable="false" clearable></el-date-picker>
                </el-form-item>
              </el-col>
              <el-col :span="6" class="no-padding">
                <el-form-item label="装货联系人" required>
                  <el-input
                    v-model="formData.productLYDto.lyLoadingContactsName"
                    clearable
                    :maxlength="4"
                    @blur="changeChinese($event, 'productLYDto', 'lyLoadingContactsName')"
                  >
                  </el-input>
                </el-form-item>
              </el-col>
              <el-col :span="6" class="no-padding">
                <el-form-item label="装货联系电话" required>
                  <el-input
                    v-model="formData.productLYDto.lyLoadingContactsTelephone"
                    clearable
                    :maxlength="20"
                    style="width: 200px"
                    @blur="changePhone($event, 'productLYDto', 'lyLoadingContactsTelephone')"
                  ></el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row :gutter="40" class="product-section pt_20 bd-none" style="margin-bottom: 0">
              <el-col :span="6">
                <el-form-item label="卸货联系人" required>
                  <el-input
                    v-model="formData.productLYDto.lyUnloadingContactsName"
                    clearable
                    :maxlength="4"
                    style="width: 200px"
                    @blur="changeChinese($event, 'productLYDto', 'lyUnloadingContactsName')"
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="6" class="no-padding">
                <el-form-item label="卸货联系电话" required>
                  <el-input
                    v-model="formData.productLYDto.lyUnloadingContactsTelephone"
                    @blur="changePhone($event, 'productLYDto', 'lyUnloadingContactsTelephone')"
                    clearable
                    :maxlength="20"
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="6" class="no-padding">
                <el-form-item label="温度要求" prop="productLYDto.lyRequireTemp">
                  <el-select v-model="formData.productLYDto.lyRequireTemp" style="width: 200px" clearable placeholder="  ">
                    <el-option v-for="item in Options.ly_require_temp" :key="item.value" :label="item.label" :value="item.value"></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
            </el-row>
          </div>
        </div>
        <!-- 其他费用（按天计费） 有显示，无隐藏-->
        <div
          class="product-section border-none"
          style="margin-top: 20px"
          v-if="
            showType == 1 && formData.settleType == 1 && customerContract && customerContract.dayOtherFeeList && customerContract.dayOtherFeeList.length > 0
          "
        >
          <div class="product-toggle-title">
            <h4>其他费用（按天计费）</h4>
          </div>
          <div class="product-toggle-body table">
            <table cellspacing="0" cellpadding="0" class="productCate__table no-tips">
              <thead>
                <tr>
                  <th width="50%">费用名称</th>
                  <th width="50%">收费标准</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(item, index) in customerContract.dayOtherFeeList" :key="index">
                  <td align="center" style="height: 40px; vertical-align: middle">
                    <div>
                      {{ filterName(item.feeName) }}
                    </div>
                  </td>
                  <td align="center" style="height: 40px; vertical-align: middle">{{ filterName(item.feeStandard) }}{{ item.feeUnitDesc }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div
          class="product-section border-none"
          style="margin-top: 20px"
          v-if="
            showType == 1 && formData.settleType == 2 && customerContract && customerContract.bagOtherFeeList && customerContract.bagOtherFeeList.length > 0
          "
        >
          <div class="product-toggle-title">
            <h4>其他费用（包干价）</h4>
          </div>
          <div class="product-toggle-body table">
            <table cellspacing="0" cellpadding="0" class="productCate__table no-tips">
              <thead>
                <tr>
                  <th width="50%">费用名称</th>
                  <th width="50%">收费标准</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(item, index) in customerContract.bagOtherFeeList" :key="index + 'fee'">
                  <td align="center" style="height: 40px; vertical-align: middle">{{ filterName(item.feeName) }}</td>
                  <td align="center" style="height: 40px; vertical-align: middle">{{ filterName(item.feeStandard) }}{{ item.feeUnitDesc }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </el-form>
      <div class="crm-button__group">
        <button class="crm-form__button primary" @click="submitFn">保存</button>
        <button class="crm-form__button cancel" @click="cancelFn">取消</button>
      </div>
      <!-- 现货产品添加 -->
      <product-dialog :isShow="isShowProductDialog" @on-close="closeProductDialog">
        <div v-if="isShowProductDialog">
          <product-pop2
            :showType="showType"
            :itemIndex="itemIndex"
            @changePopItem="changePopItem"
            :productDialogData="productDialogData"
            @on-close="closeProductDialog"
          >
          </product-pop2>
        </div>
      </product-dialog>
      <!--现货产品添加  -->
    </div>
    <div v-show="pageShow == 1" class="sj-product">
      <ContractDetail
        v-show="pageShow == 1"
        @cancel="pageCancel"
        :customerContract="customerContract"
        :oppId="productParam.oppid"
        :currency="formData.productQHDCDto.qhdcTradeCurrency"
        v-if="customerContract"
      ></ContractDetail>
    </div>
    <div v-show="pageShow == 2">
      <ExportWoolly
        ref="exportWoolly"
        v-show="pageShow == 2"
        @cancel="pageCancel"
        :qhdcForm="qhdcForm"
        @get="getExportData"
        :tradeCurrency="formData.productQHDCDto.qhdcTradeCurrency"
      ></ExportWoolly>
    </div>
  </CrmFormLayout>
</template>

<script>
import { store } from '@/store';
import CitySelect from '@/components/CitySelect/CitySelect.vue';
import ContractDetail from '../CommonProductCom/ContractDetail.vue';
import ExportWoolly from './ExportWoolly.vue';
import { erpConfirm, formatTime } from '@/utils/publicMethods';
import productMixin2 from '@/views/crm/opportunity/components/CommonProductCom/productMixin2';
import productDialog from '@/views/crm/opportunity/components/CommonProductCom/productDialog.vue';
import productPop2 from '@/views/crm/opportunity/components/CommonProductCom/productPop2.vue';
import { httpPost, httpGet } from '@/api';
// eslint-disable-next-line @typescript-eslint/no-var-requires
const bigDecimal = require('js-big-decimal');

export default {
  mixins: [productMixin2],
  props: ['productParam', 'customerContract', 'customerId', 'formType', 'data'],
  components: {
    CitySelect,
    ContractDetail,
    ExportWoolly,
    productDialog,
    productPop2,
  },
  computed: {
    comTitle() {
      let res = '';
      if (this.pageShow === 0) {
        res = this.formType === 1 ? '添加产品' : '编辑产品';
      }
      if (this.pageShow === 1) {
        res = '合同收费标准';
      }
      if (this.pageShow === 2) {
        res = '导入件套';
      }
      return res;
    },
    comhalfQhdcServiceFeeDisplay() {
      let res = '-';
      if (this.customerContract) {
        res = this.customerContract.halfQhdcServiceFeeDisplay + this.customerContract.halfQhdcServiceFeeUnitDesc;
      }
      return res;
    },
  },
  data() {
    return {
      contractId: null,
      isAjaxLoding: false,
      pageShow: 0, // 0 添加编辑产品页面 1 合同收费标准， 2 导入件套
      formStorage: null, // 绑定现存冷库对象
      storageList: [], // 目标冷库下拉列表
      portList: [], // 目的港下拉列表
      countryList: [], // 国家下拉列表
      plantList: [], // 厂号下拉列表
      qhdcForm: {
        // 期货代采用大类国家厂号
        category: null,
        country: null,
        plantNo: [],
      },
      totalPricedata: '0.00',
      totalWeightData: 0,
      backupsData: '',
      rules: {
        category: [{ required: true, message: ' ', trigger: 'change' }],
        contractPrincipalId: [{ required: true, message: ' ', trigger: 'change' }],
        productXHFWDto: {
          xhfwContainerNo: [{ required: true, message: '固定11个字符，4位英文+7位数字', trigger: 'change' }],
        },
      },
      supplierdataList: [],
      epicSupplierDataList: [],
      purchaseOption: [], // 采购下拉筛选项
      settleTypeOption: [],
      agencyArr: [],
      formData: {
        settleType: null, // 计费方式
        category: '',
        contractPrincipalId: '',
        contractPrincipalName: '',
        isNeedCg: false, // 是否需要国际采购服务
        isNeedTt: false, // 提供收货人抬头
        isNeedQg: false, // 清关服务
        isNeedJr: false, // 是否需要供应链金融服务
        isNeedCc: false, // 是否需要仓储服务服务
        isNeedXhfw: false, // 是否需要现货服务费
        isNeedLy: false, // 是否需要冷运服务
        agencyId: null, //报关行id
        lumpDtos: [
          {
            configMin: 1,
            configMax: 30,
            fee: null,
            contractId: null,
            orderId: null,
          },
          {
            configMin: 31,
            configMax: 45,
            fee: null,
            contractId: null,
            orderId: null,
          },
          {
            configMin: 46,
            configMax: 60,
            fee: null,
            contractId: null,
            orderId: null,
          },
          {
            configMin: 61,
            configMax: 75,
            fee: null,
            contractId: null,
            orderId: null,
          },
          {
            configMin: 76,
            configMax: 90,
            fee: null,
            contractId: null,
            orderId: null,
          },
        ],
        productQHDCDto: {
          category: null,
          country: null,
          plantNo: null,
          productId: null,
          qhdcConsigneeType: null,
          qhdcContainerType: null,
          qhdcDeclarationKeyElements: null,
          qhdcDepositRate: null,
          qhdcIncoterm: null,
          qhdcIsNeedInsurance: null,
          qhdcPortArrival: null,
          qhdcPortLoading: null,
          qhdcRemark: null,
          qhdcShippingDate: null,
          qhdcSupplierId: null,
          qhdcSupplierPaymentRatio: null,
          qhdcTradeCurrency: 1,
          qhdcPayType: null,
          userIds: null,
          epicSupplier: null,
          epicSupplierId: null,
          payDepositDate: null, // 支付保证金时间
        },
        productGoodsDtoList: [
          {
            category: null,
            categoryName: null,
            country: null,
            countryName: null,
            id: '',
            nameCn: null,
            nameEn: null,
            plantNo: null,
            productId: null,
            unitPrice: '',
            weight: '',
            isDisable: true,
            declarationKeyElements: null, // 申报要素
            packageCount: null, // 件数
            subLevelStandard: null, // 二级标
            plantNoArr: [], // 厂号多选
            isMultiplyPlantNo: false, // 是否选择了多个厂号
            isValid: true, // 是否通过校验
            goodsInfoFormat: '',
            isPlantNoInOption: false,
            hsCode: '',
            url: '',
          },
        ],
        productXhfwJrConfigList: [
          {
            configMax: 0,
            configMin: 0,
            minUseDays: 0,
            rate: 0,
          },
        ],
        productCCDto: {
          agencyId: null,
          agencyName: null,
          ccAddressCity: null,
          ccAddressDetail: null,
          ccAddressDistrict: null,
          ccAddressProvince: null,
          ccInstoreDate: null,
          ccStorageDays: null,
          ccStorageName: null,
          id: null,
          productId: null,
        },
        productLYDto: {
          arriveAreaId: null,
          arriveCityId: null,
          arriveProvinceId: null,
          departAreaId: null,
          departCityId: null,
          departProvinceId: null,
          id: null,
          lyArriveCity: null,
          lyArriveDetail: null,
          lyArriveDistrict: null,
          lyArriveProvince: null,
          lyArriveStorageName: null,
          lyDepartCity: null,
          lyDepartDate: null,
          lyDepartDetail: null,
          lyDepartDistrict: null,
          lyDepartProvince: null,
          lyDepartStorageName: null,
          lyRequireTemp: null,
          productId: null,
          lyLoadingContactsName: null,
          lyLoadingContactsTelephone: null,
          lyUnloadingContactsName: null,
          lyUnloadingContactsTelephone: null,
        },
        productXHFWDto: {
          productId: null,
          xhfwContainerNo: null,
          xhfwDepositRate: null,
          xhfwServiceFee: null,
          xhfwServiceType: null,
          xhfwStorageCity: null,
          xhfwStorageCityId: null,
          xhfwStorageDetail: null,
          xhfwStorageDistrict: null,
          xhfwStorageDistrictId: null,
          xhfwStorageName: null,
          xhfwStorageProvince: null,
          xhfwStorageProvinceId: null,
          xhfwUpstreamContactsName: null,
          xhfwUpstreamContactsTelephone: null,
          xhfwUpstreamName: null,
        },
        isNeedHalfQhdc: false, //  半期货服务
      },
      showType: 0,
      ccAddress: { province: '', city: '', county: '' },
      lyArriveAddress: { province: '', city: '', county: '' },
      lyDepartAddress: { province: '', city: '', county: '' },
      xhfwAddress: [],
      Options: {
        product_category: [
          { label: '期货业务', value: 1 },
          { label: '现货代采', value: 2 },
          { label: '现货质押', value: 3 },
          { label: '仓储冷运', value: 4 },
        ],
        Incoterm: store.state.dict.options.Incoterm,

        goods_category: store.state.dict.options.goods_category,

        goods_category_label: store.state.dict.options.goods_category_label,

        goods_category_slim: store.state.dict.options.goods_category_slim,

        goods_country: store.state.dict.options.goods_country,

        ly_require_temp: store.state.dict.options.ly_require_temp,

        container_type: store.state.dict.options.container_type,

        agency_fee_unit: store.state.dict.options.agency_fee_unit,

        clearance_fee_unit: store.state.dict.options.clearance_fee_unit,

        trade_currency: store.state.dict.options.trade_currency,

        consignee_type: store.state.dict.options.consignee_type,

        pay_type: store.state.dict.options.pay_type,
      },
      dialogVisible: false,
      isConsigneeFunding: false,
      dialogImageUrl: '',
      proindex: '',
      DialogData: {
        dialogVisible: false,
        title: '确定删除吗？',
        confirmButtonText: '确定',
        cancelButtonText: '取消',
      },
      isShowProductDialog: false, // 展示产品弹窗
      isCatSelecting: false,
      isNeedJrshow: false,
      principalNameOption: [],
    };
  },
  created() {
    console.log('customerContract', this.customerContract);
    this.backupsData = JSON.parse(JSON.stringify(this.formData));
    if (this.productParam.formType === 1) {
      this.getStorageList('');
      this.getPortName('');
    }
    this.getSupplierList('');
    this.getEpicSupplierList('');
    // 请求接口获取全部代理行
    this.getAgency();
  },
  mounted() {
    this.getTableWidth();
    if (this.productParam.formType === 2) {
      this.initEditData();
    }
  },
  watch: {
    'formData.category': function (newVal, oldVal) {
      if ((newVal === 2 && oldVal === 3) || (newVal === 3 && oldVal === 2)) {
        return false;
      } else {
        this.resetProductGoodsList();
        this.totalPricedata = this.totalPrice();
        this.totalWeightData = this.totalWeight();
      }
    },
  },
  methods: {
    // 获取全部代理行
    async getAgency() {
      const res = await httpGet('malicrm/agency/inquireAgency');
      if (res.code === 200) {
        this.agencyArr = res.result;
      }
    },
    // 获取签约主体列表
    async getPrincipalName() {
      const res = await httpPost('/malicrm/customer/queryContractPrincipalByCustomerId', {
        category: this.formData.category,
        customerId: this.customerId,
      });
      if (res.code === 200) {
        this.principalNameOption = res.result;
      }
    },
    // 签约主体改变事件
    selectContractPrincipal() {
      this.formData.settleType = null;
      // 编辑获取计费方式
      this.getSettleTypeOptionFun();
    },
    customClose(pageShow) {
      if (pageShow === 1) {
        this.pageShow = 0;
      }
      if (pageShow === 0) {
        this.$emit('close', false);
      }
      console.log('pageShow', pageShow);
    },
    filterName(value) {
      if (value === null || value === '' || value === undefined) {
        return '-';
      } else {
        return value;
      }
    },
    getSettleTypeOptionFun() {
      let params = {
        businessId: this.productParam.oppid,
        productCategory: this.customerContract.businessType,
        contractPrincipalId: this.formData.contractPrincipalId,
        contractPrincipalName: this.formData.contractPrincipalName,
      };
      this.ajax({
        type: 'POST',
        url: '/malicrm/business/getContractSettleType',
        data: params,
        loading: true,
        success: (res) => {
          if (res.code === 200) {
            this.settleTypeOption = res.result;
            if (this.settleTypeOption.length > 0) {
              this.contractId = this.settleTypeOption[0].contractId;
            }
          }
        },
      });
    },
    /*
     * 删除多次提货费
     */
    removePickupFee(index) {
      const obj = this.formData.lumpDtos[index];
      if (obj.configMin || obj.configMax || obj.fee) {
        this.$erpConfirm('确定删除吗？')
          .then(() => {
            this.formData.lumpDtos.splice(index, 1);
          })
          .catch(() => {
            return false;
          });
      } else {
        this.formData.lumpDtos.splice(index, 1);
      }
    },
    settleTypeChangeFun() {
      if (!this.formData.lumpDtos) {
        this.formData.lumpDtos = [
          {
            configMin: 1,
            configMax: 30,
            fee: null,
            contractId: null,
            orderId: null,
          },
          {
            configMin: 31,
            configMax: 45,
            fee: null,
            contractId: null,
            orderId: null,
          },
          {
            configMin: 46,
            configMax: 60,
            fee: null,
            contractId: null,
            orderId: null,
          },
          {
            configMin: 61,
            configMax: 75,
            fee: null,
            contractId: null,
            orderId: null,
          },
          {
            configMin: 76,
            configMax: 90,
            fee: null,
            contractId: null,
            orderId: null,
          },
        ];
      }
    },
    getExportData(data) {
      this.formData.productGoodsDtoList.forEach((v, i) => {
        if (!v.nameEn && !v.nameCn && !v.weight && !v.unitPrice) {
          this.formData.productGoodsDtoList.splice(i, 1);
        }
      });
      data.forEach((v) => {
        this.formData.productGoodsDtoList.push(v);
      });
      this.totalPricedata = this.totalPrice();
      this.totalWeightData = this.totalWeight();
      this.pageCancel();
    },
    closePage(type) {
      this.pageShow = type;
    },
    pageCancel() {
      // 展示返回
      this.pageShow = 0;
      const data = {
        type: 0,
        oldTitle: '',
        newTitle: this.productParam.formType === 1 ? '新增产品' : '编辑产品',
      };
      this.$emit('changePage', data);
    },
    changeShowPage(type) {
      if (type === 2) {
        if (this.qhdcForm.category && this.qhdcForm.country && this.qhdcForm.plantNo.length > 0) {
          this.$refs.exportWoolly.getCountry();
          this.$refs.exportWoolly.getPlantNo();
          this.$refs.exportWoolly.queryPackage('');
        } else {
          this.errorTip('请先选择大类国家厂号');
          return false;
        }
      }

      this.pageShow = type;
      const data = {
        type: type,
        oldTitle: this.productParam.formType === 1 ? '新增产品' : '编辑产品',
        newTitle: type === 1 ? '合同收费标准' : '导入件套',
      };
      this.$emit('changePage', data);
    },
    validateHalfQhdc(value, name) {
      if (
        this.showType === 1 &&
        (!this.customerContract || (this.customerContract && this.customerContract.halfQhdcServiceFee == null)) &&
        this.formData.settleType !== 2
      ) {
        this.errorTip('本服务尚未确定收费标准');
        this.formData[name] = false;
      }
    },
    validateContract(value, name) {
      if (this.showType === 1 && !this.customerContract && this.formData.settleType !== 2) {
        this.errorTip('本服务尚未确定收费标准');
        this.formData[name] = false;
      }
    },
    addXhfwJrTable() {
      if (this.xhfwJrTable.length < 20) {
        const data = {
          configMin: null,
          configMax: null,
          rate: null,
          minUseDays: null,
        };
        this.xhfwJrTable.push(data);
      }
    },
    deleteXhfwJrTable(index) {
      this.xhfwJrTable.splice(index, 1);
    },
    changeConsigneeType(value) {
      if (value === 1) {
        this.isNeedJrshow = false;
      } else {
        this.formData.isNeedJr = false;
        this.isNeedJrshow = true;
      }
    },
    changeScroll() {
      this.$refs.formStorage.blur();
    },
    initStorageList() {
      if (this.storageList && this.storageList.length <= 0) {
        this.getStorageList('');
      }
    },
    getFormStorage(data) {
      const xhfwStorage = {
        xhfwStorageProvince: data.addressProvince,
        xhfwStorageCity: data.addressCity,
        xhfwStorageDistrict: data.addressDistrict,
        xhfwStorageProvinceId: data.provinceId,
        xhfwStorageCityId: data.cityId,
        xhfwStorageDistrictId: data.areaId,
        xhfwStorageName: data.shortName,
        xhfwStorageDetail: data.addressDetail,
      };
      this.formData.productXHFWDto = Object.assign({}, this.formData.productXHFWDto, xhfwStorage);
      this.xhfwAddress = [];
      console.log('data', data);
      if (data.provinceId) {
        this.xhfwAddress = [data.provinceId];
      }
      if (data.provinceId && data.cityId) {
        this.xhfwAddress = [data.provinceId, data.cityId];
      }
      if (data.provinceId && data.cityId && data.areaId) {
        this.xhfwAddress = [data.provinceId, data.cityId, data.areaId];
      }
    },
    getStorageList(name, status = false) {
      this.ajax({
        type: 'POST',
        url: '/malicrm/storage/queryByName',
        data: {
          shortName: name,
        },
        loading: true,
        success: (res) => {
          if (res.code === 200) {
            if (res.result) {
              this.storageList = res.result;
              if (status && this.formStorage !== null) {
                const fd = this.storageList.filter((v) => v.shortName === this.formStorage.shortName);
                if (fd.length <= 0) {
                  const item = {
                    id: null,
                    shortName: this.formStorage.shortName,
                  };
                  this.storageList.unshift(item);
                }
              }
            } else {
              this.storageList = [];
            }
          }
        },
      });
    },
    initPortName() {
      if (this.portList && this.portList.length <= 0) {
        this.getPortName('');
      }
    },
    focusEpicSearch() {
      if (this.epicSupplierDataList && this.epicSupplierDataList.length <= 0) {
        this.getEpicSupplierList('');
      }
    },
    focusSupplier() {
      if (this.supplierdataList && this.supplierdataList.length <= 0) {
        this.getSupplierList('');
      }
    },
    getPortName(name) {
      this.ajax({
        type: 'POST',
        url: '/malicrm/port/queryByName',
        data: {
          portName: name,
        },
        loading: true,
        success: (res) => {
          if (res.code === 200) {
            if (res.result) {
              this.portList = res.result;
            } else {
              this.portList = [];
            }
          }
        },
      });
    },
    testJrRules() {
      const data = this.xhfwJrTable;
      if (data.length > 0) {
        let status = true;
        if (data.length === 0) {
          // 一行数据都没有则提示
          this.errorTip('请填写供应链金融服务');
          status = false;
          return false;
        }
        if (data.length === 1) {
          // 只有一行的情况
          const item = data[0];
          if (item.configMin !== 1) {
            this.errorTip('第一行最小值必须是1');
            status = false;
            return false;
          }
          if (!item.configMin) {
            this.errorTip('请填写最小天数');
            status = false;
            return false;
          }
          if (!item.minUseDays) {
            this.errorTip('请填写最小使用天数');
            status = false;
            return false;
          }
          if (!item.rate) {
            this.errorTip('请填写金融服务费利率(年化%)');
            status = false;
            return false;
          }
        }

        if (data.length > 1) {
          // 多行校验规则
          if (data[0].configMin !== 1) {
            // 多行 第一行必须为1
            this.errorTip('第一行最小值必须是1');
            status = false;
            return false;
          }
          if (!data[0].minUseDays) {
            this.errorTip('请填写最小使用天数');
            status = false;
            return false;
          }

          const len = data.length;
          for (let i = 0; i < data.length; i++) {
            const item = data[i];
            const lastItem = data[i - 1]; // 上一行的对象
            if (!item.configMin) {
              // 判断最小天数是否填写
              this.errorTip('请填写最小天数');
              status = false;
              break;
            }
            if (!item.rate) {
              // 判断是否填写金融服务费
              this.errorTip('请填写金融服务费利率(年化%)');
              status = false;
              break;
            }
            if (len - 1 !== i) {
              // 判断是不是最后一行
              if (!item.configMax) {
                // 判断最小天数是否填写
                this.errorTip('请填写最大天数');
                status = false;
                break;
              }
              if (item.configMin >= item.configMax) {
                // 如果最小天数大于最大天数
                this.errorTip('最小天数不能大于或等于最大天数');
                status = false;
                break;
              }
            }
            if (i >= 1) {
              // 从第二行开始判断
              if (item.configMin !== Number(lastItem.configMax) + 1) {
                this.errorTip('利率每行的最小值必须是上一行最大值加1');
                status = false;
                break;
              }
            }
          }
        }
        return status;
      } else {
        this.errorTip('请填写供应链金融服务');
        return false;
      }
    },
    queryProductLabelCn(value, cb) {
      // 搜索中文品名
      this.ajax({
        type: 'POST',
        url: 'malicrm/offer/queryOfferProductLabel',
        data: {
          nameCn: value,
          category: this.qhdcForm.category,
          country: this.qhdcForm.country,
          plantNo: this.qhdcForm.plantNo,
        },
        success: (res) => {
          if (res.code === 200) {
            if (res.result !== null) {
              cb(res.result);
            } else {
              const arr = [];
              cb(arr);
            }
          }
        },
      });
    },
    // 采购选择事件
    getPurchaseChange(e) {
      const item = this.purchaseOption.filter((item) => {
        return item.value === e;
      });
      if (item && item.length > 0) {
        this.formData.productQHDCDto.userNames = item[0].label;
      } else {
        this.formData.productQHDCDto.userNames = null;
      }
    },
    queryProductLabelEn(value, cb) {
      // 搜索英文品名
      this.ajax({
        type: 'POST',
        url: 'malicrm/offer/queryOfferProductLabel',
        data: {
          nameEn: value,
          category: this.qhdcForm.category,
          country: this.qhdcForm.country,
          plantNo: this.qhdcForm.plantNo,
        },
        success: (res) => {
          if (res.code === 200) {
            if (res.result !== null) {
              cb(res.result);
            } else {
              const arr = [];
              cb(arr);
            }
          }
        },
      });
    },
    handleName(item) {
      // 获取英文品名
      // eslint-disable-next-line prefer-rest-params
      const data = arguments[1];
      data.nameCn = item.nameCn;
      data.nameEn = item.nameEn;
      data.category = this.qhdcForm.category;
      data.country = this.qhdcForm.country;
      data.plantNo = item.plantNo;
      data.labelId = item.id;
      data.url = item.url;
    },
    nameFocus(item) {
      setTimeout(() => {
        if (!item.labelId) {
          item.nameCn = null;
          item.nameEn = null;
        } else {
          return false;
        }
      }, 200);
    },
    clearNameLabel(item) {
      item.labelId = null;
      item.nameCn = null;
      item.nameEn = null;
      item.url = null;
    },
    changeCateGory() {
      this.qhdcForm.country = null;
      this.qhdcForm.plantNo = [];
      this.countryList = [];
      this.plantList = [];
      this.getCountry();
      this.isCatSelecting = true;
      this.resetProductGoodsList();
    },
    handleShowBottomSection() {
      console.log('dd');
    },
    changeCountry() {
      this.qhdcForm.plantNo = [];
      this.plantList = [];
      this.getPlantNo();
      this.isCatSelecting = true;
      this.resetProductGoodsList();
    },
    changePlantNo(plantNo) {
      this.isCatSelecting = true;
      const filterProduct = this.formData.productGoodsDtoList.filter((v) => {
        if (plantNo.includes(v.plantNo)) {
          return v;
        }
      });

      if (filterProduct.length === 0) {
        this.resetProductGoodsList();
      } else {
        this.formData.productGoodsDtoList = filterProduct;
      }
    },
    getCountry() {
      // 获取国家信息
      this.ajax({
        type: 'POST',
        url: 'malicrm/approval/queryCountry',
        data: {
          category: this.qhdcForm.category,
          country: null,
          plantNo: null,
        },
        loading: true,
        success: (res) => {
          this.countryList = res.result;
        },
      });
    },
    getPlantNo() {
      // 获取厂号
      this.ajax({
        type: 'POST',
        url: 'malicrm/approval/queryPlantNo',
        data: {
          category: this.qhdcForm.category,
          country: this.qhdcForm.country,
          plantNo: null,
        },
        loading: true,
        success: (res) => {
          this.plantList = res.result;
        },
      });
    },
    getSupplierList(value, change) {
      // that.supplierdataList = [];
      this.ajax({
        type: 'POST',
        url: 'malicrm/offer/querySupplierBySupplierName',
        data: {
          supplierName: value,
        },
        loading: false,
        success: (res) => {
          this.supplierdataList = res.result;
          const item = this.supplierdataList.find((item) => item.id === this.formData.productQHDCDto.qhdcSupplierId);
          if ((item && item.supplierName !== 'Epic Meats Group Limited') || !item) {
            this.formData.productQHDCDto.userIds = null;
            this.formData.productQHDCDto.epicSupplier = null;
            this.formData.productQHDCDto.epicSupplierId = null;
          }
          if (item && item.supplierName === 'Epic Meats Group Limited') {
            this.purchaseOption = item.purchases;
          }
          // 采购下拉没有就置空
          if (change === 'edit') {
            const userIds = Object.assign([], this.formData.productQHDCDto.userIds);
            const tempUserIds = Object.assign([], this.formData.productQHDCDto.userIds);
            if (userIds && userIds.length > 0) {
              for (let i = 0; i < userIds.length; i++) {
                const isIn = this.purchaseOption.find((item) => {
                  return item.value === userIds[i];
                });
                if (!isIn) {
                  const index = tempUserIds.findIndex((item) => {
                    return item === userIds[i];
                  });
                  if (index > -1) {
                    tempUserIds.splice(index, 1);
                  }
                }
              }
              this.formData.productQHDCDto.userIds = tempUserIds;
            }
          }
        },
      });
    },
    getEpicSupplierList(value) {
      this.ajax({
        type: 'POST',
        url: 'malicrm/offer/querySupplierBySupplierName',
        data: {
          supplierName: value,
        },
        loading: false,
        success: (res) => {
          this.epicSupplierDataList = res.result;
        },
      });
    },
    getEpicSupplierChange(val) {
      if (val) {
        const epicArr = this.epicSupplierDataList.filter((item) => {
          return item.id === val;
        });
        if (epicArr) {
          const epicItem = epicArr[0];
          this.formData.productQHDCDto.epicSupplier = epicItem.supplierName;
        }
      }
    },
    changeIcoterm(value) {
      if (value === 1) {
        this.formData.productQHDCDto.qhdcIsNeedInsurance = false;
      }
    },
    showSelect(val) {
      this.isCatSelecting = true;
      this.showType = val;
      this.formData.category = val;
      this.formData.contractPrincipalId = '';
      this.formData.contractPrincipalName = '';
      this.totalPrice();
      this.getPrincipalName();
      if (val === 1) {
        this.getPortName('');
        this.getSupplierList('');
        this.getEpicSupplierList('');
        const arr = this.formData.productGoodsDtoList;
        arr.forEach((v) => {
          v.labelId = null;
          v.nameCn = null;
          v.nameEn = null;
          v.goodsInfoFormat = this._tableGoodsInfo(v);
        });
      } else if (val === 2 || val === 3) {
        this.getStorageList('', true);
      }
      this.$nextTick(() => {
        const el = document.querySelector('#storageListTable');
        if (el) {
          el.addEventListener('scroll', this.changeScroll);
        }
      });
    },
    resetProductGoodsList() {
      if (!this.isCatSelecting) {
        return;
      }
      this.formData.productGoodsDtoList = [];
      const newProduct = {
        category: null,
        categoryName: null,
        country: null,
        countryName: null,
        id: '',
        nameCn: '',
        nameEn: '',
        plantNo: null,
        productId: '',
        unitPrice: '',
        weight: '',
        declarationKeyElements: '', // 申报要素
        packageCount: '', // 件数
        subLevelStandard: '', // 二级标
        url: null,
        // 新增
        plantNoArr: null, // 厂号多选
        isMultiplyPlantNo: null, // 是否选择了多个厂号
        isValid: false, // 是否通过校验
        goodsInfoFormat: '',
        isPlantNoInOption: false,
        hsCode: '',
      };
      this.formData.productGoodsDtoList.push(newProduct);
      this.isCatSelecting = false;
      this.totalPricedata = this.totalPrice();
      this.totalWeightData = this.totalWeight();
    },
    afterTypeChange() {
      this.getTableWidth();
      this.formData.productGoodsDtoList = [
        {
          category: '',
          categoryName: '',
          country: '',
          countryName: '',
          id: 0,
          nameCn: '',
          nameEn: '',
          plantNo: '',
          productId: 0,
          unitPrice: '',
          weight: '',
          declarationKeyElements: '', // 申报要素
          packageCount: '', // 件数
          subLevelStandard: '', // 二级标
          // 新增自定义属性
          plantNoArr: [], // 厂号多选
          isMultiplyPlantNo: false, // 是否选择了多个厂号
          isValid: true, // 是否通过校验
          goodsInfoFormat: '',
          isPlantNoInOption: false,
          hsCode: '',
          url: '',
        },
      ];
    },
    addProduct() {
      const index = this.formData.productGoodsDtoList.length - 1;
      const t = this.formData.productGoodsDtoList[index];
      const newProduct = {
        category: t.category,
        categoryName: t.categoryName,
        country: t.country,
        countryName: t.countryName,
        id: '',
        nameCn: '',
        nameEn: '',
        plantNo: t.plantNo,
        productId: '',
        unitPrice: '',
        weight: '',
        packageCount: '', // 件数
        subLevelStandard: '', // 二级标
        plantNoArr: t.plantNoArr, // 厂号多选
        isMultiplyPlantNo: t.isMultiplyPlantNo, // 是否选择了多个厂号
        isValid: false, // 是否通过校验
        goodsInfoFormat: '',
        isPlantNoInOption: false,
        hsCode: '',
      };
      const goodsInfoFormat = this._tableGoodsInfo(newProduct);
      newProduct.goodsInfoFormat = goodsInfoFormat;
      this.formData.productGoodsDtoList.push(newProduct);
    },
    confirmEvent() {
      this.formData.productGoodsDtoList.splice(this.proindex, 1);
      this.DialogData.dialogVisible = false;
      this.restTotal();
    },
    removeProduct(index) {
      const product = this.formData.productGoodsDtoList[index];
      const key = Object.keys(product);
      const hasValue = key.some(function (item) {
        return product[item];
      });
      if (hasValue) {
        this.proindex = index;
        erpConfirm('确定删除吗？').then(() => {
          this.formData.productGoodsDtoList.splice(this.proindex, 1);
          this.DialogData.dialogVisible = false;
          this.restTotal();
        });
        this.DialogData.dialogVisible = true;
      } else {
        this.formData.productGoodsDtoList.splice(index, 1);
        this.restTotal();
      }
    },

    submitFn() {
      this.formData.productQHDCDto.agencyId = this.formData.agencyId;
      this.agencyArr.forEach((item) => {
        if (item.agencyId == this.formData.agencyId) {
          this.formData.productQHDCDto.agencyName = item.shortName;
        }
      });
      // 清除校验
      this.$refs.form.validate((valid) => {
        if (valid) {
          let params = Object.assign({}, this.formData);
          if (params.category !== 1) {
            params.settleType = null;
          }
          params.operateType = this.productParam.formType; // this.formType;
          params.businessId = this.productParam.oppid;
          if (this.productParam.id) {
            params.id = this.productParam.id;
          }
          params = this.filterProductData(params);
          if (this.validateRules(params)) {
            if (params.settleType !== 2) {
              params.lumpDtos = null;
            }
            if (params.settleType === 2) {
              const tempLumpDtos = params.lumpDtos.filter((item) => {
                return item.configMin || item.configMax || item.fee;
              });
              tempLumpDtos.forEach((item, index) => {
                tempLumpDtos[index].contractId = this.contractId;
              });
              params.lumpDtos = tempLumpDtos;
            }
            if (!this.isAjaxLoding) {
              this.saveSubmit(params);
            }
          }
        } else {
          this.errorTip('业务不可为空');
          return false;
        }
      });
    },
    saveSubmit(params) {
      this.isAjaxLoding = true;
      if (params?.productCCDto?.ccInstoreDate) {
        params.productCCDto.ccInstoreDate = formatTime(params.productCCDto.ccInstoreDate, 'YYYY-MM-DD');
      }
      if (params?.productLYDto?.lyDepartDate) {
        params.productLYDto.lyDepartDate = formatTime(params.productLYDto.lyDepartDate, 'YYYY-MM-DD HH:mm');
      }
      console.log('paramsparamsparamsparamsparamsparamsparamsparamsparamsparamsparamsparams', params);
      this.ajax({
        type: 'POST',
        url: '/malicrm/business/operateProduct',
        data: {
          ...params,
        },
        loading: true,
        success: (res) => {
          if (res.code === 200) {
            this.isAjaxLoding = false;
            if (this.productParam.formType === 1) {
              this.successTip('添加成功', 2000);
            } else {
              this.successTip('编辑成功', 2000);
            }
            this.$emit('close', true);
          } else {
            this.isAjaxLoding = false;
            this.errorTip(res.message);
          }
        },
      });
    },
    // 现货填写柜号校验
    changeContainerNo(e, obj, objectName) {
      let value = e.target.value;
      let reg = /([a-zA-Z]){4}(\d){7}/;
      if (reg.test(value)) {
        this.formData[obj][objectName] = value;
      } else {
        this.formData[obj][objectName] = null;
      }
    },
    filterProductData(data) {
      // 根据选择项过滤空数据
      const filterData = JSON.parse(JSON.stringify(data));
      const { category } = filterData;
      if (category === 1) {
        // 期货代采业务
        filterData.isNeedXhfw = false; // 去除服务费用
        filterData.productQHDCDto.category = this.qhdcForm.category;
        filterData.productQHDCDto.country = this.qhdcForm.country;
        filterData.productQHDCDto.plantNo = this.qhdcForm.plantNo.join('、');
      }
      if (category === 2 || category === 3) {
        filterData.isNeedCg = false;
        filterData.isNeedTt = false;
        filterData.isNeedQg = false;
      }
      if (category === 4) {
        filterData.isNeedXhfw = false; // 去除服务费用
        filterData.isNeedCg = false;
        filterData.isNeedTt = false;
        filterData.isNeedQg = false;
        filterData.isNeedJr = false;
      }
      // 根据类别先过滤掉不存在的服务类别
      if (!filterData.isNeedXhfw) {
        filterData.productXHFWDto.xhfwServiceFee = null; //
      }
      if (category !== 1) {
        filterData.productQHDCDto = null;
      }
      if (filterData.isNeedJr && (filterData.category === 2 || filterData.category === 3)) {
        // 写入金融数据
        filterData.productXhfwJrConfigList = JSON.parse(JSON.stringify(this.xhfwJrTable));
      } else {
        filterData.productXhfwJrConfigList = null;
      }
      if (!filterData.isNeedCc) {
        filterData.productCCDto = null;
      }
      if (!filterData.isNeedLy) {
        filterData.productLYDto = null;
      }
      const tmpProductList = [];
      filterData.productGoodsDtoList.map((v) => {
        if (
          (v.nameEn === '' || v.nameEn === null) &&
          (v.nameCn === '' || v.nameCn === null) &&
          (v.packageCount === '' || v.packageCount === null) &&
          (v.subLevelStandard === '' || v.subLevelStandard === null) &&
          (v.unitPrice === '' || v.unitPrice === null) &&
          (v.weight === '' || v.weight === null)
        ) {
          return false;
        } else {
          tmpProductList.push(v);
        }
      });
      filterData.productGoodsDtoList = tmpProductList;
      const val = filterData.category;
      if (val === 4) {
        // 切换为仓储服务时去除英文品名
        const arr = filterData.productGoodsDtoList;
        arr.forEach((v) => {
          v.nameEn = null;
          v.packageCount = null;
          v.unitPrice = null;
          v.labelId = null;
          v.subLevelStandard = null;
          v.goodsInfoFormat = this._tableGoodsInfo(v);
        });
      }
      if (val === 1) {
        const arr = filterData.productGoodsDtoList;
        arr.forEach((v) => {
          v.subLevelStandard = null;
          v.packageCount = null;
        });
      }
      if (val === 2 || val === 3) {
        const arr = filterData.productGoodsDtoList;
        arr.forEach((v) => {
          v.labelId = null;
        });
      }

      return filterData;
    },
    cancelFn() {
      this.$emit('close');
    },
    changelumpVos(data) {
      const lumpVos = [
        {
          configMin: 1,
          configMax: 30,
          fee: null,
          contractId: null,
          orderId: null,
        },
        {
          configMin: 31,
          configMax: 45,
          fee: null,
          contractId: null,
          orderId: null,
        },
        {
          configMin: 46,
          configMax: 60,
          fee: null,
          contractId: null,
          orderId: null,
        },
        {
          configMin: 61,
          configMax: 75,
          fee: null,
          contractId: null,
          orderId: null,
        },
        {
          configMin: 76,
          configMax: 90,
          fee: null,
          contractId: null,
          orderId: null,
        },
      ];
      data.forEach((v) => {
        if (v.configMin >= 1 && v.configMax <= 30) {
          lumpVos[0].fee = v.fee;
        }
        if (v.configMin >= 31 && v.configMax <= 45) {
          lumpVos[1].fee = v.fee;
        }
        if (v.configMin >= 46 && v.configMax <= 60) {
          lumpVos[2].fee = v.fee;
        }
        if (v.configMin >= 61 && v.configMax <= 75) {
          lumpVos[3].fee = v.fee;
        }
        if (v.configMin >= 76 && v.configMax <= 90) {
          lumpVos[4].fee = v.fee;
        }
      });
      return lumpVos;
    },
    async initEditData() {
      let res = await httpPost('malicrm/business/productDetail', { id: this.productParam.id, businessType: this.data.category });
      if (res.code === 200) {
        this.formData.category = res.result.category;
        this.showType = res.result.category;
        this.formData.contractPrincipalId = res.result.contractPrincipalId;
        this.formData.contractPrincipalName = res.result.contractPrincipalName;
        this.isConsigneeFunding = res.result.isConsigneeFunding;
        this.formData.isNeedCc = res.result.isNeedCc;
        this.formData.isNeedCg = res.result.isNeedCg;
        this.formData.isNeedJr = res.result.isNeedJr;
        this.formData.isNeedLy = res.result.isNeedLy;
        this.formData.isNeedQg = res.result.isNeedQg;
        this.formData.isNeedTt = res.result.isNeedTt;
        this.formData.isNeedXhfw = res.result.isNeedXhfw;
        this.formData.isNeedHalfQhdc = res.result.isNeedHalfQhdc;
        if (res.result.isNeedQg) {
          //如果选中了清关服务，拿之前新增时选中的报关行
          this.formData.agencyId = res.result.agencyId;
        }
        if (res.result.settleType) {
          this.formData.settleType = res.result.settleType;
        }
        if (res.result.category === 1) {
          if (res.result.lumpVos && res.result.lumpVos.length > 0) {
            this.formData.lumpDtos = this.changelumpVos(res.result.lumpVos);
          }
          this.qhdcForm.category = res.result.productQHDCVo.category;
          this.qhdcForm.country = res.result.productQHDCVo.country;
          this.qhdcForm.plantNo = res.result.productQHDCVo.plantNo === '' ? [] : res.result.productQHDCVo.plantNo.split('、');

          this.formData.productQHDCDto = Object.assign(this.formData.productQHDCDto, res.result.productQHDCVo);
          /**新需求 报单转产品 如果是cif则不买保险*/
          if (this.formData.productQHDCDto.qhdcIncoterm === 1) {
            this.formData.productQHDCDto.qhdcIsNeedInsurance = false;
          }
          this.isNeedJrshow = this.formData.productQHDCDto.qhdcConsigneeType !== 1;

          this.countryList = [
            {
              country: res.result.productQHDCVo.country,
              countryName: res.result.productQHDCVo.countryName,
            },
          ];
          if (res.result.productQHDCVo.qhdcSupplierId && res.result.productQHDCVo.supplierName) {
            this.supplierdataList = [
              {
                id: res.result.productQHDCVo.qhdcSupplierId,
                supplierName: res.result.productQHDCVo.supplierName,
              },
            ];
          }
          if (this.qhdcForm.category) {
            this.getCountry();
          }
          if (this.qhdcForm.country) {
            this.getPlantNo();
          }
          this.getSupplierList('', 'edit');
          this.getEpicSupplierList('', 'edit');
          this.getPortName('');
        }
        if (res.result.productGoodsVos && res.result.productGoodsVos.length > 0) {
          this.formData.productGoodsDtoList = res.result.productGoodsVos;
        } else {
          this.formData.productGoodsDtoList = [
            {
              category: null,
              categoryName: null,
              country: null,
              countryName: null,
              id: 0,
              nameCn: null,
              nameEn: null,
              plantNo: null,
              productId: 0,
              unitPrice: null,
              weight: null,
              declarationKeyElements: null, // 申报要素
              packageCount: null, // 件数
              subLevelStandard: null, // 二级标
              plantNoArr: [], // 厂号多选
              isMultiplyPlantNo: false, // 是否选择了多个厂号
              isValid: true, // 是否通过校验
              goodsInfoFormat: null,
              isPlantNoInOption: false,
              hsCode: null,
              url: null,
            },
          ];
        }
        const newPros = { isValid: true, goodsInfoFormat: '', hsCode: '' };
        this.formData.productGoodsDtoList.forEach((item, index) => {
          if (item.country === 6) {
            item.countryName = '中国';
          }
          const goodsInfoFormat = this._tableGoodsInfo(item);
          Object.assign(this.formData.productGoodsDtoList[index], newPros);
          this.formData.productGoodsDtoList[index].goodsInfoFormat = goodsInfoFormat;
          this.formData.productGoodsDtoList[index].goodsInfoFormat = goodsInfoFormat;
        });
        if (res.result.productXHFWVo) {
          this.formData.productXHFWDto = Object.assign(this.formData.productXHFWDto, res.result.productXHFWVo);
          const data = res.result.productXHFWVo;

          this.xhfwAddress = [];
          if (data.xhfwStorageProvinceId) {
            this.xhfwAddress = [data.xhfwStorageProvinceId];
          }
          if (data.xhfwStorageProvinceId && data.xhfwStorageCityId) {
            this.xhfwAddress = [data.xhfwStorageProvinceId, data.xhfwStorageCityId];
          }
          if (data.xhfwStorageProvinceId && data.xhfwStorageCityId && data.xhfwStorageDistrictId) {
            this.xhfwAddress = [data.xhfwStorageProvinceId, data.xhfwStorageCityId, data.xhfwStorageDistrictId];
          }
          this.formStorage = { shortName: data.xhfwStorageName };
          this.getStorageList('', true);
        }

        if (this.formData.isNeedJr && (this.formData.category === 2 || this.formData.category === 3)) {
          this.xhfwJrTable = JSON.parse(JSON.stringify(res.result.productXhfwJrConfigList));
          // 金融修改
        }
        if (this.formData.isNeedCc) {
          const data = res.result.productCCVo;
          this.formData.productCCDto = Object.assign(this.formData.productCCDto, data);
          this.ccAddress = [];
          if (data.ccAddressProvinceId) {
            this.ccAddress = [data.ccAddressProvinceId];
          }
          if (data.ccAddressProvinceId && data.ccAddressCityId) {
            this.ccAddress = [data.ccAddressProvinceId, data.ccAddressCityId];
          }
          if (data.ccAddressProvinceId && data.ccAddressCityId && data.ccAddressAreaId) {
            this.ccAddress = [data.ccAddressProvinceId, data.ccAddressCityId, data.ccAddressAreaId];
          }
        }
        this.getPrincipalName();
        this.getSettleTypeOptionFun();
      }
      if (this.formData.isNeedLy) {
        const data = res.result.productLYVo;
        this.formData.productLYDto = Object.assign(this.formData.productLYDto, data);
        this.lyArriveAddress = [];
        if (data.arriveProvinceId) {
          this.lyArriveAddress = [data.arriveProvinceId];
        }
        if (data.arriveProvinceId && data.arriveCityId) {
          this.lyArriveAddress = [data.arriveProvinceId, data.arriveCityId];
        }
        if (data.arriveProvinceId && data.arriveCityId && data.arriveAreaId) {
          this.lyArriveAddress = [data.arriveProvinceId, data.arriveCityId, data.arriveAreaId];
        }

        this.lyDepartAddress = [];
        if (data.arriveProvinceId) {
          this.lyDepartAddress = [data.departProvinceId];
        }
        if (data.departProvinceId && data.departCityId) {
          this.lyDepartAddress = [data.departProvinceId, data.departCityId];
        }
        if (data.departProvinceId && data.departCityId && data.departAreaId) {
          this.lyDepartAddress = [data.departProvinceId, data.departCityId, data.departAreaId];
        }
      }
      if (this.formData.isNeedXhfw) {
        this.formData.productXHFWDto.xhfwServiceFee = res.result.productXHFWVo.xhfwServiceFee;
      }
      this.showType = res.result.category;
      this.totalPricedata = this.totalPrice();
      this.totalWeightData = this.totalWeight();
      this.$nextTick(() => {
        const el = document.querySelector('#storageListTable');
        if (el) {
          el.addEventListener('scroll', this.changeScroll);
        }
      });
    },
    restTotal() {
      this.totalPricedata = this.totalPrice();
      this.totalWeightData = this.totalWeight();
    },
    totalWeight() {
      let weight = 0;
      this.formData.productGoodsDtoList.forEach((v) => {
        if (v.weight !== '') {
          weight = bigDecimal.add(weight, v.weight);
        }
      });
      return this._thousandBitSeparator(weight);
    },
    totalPrice() {
      let price = 0;
      this.formData.productGoodsDtoList.forEach((v) => {
        if (v.price !== '' && v.unitPrice !== '') {
          let count = bigDecimal.multiply(v.weight, v.unitPrice);
          count = this._formatFloat(Number(count), 2);
          price = bigDecimal.add(price, count);
        }
      });
      if (isNaN(price) || price === 0) {
        return '0.00';
      } else {
        const index = price.indexOf('.');
        if (index > -1) {
          const subStr = price.substr(index + 1);
          if (subStr.length >= 2) {
            price = this._formatFloat(Number(price), 2);
          }
        }
        const str = this._thousandBitSeparator(price);
        return str === '' ? 0.0 : str;
      }
    },
    clickTag(url) {
      if (url && url !== '') {
        const link = document.createElement('a');
        link.href = url;
        link.target = '_blank';
        link.click();
      }
    },
    getTotalPrice(unitPrice, weight) {
      unitPrice = unitPrice
        .toString()
        .replace(/[^\d.]/g, '')
        .replace(/\.{2,}/g, '.'); // 清除“数字”和“.”以外的字符
      weight = weight
        .toString()
        .replace(/[^\d.]/g, '')
        .replace(/\.{2,}/g, '.'); // 清除“数字”和“.”以外的字符
      let total;
      if (unitPrice && unitPrice !== '' && weight && weight !== '') {
        total = bigDecimal.multiply(unitPrice.toString(), weight.toString());
        if (isNaN(total)) {
          total = '';
        } else {
          const index = total.indexOf('.');
          if (index > -1) {
            const subStr = total.substr(index + 1);
            if (subStr.length >= 2) {
              total = this._formatFloat(total, 2);
            }
          }
          total = this._thousandBitSeparator(total);
        }
      } else {
        total = '';
      }
      return total;
    },
  },
};
</script>

<style lang="scss">
@import './style/product.scss';
.form-item-box {
  .el-form-item__label {
    line-height: 40px !important;
    margin-right: 20px;
  }
}
</style>
