<template>
  <!--国际采购服务 -->
  <div class="mt-20" v-if="checked">
    <div class="mali-proitem__header">供应链金融服务</div>
    <div class="mali-proitem__body" v-if="checked && settleType !== 2">
      <table
        class="productCate__table finan-table mt-20"
        style="width: 100%; margin-top: 0px"
        cellspacing="0"
        cellpadding="0"
      >
        <tr>
          <th class="th-120">
            <span>最小天数</span>
          </th>
          <th class="th-120">
            <span>最大天数</span>
          </th>

          <th class="th-120">
            <span>利率(年化%)</span>
          </th>
          <th class="th-120">
            <span>最小使用天数</span>
          </th>
        </tr>
        <tr v-for="(item, index) in data" :key="index">
          <td class="port__inner">{{ emptyName(item.configMinDisplay) }}</td>
          <td class="port__inner">{{ emptyName(item.configMaxDisplay) }}</td>
          <td class="port__inner">{{ emptyName(item.rate) }}</td>
          <td class="port__inner">{{ emptyName(item.minUseDaysDisplay) }}</td>
        </tr>
      </table>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    settleType: [Number],
    checked: {
      // 是否选择该服务
      type: [Boolean],
      default: true,
    },
    data: {
      // 产品信息
      type: [Array],
      default: function () {
        return [];
      },
    },
  },
  methods: {
    emptyName(val) {
      if (val === '' || val === null) {
        return '-';
      } else {
        return val;
      }
    },
  },
};
</script>

<style></style>
