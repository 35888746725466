<template>
  <CrmFormLayout :title="comTitle" v-if="pageShow == 0" @close="cancel(false)">
    <div class="product-detail-page">
      <div>
        <el-form ref="form" :show-message="true" :model="formData">
          <el-row style="border: 1px solid #e3e4e6">
            <el-col :span="12">
              <div class="mali-proitem__flex">
                <div class="mali-flex__label">业务</div>
                <div class="mali-flex__form" style="width: 248px">
                  <div class="flex-between">
                    <div>{{ formData.categoryName }}</div>
                  </div>
                </div>
              </div>
            </el-col>

            <el-col :span="12">
              <div class="mali-proitem__flex">
                <div class="mali-flex__label">签约主体</div>
                <div class="mali-flex__form" style="width: 248px">
                  <div class="flex-between">
                    <div>{{ formData.contractPrincipalName }}</div>
                  </div>
                </div>
              </div>
            </el-col>
          </el-row>

          <xhfwServices :data="formData.productXHFWVo" v-if="formData.category === 2 || formData.category === 3"></xhfwServices>
          <!-- 港口信息 -->
          <port-table :data="formData.productQHDCVo" :settleTypeDesc="formData.settleTypeDesc" v-if="formData.category === 1"></port-table>
          <information
            :data="formData.productQHDCVo"
            :type="formData.category"
            v-if="formData.category === 1"
            :tradeCurrencyName="formData.tradeCurrencyName"
          ></information>

          <div class="total-bar" v-if="formData.category < 4" style="margin-top: 8px">
            <div>
              <span class="total-bar__info" style="margin-right: 30px">总货值({{ unit }})：{{ formData.totalValueString }}</span>
              <span class="total-bar__info">总重量(kg)：{{ formData.totalWeightString }}</span>
            </div>
            <div>
              <!--anki-商机5-->
              <div style="float: left" v-if="hasPermission('crm:business:batchDownload')" class="label-donwload-wrap" @click.stop="downLoadZip">
                <img src="../../../../../assets/images/erp/download-label.png" alt />
                <span style="position: relative; top: 1px">下载标签</span>
              </div>
              <div style="float: left" v-if="formData.category === 1">
                <span class="total-bar__info">交易币种：</span>
                <span class="total-bar__info">{{ unit }}</span>
              </div>
            </div>
          </div>
          <productTable
            :data="formData.productGoodsVos"
            :type="formData.category"
            :currencyString="formData.productQHDCVo ? formData.productQHDCVo.qhdcTradeCurrencyString : null"
          ></productTable>

          <div class="accept-fee-table-detail" v-if="formData.category === 1 && formData.settleType === 2">
            <h4>收费标准</h4>
            <acceptFeeTable :lumpVos="formData.lumpVos"></acceptFeeTable>
          </div>

          <div v-if="formData.category == 1 && customerContract && formData.settleType !== 2">
            <span @click="changeShowPage(1)" style="display: inline-block; margin-top: 20px; color: #2878ff; font-size: 16px; cursor: pointer; font-size: 16px"
              >合同收费标准</span
            >
          </div>
          <!-- 半期货服务费 -->
          <half-qh-service
            v-if="formData.category === 1 && customerContract"
            service-name="半期货服务费"
            :settleType="formData.settleType"
            data-label="服务费"
            :value="customerContract.halfQhdcServiceFeeDisplay + customerContract.halfQhdcServiceFeeUnitDesc"
            :checked="formData.isNeedHalfQhdc"
          >
          </half-qh-service>
          <!-- 国际采购服务 -->
          <consignee
            v-if="formData.category === 1 && customerContract"
            service-name="国际采购服务"
            :settleType="formData.settleType"
            data-label="代采费"
            :category="formData.productQHDCVo.category"
            :cgFeeList="customerContract.cgFeeList"
            :checked="formData.isNeedCg"
          ></consignee>
          <!-- 提供收货人抬头 -->
          <consignee
            v-if="formData.category === 1"
            service-name="提供收货人抬头"
            data-label="收货人"
            :value="formData.productQHDCVo.qhdcTtConsignee"
            :checked="formData.isNeedTt"
          ></consignee>
          <!-- 清关服务 -->
          <QgConsignee
            v-if="formData.category === 1 && customerContract"
            :agencyName="formData.agencyName"
            service-name="清关服务"
            :settleType="formData.settleType"
            :agencyFeeList="customerContract.agencyFeeList"
            :category="formData.productQHDCVo.category"
            :checked="formData.isNeedQg"
          ></QgConsignee>
          <service-fee
            v-if="formData.category === 2 || formData.category === 3"
            service-name="服务费用"
            data-label="服务费（元/吨）"
            :value="formData.productXHFWVo ? formData.productXHFWVo.xhfwServiceFee : 0"
            :checked="formData.isNeedXhfw"
          ></service-fee>
          <!-- 供应链金融 -->
          <financial-services
            v-if="formData.isNeedJr"
            :checked="formData.isNeedJr"
            :settleType="formData.settleType"
            :data="formData.category === 1 ? (customerContract ? customerContract.interestRateConfigList : []) : formData.productXhfwJrConfigList"
          ></financial-services>
          <!-- 仓储服务 -->
          <storage-services :checked="formData.isNeedCc" :data="formData.productCCVo"></storage-services>
          <!-- 冷运服务 -->
          <cold-transport-services :checked="formData.isNeedLy" :data="formData.productLYVo"></cold-transport-services>
          <!-- 其他费用 -->
          <other-fee-table v-if="formData.category == 1 && otherFeeData.length > 0" :settleType="formData.settleType" :data="otherFeeData"></other-fee-table>
        </el-form>
        <!-- 查看 -->

        <div class="crm-button__group">
          <button class="crm-form__button" @click="cancel(false)">关闭</button>
        </div>
      </div>
    </div>
  </CrmFormLayout>
  <CrmFormLayout :title="comTitle" v-if="pageShow == 1" @close="pageShow = 0">
    <div class="product-detail-page">
      <contractDetail
        @cancel="pageCancel"
        :customerContract="customerContract"
        :currency="formData.productQHDCVo ? formData.productQHDCVo.qhdcTradeCurrency : 1"
        :oppId="eventId"
        v-if="customerContract"
      ></contractDetail>
    </div>
  </CrmFormLayout>
</template>

<script>
import portTable from './components/PortTable';
import productTable from './components/ProductTable';
import information from './components/information';
import FinancialServices from './components/FinancialServices';
import Consignee from './components/Consignee';
import ServiceFee from './components/ServiceFee';
import QgConsignee from './components/QgConsignee';
import StorageServices from './components/StorageServices';
import ColdTransportServices from './components/ColdTransportServices';
import XhfwServices from './components/XhfwServices';
import contractDetail from './components/ContractDetail';
import acceptFeeTable from './components/acceptFeeTable.vue';
import HalfQhService from './components/HalfQhService.vue';
import OtherFeeTable from './components/OtherFeeTable.vue';
import { handleBatchDownload, successMessage, getSuffix, batchCount } from '@/utils/publicMethods';
import { httpPost } from '@/api';
export default {
  components: {
    XhfwServices,
    portTable,
    productTable,
    FinancialServices,
    StorageServices,
    ColdTransportServices,
    Consignee,
    QgConsignee,
    information,
    contractDetail,
    acceptFeeTable,
    HalfQhService, // 半期货服务费
    OtherFeeTable, // 其他费用
    ServiceFee,
  },
  props: {
    customerContract: { type: [Object] },
    data: {
      // 回填表单数据
      type: [Object],
      default: function () {
        return {};
      },
    },
    eventId: {
      type: [String],
      value: '',
    },
    name: {
      type: [String],
      value: '',
    },
    businessType: {
      type: [String, Number],
      default: 1,
    },
  },
  data() {
    return {
      pageShow: 0, // 0 添加编辑产品页面 1 合同收费标准， 2 导入件套
      formData: {
        lumpVos: [],
        category: 1,
        categoryName: '',
        isNeedCc: false,
        isNeedCg: false,
        isNeedJr: false,
        isNeedLy: false,
        isNeedQg: false,
        isNeedTt: false,
        isNeedXhfw: false,
        isNeedHalfQhdc: true, // 半期货服务
        productCCVo: {
          ccAddressCity: '',
          ccAddressDetail: null,
          ccAddressDistrict: '',
          ccAddressProvince: '',
          ccInstoreDate: null,
          ccStorageDays: 0,
          ccStorageName: null,
          id: 0,
        },
        productGoodsVos: [
          {
            category: null,
            country: null,
            id: 0,
            nameCn: null,
            nameEn: null,
            plantNo: null,
            unitPrice: 0,
            weight: 0,
          },
        ],
        productXhfwJrConfigList: [],
        productLYVo: {
          id: 0,
          lyArriveCity: '',
          lyArriveDetail: null,
          lyArriveDistrict: '',
          lyArriveProvince: '',
          lyArriveStorageName: null,
          lyDepartCity: '',
          lyDepartDate: null,
          lyDepartDetail: null,
          lyDepartDistrict: '',
          lyDepartProvince: '',
          lyDepartStorageName: null,
          lyRequireTemp: null,
        },
        productQHDCVo: {
          id: 0,
          qhdcCgAgencyFee: 0,
          qhdcIncoterm: null,
          qhdcPortArrival: null,
          qhdcPortLoading: null,
          qhdcQgClearanceFee: 0,
          qhdcRemark: null,
          qhdcShippingDate: null,
          qhdcTtConsignee: null,
        },
        productXHFWVo: {
          id: 0,
          xhfwServiceFee: 0,
          xhfwServiceType: null,
          xhfwServiceTypeName: null,
        },
      },
      purchaseOption: [],
      supplierdataList: [],
      batchCount: batchCount,
    };
  },
  computed: {
    comTitle() {
      let res = '';
      if (this.pageShow === 0) {
        res = '产品详情';
      }
      if (this.pageShow === 1) {
        res = '合同收费标准';
      }
      return res;
    },
    unit() {
      const str = ['美元', '美元', '元', '元', ''];
      if (this.formData.category === 1) {
        return this.formData.productQHDCVo.qhdcTradeCurrencyString;
      } else {
        return str[Number(this.formData.category)];
      }
    },
    otherFeeData() {
      let tmp = [];
      // 按天计费
      if (this.formData.settleType === 1 && this.customerContract.dayOtherFeeList) {
        tmp = this.customerContract.dayOtherFeeList;
      }
      // 包干价
      if (this.formData.settleType === 2 && this.customerContract.bagOtherFeeList) {
        tmp = this.customerContract.bagOtherFeeList;
      }
      return tmp;
    },
  },
  methods: {
    closeDetail() {
      console.log('close');
    },
    downLoadZip() {
      const zipArr = [];
      this.formData.productGoodsVos.filter((item) => {
        if (item.url) {
          const fileName = item.plantNo + item.nameCn + '.' + getSuffix(item.url);
          zipArr.push({ name: fileName, url: item.url });
        }
      });
      if (zipArr.length === 0) {
        this.errorTip('没有要下载的标签');
      } else {
        // 批量下载实现
        console.log(batchCount.value);
        handleBatchDownload(zipArr, this.name + '-标签').then(() => {
          successMessage('下载成功');
        });
      }
    },

    changeShowPage(type) {
      this.pageShow = type;
    },
    closePage(type) {
      this.pageShow = type;
    },
    pageCancel() {
      // 展示返回
      this.pageShow = 0;
      const data = {
        type: 0,
        oldTitle: '',
        newTitle: '产品详情',
      };
      this.$emit('changePage', data);
    },
    async getDetails(id) {
      let res = await httpPost('malicrm/business/productDetail', { id, businessType: this.businessType });
      if (res.code === 200) {
        this.formData = res.result;
        console.log('formData这里需要拿到产品关行数据', this.formData);
        this.formData.productGoodsVos.forEach((item, index) => {
          if (item.country === 6) {
            this.formData.productGoodsVos[index].countryName = '中国';
          }
        });
        this.getSupplierList('');
      } else {
        this.errorTip(res.message);
      }
    },
    getSupplierList(value) {
      this.ajax({
        type: 'POST',
        url: 'malicrm/offer/querySupplierBySupplierName',
        data: {
          supplierName: value,
        },
        loading: true,
        success: (res) => {
          this.supplierdataList = res.result;
          let item;
          if (this.formData.productQHDCVo && this.formData.productQHDCVo.qhdcSupplierId) {
            item = this.supplierdataList.find((item) => item.id === this.formData.productQHDCVo.qhdcSupplierId);
          }
          if (item && item.supplierName === 'Epic Meats Group Limited') {
            this.purchaseOption = item.purchases;
          }
          // 采购下拉没有就置空
          let userIds = [];
          if (this.formData.productQHDCVo && this.formData.productQHDCVo.userIds) {
            userIds = Object.assign([], this.formData.productQHDCVo.userIds);
          }
          let nameArr = [];
          if (this.formData.productQHDCVo && this.formData.productQHDCVo.userNames) {
            nameArr = this.formData.productQHDCVo.userNames.split(',');
          }
          let tempUserIds = [];
          if (this.formData.productQHDCVo && this.formData.productQHDCVo.userIds) {
            tempUserIds = Object.assign([], this.formData.productQHDCVo.userIds);
          }
          if (userIds && userIds.length > 0) {
            for (let i = 0; i < userIds.length; i++) {
              const isIn = this.purchaseOption.find((item) => {
                return item.value === userIds[i];
              });
              if (!isIn) {
                const index = tempUserIds.findIndex((item) => {
                  return item === userIds[i];
                });
                if (index > -1) {
                  tempUserIds.splice(index, 1);
                }
                nameArr.splice(index, 1);
              }
            }
          }
          if (this.formData.productQHDCVo && this.formData.productQHDCVo.userIds) {
            this.formData.productQHDCVo.userIds = [];
            this.formData.productQHDCVo.userIds = tempUserIds;
            this.formData.productQHDCVo.userNames = nameArr.join(',');
          }
        },
      });
    },
    cancel() {
      // 取消操作
      if (this.pageShow === 0) {
        this.$emit('close', false);
      }
    },
  },
  created() {
    console.log('businessType', this.businessType);
    console.log('data', this.data);
    this.getDetails(this.data.id);
  },
};
</script>
<style lang="scss" scoped>
.accept-fee-table-detail {
  h4 {
    font-size: 16px;
    color: #333333;
    line-height: 40px;
    margin-top: 7px;
  }
}
.total-bar {
  .label-donwload-wrap {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    height: 16px;
    font-size: 14px;
    color: #2878ff;
    cursor: pointer;
    margin-right: 30px;
    img {
      margin-right: 4px;
    }
  }
}
.mali-proitem__flex {
  border: none;
}
</style>
