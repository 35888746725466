<template>
  <!--收货人抬头 -->
  <div class="mt-20" v-if="checked">
    <div class="mali-proitem__header agency-head">
      {{ serviceName }}
      <span>报关行/代理：{{ agencyName }}</span>
    </div>
    <div class="mali-proitem__body" v-if="checked && settleType !== 2">
      <table class="productCate__table finan-table mt-20" style="width: 100%; margin-top: 0px" cellspacing="0" cellpadding="0">
        <tr>
          <th class="th-120">
            <span>大类</span>
          </th>
          <th class="th-120">
            <span>大柜代理费</span>
          </th>
          <th class="th-120">
            <span>大柜最低吨数</span>
          </th>
          <th class="th-120">
            <span>小柜代理费</span>
          </th>
        </tr>
        <tr v-for="(item, index) in agencyFeeList" :key="index + 'qhdc'">
          <template v-if="item.category === category">
            <td class="port__inner">{{ item.categoryDesc }}</td>
            <td class="port__inner">{{ item.clearanceFeeLarge }} ({{ item.clearanceFeeLargeUnitDesc }})</td>
            <td class="port__inner">
              {{ filterName(item.clearanceFeeLargeMinWeight) }}
            </td>
            <td class="port__inner">{{ item.clearanceFeeSmall }} ({{ item.clearanceFeeSmallUnitDesc }})</td>
          </template>
        </tr>
      </table>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    agencyName: {
      type: [String],
      default: '默认的报关行',
    },
    serviceName: {
      type: [String],
      default: '服务名称',
    },
    append: {
      type: [String],
      default: '',
    },
    dataLabel: {
      type: [String],
      default: '标签文本',
    },
    settleType: {
      type: [Number],
    },
    checked: {
      // 是否选择该服务
      type: [Boolean],
      default: true,
    },
    category: {
      type: [String, Number],
      default: '',
    },
    agencyFeeList: {
      // 产品信息
      type: [Array],
      default() {
        return [];
      },
    },
  },
  computed: {
    localValue() {
      if (!this.value) {
        return null;
      } else {
        return this.value.toString();
      }
    },
  },
  // created() {

  // },
  data() {
    return {
      disabled: true,
    };
  },
  methods: {
    emptyName(val) {
      if (val === '' || val === null) {
        return '-';
      } else {
        return val;
      }
    },
  },
};
</script>

<style scoped>
.agency-head {
  display: flex;
  justify-content: space-between;
}
</style>
