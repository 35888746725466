<template>
  <!--冷运服务 -->
  <div class="mt-20" v-if="checked">
    <div class="mali-proitem__header">冷运服务</div>
    <div class="mali-proitem__body">
      <div class="mali-proitem__inner">
        <div class="mali-group__left">
          <div class="mali-proitem__label">发货冷库名称</div>
          <div class="mali-proitem__form">
            <el-input
              v-model="filterData.lyDepartStorageName"
              :readonly="true"
            ></el-input>
          </div>
        </div>
        <div class="mali-group__right">
          <div class="mali-proitem__label">发货冷库地址</div>
          <div class="mali-proitem__form">
            <city-select
              :readonly="true"
              :data="[
                filterData.departProvinceId,
                filterData.departCityId,
                filterData.departAreaId,
              ]"
              size="small"
              class="inline-city"
            ></city-select>
            <el-input
              class="mali-input__370"
              v-model="filterData.lyDepartDetail"
              :readonly="true"
            ></el-input>
          </div>
        </div>
      </div>
      <div class="mali-proitem__inner">
        <div class="mali-group__left">
          <div class="mali-proitem__label">收货冷库名称</div>
          <div class="mali-proitem__form">
            <el-input
              v-model="filterData.lyArriveStorageName"
              :readonly="true"
            ></el-input>
          </div>
        </div>
        <div class="mali-group__right">
          <div class="mali-proitem__label">收货冷库地址</div>
          <div class="mali-proitem__form">
            <city-select
              :readonly="true"
              :data="[
                filterData.departProvinceId,
                filterData.departCityId,
                filterData.departAreaId,
              ]"
              size="small"
              class="inline-city"
            ></city-select>
            <el-input
              class="mali-input__370"
              v-model="filterData.lyArriveDetail"
              :readonly="true"
            ></el-input>
          </div>
        </div>
      </div>
      <div class="mali-proitem__inner">
        <div class="mali-group__left">
          <div class="mali-proitem__label">发货时间</div>
          <div class="mali-proitem__form">
            <el-input
              v-model="filterData.lyDepartDate"
              :readonly="true"
            ></el-input>
          </div>
        </div>
        <div class="mali-group__left" style="width: 200px; margin-right: 10px">
          <div class="mali-proitem__label">装货联系人</div>
          <div class="mali-proitem__form">
            <el-input
              v-model="filterData.lyLoadingContactsName"
              :readonly="true"
            ></el-input>
          </div>
        </div>
        <div class="mali-group__left" style="width: 200px; margin-right: 0">
          <div class="mali-proitem__label">装货人联系电话</div>
          <div class="mali-proitem__form">
            <el-input
              v-model="filterData.lyLoadingContactsTelephone"
              :readonly="true"
            ></el-input>
          </div>
        </div>
      </div>

      <div class="mali-proitem__inner">
        <div class="mali-group__left">
          <div class="mali-proitem__label">卸货联系人</div>
          <div class="mali-proitem__form">
            <el-input
              v-model="filterData.lyUnloadingContactsName"
              :readonly="true"
            ></el-input>
          </div>
        </div>
        <div class="mali-group__left" style="width: 200px; margin-right: 10px">
          <div class="mali-proitem__label">卸货人联系电话</div>
          <div class="mali-proitem__form">
            <el-input
              v-model="filterData.lyUnloadingContactsTelephone"
              :readonly="true"
            ></el-input>
          </div>
        </div>
        <div class="mali-group__left">
          <div class="mali-proitem__label">温度要求</div>
          <div class="mali-proitem__form">
            <el-input
              v-model="filterData.lyRequireTempName"
              :readonly="true"
            ></el-input>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CitySelect from '@/components/CitySelect/CitySelect.vue';
export default {
  components: {
    CitySelect,
  },
  props: {
    checked: {
      // 是否选择该服务
      type: [Boolean],
      default: true,
    },
    data: {
      // 产品信息
      type: [Object],
      default: function () {
        return {
          id: 0,
          lyArriveCity: '',
          lyArriveDetail: '',
          lyArriveDistrict: '',
          lyArriveProvince: '',
          lyArriveStorageName: '',
          lyDepartCity: '',
          lyDepartDate: '',
          lyDepartDetail: '',
          lyDepartDistrict: '',
          lyDepartProvince: '',
          lyDepartStorageName: '',
          lyRequireTemp: '',
        };
      },
    },
  },
  computed: {
    filterData() {
      const obj = {};
      for (const key in this.data) {
        if (this.data[key] === '' || this.data[key] === null) {
          obj[key] = '-';
        } else {
          obj[key] = this.data[key];
        }
      }
      return obj;
    },
  },
  methods: {},
};
</script>

<style></style>
