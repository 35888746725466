
// 线索基础模块信息
import { defineComponent, ref } from 'vue';
import useFilter from '@/hooks/useFilter';
import TableFileContent from '@/components/TableFileContent/TableFileContent.vue';
import { showFiles } from '@/utils/publicMethods';
export default defineComponent({
  name: 'oppCustomerInfo',
  props: ['data'],
  components: {
    TableFileContent,
  },
  setup(props) {
    const { empty } = useFilter();
    const isFile = (data) => {
      return data !== null && data !== '';
    };
    const rating = ref('');
    rating.value = (props.data as any).rating;
    return { empty, rating, isFile, showFiles };
  },
});
