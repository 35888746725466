<template>
  <div class="pdf-preview-page">
    <div
      class="pdf-area"
      v-loading="!load"
      element-loading-text="正在加载"
      element-loading-spinner="el-icon-loading"
      element-loading-background="rgba(0, 0, 0, 0.8)"
    >
      <template v-if="load">
        <div>
          <template v-for="index in numPages" :key="index">
            <div class="pdf-wrapper">
              <Pdf :src="rawArray" :page="index"></Pdf>
              <template v-for="(item, i) in singArr">
                <div
                  class="sign"
                  :style="{
                    top: 1680 - item.layerY + axioy + 'px',
                    left: item.layerX + axiox + 'px',
                  }"
                  :key="i"
                  v-if="item.pageNum === index"
                >
                  <div class="seal-item" :title="item.pageNum" :t="i">
                    <img :src="item.sealUrl" class="seal-img" />
                  </div>
                </div>
              </template>
            </div>
          </template>
        </div>
      </template>
    </div>
    <div class="pdf-bottom-groups">
      <el-button size="small" type="primary" class="erp-primary__btn confirm-btn" @click="confirmSeal">确认签章</el-button>
      <div class="cancel-btn" @click="cancelSeal">退出签章</div>
    </div>
  </div>
</template>
<script>
import Pdf from '@/components/Pdf/Pdf.vue';
import { ajax } from '@/api';
export default {
  props: {
    positionData: {
      type: Array,
      default() {
        return [];
      },
    },
    settleType: {
      type: Number,
      default: 1,
    },
    rawPdfUrl: {
      type: String,
      default: '',
    },
    id: {
      type: String,
      default: '',
    },
    contractPrincipalName: {
      type: String,
      default: '深圳马力',
    },
  },
  computed: {
    axiox() {
      const res = 50;
      return res;
    },
    axioy() {
      const res = -10;
      return res;
    },
    sealUrl() {
      // 印章的图片
      let url =
        this.contractPrincipalName === '深圳马力'
          ? 'https://eminio.mujidigital.com/saas/1458539678713152512/af364c07-fa06-41b9-b6e5-bfbfa5e7767f-openseal.png'
          : 'https://eminio.mujidigital.com/saas/1450066679060136960/3333333.png';
      return url;
    },
  },
  components: { Pdf },
  data() {
    return {
      rawArray: [],
      numPages: 1,
      pdfUrl: '',
      currentPage: 0, // pdf文件页码
      pageCount: 0, // pdf文件总页数
      singArr: [],
      version: '',
      // sealUrl: 'https://fastdfs.malleeglobal.com/group1/M00/07/51/rBEABmBVd4mAMZQwAAIUwUpzPd4850.png', // 印章的图片
      load: false,
    };
  },
  watch: {
    rawPdfUrl: {
      handler(n) {
        if (n) {
          this.getPdf(n, 'name');
        }
      },
      immediate: true,
    },
  },
  methods: {
    getPdf(url) {
      ajax({
        method: 'POST',
        url: '/malicrm/app/attachment/noAuth/previewPdf',
        data: {
          pdfUrl: url,
        },
      })
        .then((res) => {
          const { result } = res;
          const raw = window.atob(result); // 从这里 到下面 得到 rawArray
          const rawLength = raw.length;
          this.rawArray = new Uint8Array(new ArrayBuffer(rawLength));
          for (let i = 0; i < rawLength; i++) {
            this.rawArray[i] = raw.charCodeAt(i);
          }
          const PDFJS = window.pdfjsLib;
          PDFJS.getDocument(this.rawArray).then((pdfDoc) => {
            // 初始化pdf
            this.numPages = pdfDoc.numPages;
            console.log(this.numPages);
          });

          this.handleSealArrFn();
        })
        .catch(() => {
          return false;
        });
    },

    confirmSeal() {
      console.log(111);
      this.$emit('sure', this.version);
    },
    cancelSeal() {
      this.$emit('close');
    },
    handleSealArrFn() {
      // {layerX: 495, layerY: 100, pageNum: 1, sealUrl: 'http://malicn.oicp.vip/group1/M00/01/65/rB8K1F8Gs4GAfBCyAAAJXhg4sJE828.png'}

      const keyWord = '乙方（盖章）';
      const positionItem = this.positionData.filter((item) => {
        return keyWord === item.keyWord;
      });
      console.log('positionItem', positionItem);
      // bug 1633  电子合同签章预览无法显示多个乙方签章位置信息
      // 这里可能有多个同名签章位置 2021-03-10
      if (positionItem && positionItem.length > 0) {
        this.singArr = [];
        positionItem[0].positionList.forEach((v) => {
          v.coordinateList.forEach((coord) => {
            const item = {
              layerX: null,
              layerY: null,
              pageNum: null,
              sealUrl: null,
            };
            item.pageNum = v.pageIndex;
            item.sealUrl = this.sealUrl;
            item.layerX = coord.posx * 2;
            item.layerY = coord.posy * 2;
            this.singArr.push(item);
          });
        });
      }
      this.load = true;
    },
  },
};
</script>
<style lang="scss" scoped>
.pdf-preview-page {
  padding-bottom: 25px;
  position: relative;
  .tips-area {
    display: flex;
    justify-content: flex-start;
    width: 1251px;
    margin-left: 20px;
    .tips-left {
      img {
        position: relative;
        top: 6px;
        margin-right: 7px;
      }
    }
    .tips-right {
      line-height: 24px;
      font-size: 14px;
      color: #1a1a1a;
      text-align: justify;
    }
  }
  .pdf-bar {
    width: 1251px;
    height: 30px;
    background-color: rgba(0, 0, 0, 0.5);
    margin-left: 20px;
    margin-bottom: -20px;
    line-height: 30px;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    .pdf-bar-input {
      width: 40px;
      text-indent: 10px;
      margin-right: 10px;
      margin-left: 10px;
    }
    .pre-btn {
      margin-right: 10px;
      cursor: pointer;
    }
    .next-btn {
      margin-right: 10px;
      cursor: pointer;
    }
  }
  .pdf-area {
    width: 1251px;
    padding: 20px;
    box-sizing: border-box;
    border: 2px dashed rgba(230, 230, 230, 1);
    background: rgba(245, 245, 245, 1);
    margin-left: 20px;
    margin-top: 19px;
    overflow-y: scroll;
    position: relative;
    height: 700px;
    .pdf-wrapper {
      position: relative;
      margin-bottom: 20px;
      .sign {
        position: absolute;
        width: 220px;
        height: 220px;
        top: 0;
        right: 0;
        z-index: 99;
        .seal-item {
          width: 220px;
          height: 220px;
          /*cursor: move;*/
          position: relative;
          .seal-img {
            width: 100%;
            object-fit: cover;
            /*cursor: move;*/
            touch-action: none;
          }
        }
      }
    }
  }
  .pdf-bottom-groups {
    display: flex;
    justify-content: center;
    margin-top: 25px;
    .confirm-btn {
      width: 140px;
      height: 40px;
      line-height: 25px;
      border-radius: 2px;
      background-color: #2878ff;
      text-align: center;
      color: #fff;
      border: 1px solid #2878ff;
      margin-right: 11px;
      cursor: pointer;
    }
    .cancel-btn {
      width: 140px;
      height: 40px;
      line-height: 40px;
      border-radius: 2px;
      background-color: #fff;
      text-align: center;
      color: #2878ff;
      border: 1px solid #2878ff;
      cursor: pointer;
    }
  }
}
</style>
