<template>
  <CrmFormLayout title="添加附件" @close="close">
    <div class="mali-edit__title f16">选择附件<i class="req"></i></div>
    <div v-if="type && type === 'opportunity'" class="mali-edit__form" style="padding-bottom: 20px">
      <el-row :gutter="20">
        <el-col :span="6" class="a-label-wrap">
          <span class="a-label">附件</span><span class="a-required">*</span><span class="a-coma">：</span>
          <el-select
            v-model="formData.attachmentType"
            placeholder="选择证件类型"
            value-key="value"
            style="width: 130px"
            @change="attachmentChange"
            size="small"
          >
            <el-option v-for="item in Options.business_attachment_type" :key="item.value" :label="item.label" :value="item"></el-option>
          </el-select>
        </el-col>
        <el-col :span="12" class="a-label-wrap" v-if="formData.attachmentType && formData.attachmentType.label === 'PI'">
          <!-- 发票号 供应商合同号 -->

          <span class="a-label">发票号</span><span class="a-required">*</span><span class="a-coma">：</span>
          <el-input
            size="small"
            style="width: 240px"
            class="mali-full__inputcom"
            placeholder="请输入发票号"
            v-model="formData.invoiceNo"
            clearable
            :maxlength="25"
          ></el-input>
        </el-col>
        <el-col :span="12" class="a-label-wrap" v-if="formData.attachmentType && formData.attachmentType.label === 'SC'">
          <span class="a-label">供应商合同号</span><span class="a-required">*</span><span class="a-coma">：</span>
          <el-input
            size="small"
            style="width: 240px"
            class="mali-full__inputcom"
            placeholder="请输入供应商合同号"
            v-model="formData.invoiceNo"
            clearable
            :maxlength="25"
          ></el-input>
        </el-col>
      </el-row>
      <FileContent :fromType="1" @success="getContractUrls" :max="1" :customData="formData.attachmentType" customType="card" class="mt-20"></FileContent>
    </div>
    <div class="crm-button__group">
      <button class="crm-form__button primary" @click="submitFn" v-if="formType < 3">保存</button>
      <button class="crm-form__button cancel" @click="cancelFn" v-if="formType < 3">取消</button>
    </div>
  </CrmFormLayout>
</template>

<script>
import FileContent from '@/components/FileContent/FileContent.vue';
import { store } from '@/store';
export default {
  props: {
    maxImg: {
      type: [Number, String],
      default: 1,
    },
    type: {
      // 附件类型
      type: [String],
    },
    formType: {
      // 表单操作类型 1 新增 2 修改 3查看 查看没有操作按钮
      type: [String, Number],
      default: 1,
    },
    source: {
      // 任务类型(来源)
      type: [String, Number],
      default: 1,
    },
    eventId: {
      // 任务类型(来源)
      type: [String, Number],
      default: 1,
    },
  },
  data() {
    return {
      dialogObj: {
        show: true,
        title: '添加附件',
      },
      Options: {
        // 字典下拉数据初始化

        business_attachment_type: store.state.dict.options.business_attachment_type,
      },
      formData: {
        invoiceNo: null,
        attachmentType: null,
      },
      attachmentTypeValue: null,
      disabled: false,
      isMessag: true,
      fileUrlArr: [],
    };
  },
  components: {
    FileContent,
  },
  methods: {
    /**
     * 选择附件change 事件
     */
    attachmentChange() {
      // 重置发票号，供应商合同号
      this.formData.invoiceNo = null;
    },
    getContractUrls(fileUrlArray) {
      this.fileUrlArr = fileUrlArray;
      if (fileUrlArray && fileUrlArray.length > 0) {
        this.attachmentTypeValue = fileUrlArray[0].customData.value;
      }

      if (this.fileUrlArr.length === 0) {
        this.isMessag = true;
      } else {
        this.isMessag = false;
      }
    },
    isMassge() {
      this.disabled = true;
    },
    submitFn() {
      if (this.disabled) return;
      let valid = true;
      let label = '';
      if (this.type === 'opportunity' && this.formData.attachmentType) {
        label = this.formData.attachmentType.label;
      }
      if (this.type === 'opportunity' && !label) {
        valid = false;
        this.errorTip('请填写带星号的必填项', 3000);
        return;
      }
      if (this.type === 'opportunity' && (label === 'PI' || label === 'SC')) {
        if (!this.formData.invoiceNo) {
          valid = false;
          this.errorTip('请填写带星号的必填项', 3000);
        }
      }
      if (valid && this.isMessag) {
        this.errorTip('请添加附件', 3000);
        this.disabled = true;
        setTimeout(() => {
          this.disabled = false;
        }, 3000);
      }
      if (valid && !this.isMessag) {
        const attachmentDtos = [];
        this.fileUrlArr.forEach((v) => {
          const params = {
            attachmentType: this.formData.attachmentType.value,
            operateType: this.formType,
            name: v.name,
            invoiceNo: this.formData.invoiceNo,
            source: this.source,
            url: v.url,
            eventId: this.eventId,
          };
          attachmentDtos.push(params);
        });
        this.ajax({
          type: 'POST',
          url: '/malicrm/attachment/batchAttachment',
          data: { attachmentDtos },
          loading: true,
          success: (res) => {
            if (res.code === 200) {
              if (this.source === 5) {
                this.successTip('保存成功', 2000);
              } else {
                this.successTip('添加成功', 2000);
              }
              this.$emit('close', true);
            } else {
              this.errorTip(res.message);
            }
          },
        });
      }
    },
    cancelFn() {
      this.$emit('close', false);
    },
  },
};
</script>
<style scoped></style>
