<template>
  <!-- 港口信息 -->
  <div>
    <table class="productCate__table mt-20" cellspacing="0" cellpadding="0">
      <tr>
        <th class="">
          <span class="">起运港</span>
        </th>
        <th class="" style="width: 90px"><span class="">目的港</span></th>
        <th class="th-160" style="width: 90px"><span>船期</span></th>
        <th class="th-160" style="width: 100px"><span>Incoterm</span></th>
        <th class="th-160" style="width: 120px"><span>柜型</span></th>
        <th class="th-160" style="width: 100px"><span>是否买保险</span></th>
        <th class="th-160" style="width: 136px"><span>保证金比例(%)</span></th>
        <th class="th-160" style="width: 130px">
          <span>计费方式</span>
        </th>
      </tr>
      <tr>
        <td class="port__inner" style="min-width: 100px">
          <el-tooltip
            effect="dark"
            :content="data.qhdcPortLoading"
            placement="top-start"
            :disabled="!data.qhdcPortLoading || (data.qhdcPortLoading && data.qhdcPortLoading.length <= 20)"
            class="tootip-over"
          >
            <div>{{ emptyName(data.qhdcPortLoading) }}</div>
          </el-tooltip>
        </td>
        <td class="port__inner">
          <el-tooltip
            effect="dark"
            :content="data.qhdcPortArrival"
            placement="top-start"
            :disabled="data.qhdcPortArrival && data.qhdcPortArrival.length <= 20"
            class="tootip-over"
          >
            <div>{{ emptyName(data.qhdcPortArrival) }}</div>
          </el-tooltip>
        </td>
        <td class="port__inner">
          {{ emptyName(data.qhdcShippingDate) }}
        </td>
        <td class="port__inner">{{ emptyName(data.qhdcIncotermName) }}</td>

        <td class="port__inner" style="min-width: 60px">
          {{ emptyName(data.qhdcContainerTypeString) }}
        </td>
        <td class="port__inner" style="min-width: 60px">
          {{ emptyName(data.qhdcIsNeedInsuranceDisplay) }}
        </td>
        <td class="port__inner" style="min-width: 60px">
          {{ emptyName(data.qhdcDepositRate) }}
        </td>
        <td class="port__inner">
          {{ emptyName(settleTypeDesc) }}
        </td>
      </tr>
    </table>
    <div class="pay-date-wrap">
      <h4>支付保证金/定金的时间</h4>
      <div class="txt">{{ payTimeDesc }}</div>
    </div>
    <div class="ele-declaration">
      <h4>备注</h4>
      <div class="show-remark">{{ emptyName(data.qhdcRemark) }}</div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    data: {
      type: [Object],
      default: function () {
        return {
          id: 0,
          qhdcCgAgencyFee: 0,
          qhdcIncoterm: 'string',
          qhdcPortArrival: 'string',
          qhdcPortLoading: 'string',
          qhdcQgClearanceFee: 0,
          qhdcRemark: '',
          qhdcShippingDate: '2019-03-29',
          qhdcTtConsignee: 'string',
        };
      },
    },
    settleTypeDesc: {
      type: [String],
    },
  },
  computed: {
    payTimeDesc() {
      let res = '-';
      const { payDepositDate } = this.data;
      if (payDepositDate === 1) {
        res = '订购单签署完成后的3个工作日内';
      }
      if (payDepositDate === 2) {
        res = '预计启运前的第7个工作日';
      }
      return res;
    },
  },
  methods: {
    stringSlice(val) {
      let str = '';
      if (val && val !== '') {
        if (val.length > 10) {
          str = val.substring(0, 10) + '...';
        } else {
          str = val;
        }
      } else {
        str = '-';
      }
      return str;
    },
    emptyName(val) {
      if (val === '' || val === null) {
        return '-';
      } else {
        return val;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.mt-20 {
  margin-top: 20px;
}
.port__inner {
  vertical-align: middle;
}
.port__desc {
  max-width: 284px;
  line-height: 1.5;
}
.pay-date-wrap {
  h4 {
    font-size: 16px;
    color: #333333;
    line-height: 40px;
    margin-top: 7px;
    font-weight: 400;
  }
  .txt {
    border: 1px solid #e3e4e6;
    background: #fff;
    padding: 15px 20px;
    color: #333;
    font-size: 14px;
  }
}
.ele-declaration {
  margin-bottom: 20px;
}
</style>
