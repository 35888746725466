import { reactive, ref } from 'vue';

export default (params) => {
  const formRef = ref(); // 表单校验对象使用
  type CommuniData = {
    topic?: string;
    contactTime?: string;
    content?: string;
    [propName: string]: any;
  };
  const formData: CommuniData = reactive({
    deadline: '',
    eventId: params.eventId,
    id: '',
    name: '',
    principalIds: [],
    priority: '',
    relevantIds: [],
    relevant: '',
    remark: '',
    source: params.eventType,
    status: '',
    eventType: params.eventType,
    ...params,
  });
  const rules = reactive({
    name: [
      {
        required: true,
        message: '请输入任务名称',
        trigger: 'blur',
      },
      {
        max: 20,
        message: '最大长度为20',
        trigger: 'blur',
      },
    ],
    deadline: [
      {
        required: true,
        message: '请选择截止日期',
        trigger: 'blur',
      },
    ],
    principalIds: [
      {
        required: true,
        message: '请选择负责人',
        trigger: 'blur',
      },
    ],
    status: [
      {
        required: true,
        message: '请选择状态',
        trigger: 'change',
      },
    ],
    priority: [
      {
        required: true,
        message: '请选择优先级',
        trigger: 'change',
      },
    ],
    source: [
      {
        required: true,
        message: '请选择相关事件',
        trigger: 'change',
      },
    ],
    remark: [
      {
        max: 1000,
        message: '最大长度为1000',
        trigger: 'blur',
      },
    ],
  });

  return { rules, formData, formRef };
};
