<template>
  <!-- 港口信息 -->
  <div style="overflow: auto">
    <table class="productCate__table mt-20" style="width: 100%" cellspacing="0" cellpadding="0">
      <tr>
        <th style="min-width: 100px">
          <span class="">现存冷库名称</span>
        </th>
        <th style="min-width: 100px"><span class="">现存冷库地址</span></th>
        <th style="min-width: 100px"><span class="">柜号</span></th>
        <th style="min-width: 100px"><span class="">保证金比例(%)</span></th>
        <th><span>上游名称</span></th>
        <th style="min-width: 100px"><span>上游联系人</span></th>
        <th style="min-width: 100px"><span>上游联系方式</span></th>
      </tr>
      <tr>
        <td class="port__inner" style="max-width: 120px">
          {{ emptyName(data.xhfwStorageName) }}
        </td>
        <td class="port__inner" style="max-width: 120px">
          {{ data.xhfwDepartAddress }}
        </td>
        <td class="port__inner">
          {{ emptyName(data.xhfwContainerNo) }}
        </td>
        <td class="port__inner">
          {{ emptyName(data.xhfwDepositRate) }}
        </td>
        <td class="port__inner">{{ emptyName(data.xhfwUpstreamName) }}</td>
        <td class="port__inner">
          {{ emptyName(data.xhfwUpstreamContactsName) }}
        </td>
        <td class="port__inner">
          {{ emptyName(data.xhfwUpstreamContactsTelephone) }}
        </td>
      </tr>
    </table>
  </div>
</template>

<script>
export default {
  props: {
    data: {
      type: [Object],
      default: function () {
        return {
          id: 0,
          xhfwDepartAddress: 'string',
          xhfwServiceFee: 0,
          xhfwServiceFeeString: 'string',
          xhfwServiceType: 'string',
          xhfwServiceTypeName: 'string',
          xhfwStorageCity: 'string',
          xhfwStorageCityId: 0,
          xhfwStorageDetail: 'string',
          xhfwStorageDistrict: 'string',
          xhfwStorageDistrictId: 0,
          xhfwStorageName: 'string',
          xhfwStorageProvince: 'string',
          xhfwStorageProvinceId: 0,
          xhfwUpstreamContactsName: 'string',
          xhfwUpstreamContactsTelephone: 'string',
          xhfwUpstreamName: 'string',
        };
      },
    },
  },

  methods: {
    emptyName(val) {
      if (val === '' || val === null) {
        return '-';
      } else {
        return val;
      }
    },
    dataAdress() {
      // data.xhfwStorageProvince +'-'+ data.xhfwStorageCity +'-' +data.xhfwStorageDistrict +'-'+data.xhfwStorageDetail
      if (this.data.xhfwStorageProvince) {
        if (this.data.xhfwStorageCity) {
          if (this.data.xhfwStorageDistrict) {
            if (this.data.xhfwStorageDetail) {
              return this.data.xhfwStorageProvince + '-' + this.data.xhfwStorageCity + '-' + this.data.xhfwStorageDistrict + '-' + this.data.xhfwStorageDetail;
            } else {
              return this.data.xhfwStorageProvince + '-' + this.data.xhfwStorageCity + '-' + this.data.xhfwStorageDistrict;
            }
          } else {
            return this.data.xhfwStorageProvince + '-' + this.data.xhfwStorageCity;
          }
        } else {
          return this.data.xhfwStorageProvince;
        }
      } else {
        return '';
      }
    },
  },
  filters: {
    emptyName(val) {
      if (val === '' || val === null) {
        return '-';
      } else {
        return val;
      }
    },
  },
};
</script>

<style></style>
