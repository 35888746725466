<template>
  <div>
    <table
      class="productCate__table"
      style="margintop: 20px"
      cellspacing="0"
      cellpadding="0"
    >
      <tr>
        <th class="th-120" style="width: 76px">
          <span>大类</span>
        </th>
        <th class="th-120">
          <span class>国家</span>
        </th>
        <th class="th-120" style="width: 120px">
          <span class>厂号</span>
        </th>
        <th class="th-120">
          <span class>供应商</span>
        </th>
        <th class="th-120" style="width: 120px">
          <span class>供应商合同号</span>
        </th>
        <th class="th-120" style="width: 104px">
          <div style="line-height: 1.1">
            供应商预付
            <br />比例(%)
          </div>
        </th>
        <th class="th-120" style="width: 106px">
          <span class>付款方式</span>
        </th>
        <th class="th-160" style="width: 90px">
          <span class>抬头类型</span>
        </th>
      </tr>
      <tr>
        <td class="port__inner" style="min-width: 76px">
          {{ emptyName(data.categoryName) }}
        </td>
        <td class="port__inner">{{ emptyName(data.countryName) }}</td>
        <td class="port__inner" style="max-width: 120px">
          <div class="name_cn_product">
            <el-tooltip
              effect="dark"
              :content="data.plantNo"
              placement="top-start"
              :disabled="data.plantNo && data.plantNo.length <= 20"
              class="tootip-over"
            >
              <div>{{ emptyName(data.plantNo) }}</div>
            </el-tooltip>
          </div>
        </td>
        <td class="port__inner" style="max-width: 250px">
          <el-tooltip
            effect="dark"
            :content="data.supplierName"
            placement="top-start"
            :disabled="
              !data.supplierName ||
              (data.supplierName && data.supplierName.length <= 10)
            "
            class="tootip-over"
          >
            <div>{{ emptyName(data.supplierName) }}</div>
          </el-tooltip>
        </td>
        <td class="port__inner" style="max-width: 120px">
          <el-tooltip
            effect="dark"
            :content="data.qhdcSupplierContractNo"
            placement="top-start"
            :disabled="
              !data.qhdcSupplierContractNo ||
              (data.qhdcSupplierContractNo &&
                data.qhdcSupplierContractNo.length <= 10)
            "
            class="tootip-over"
          >
            <div>{{ emptyName(data.qhdcSupplierContractNo) }}</div>
          </el-tooltip>
        </td>
        <td class="port__inner th-120" style="max-width: 104px">
          {{ emptyName(data.qhdcSupplierPaymentRatio) }}
        </td>
        <td class="port__inner th-120" style="width: 106px">
          {{ emptyName(data.qhdcPayTypeName) }}
        </td>
        <td class="port__inner" style="width: 90px">
          {{ emptyName(tradeCurrencyName) }}
        </td>
      </tr>
    </table>
    <div v-if="isShowPurchaseTd" class="show-epicSupplier">
      <span class="epic-label">Epic的供应商：</span>
      <el-tooltip
        effect="dark"
        :content="data.epicSupplier"
        placement="top-start"
        :disabled="true"
        class="tootip-over"
      >
        <div class="epic-value">{{ emptyName(data.epicSupplier) }}</div>
      </el-tooltip>
    </div>
    <el-dialog :append-to-body="true" v-model:visible="dialogVisible">
      <img width="100%" :src="dialogImageUrl" />
    </el-dialog>
  </div>
</template>

<script>
export default {
  props: {
    type: [String, Number],
    tradeCurrencyName: [String],
    data: {
      type: [Object],
      default: function () {
        return {};
      },
    },
  },
  computed: {
    // 判断是否展示采购字段
    isShowPurchaseTd() {
      return this.data.supplierName === 'Epic Meats Group Limited';
    },
  },
  data() {
    return {
      dialogImageUrl: '',
      dialogVisible: false,
    };
  },
  methods: {
    clickTag(url) {
      if (url) {
        const link = document.createElement('a');
        link.href = url;
        link.target = '_blank';
        link.click();
      }
    },
    emptyName(val) {
      if (val === '' || val === null) {
        return '-';
      } else {
        return val;
      }
    },
  },
};
</script>

<style lang="scss">
.name_cn_product {
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  display: block;
}
.mt-10 {
  margin-top: 10px;
}
.goods-tag {
  cursor: pointer;
}
.tootip-over {
  width: 100%;
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-all;
  white-space: nowrap;
}
.purchase-item {
  display: flex;
  align-items: center;
  height: 28px;
  margin-top: 24px;
  margin-bottom: -8px;
  .select-label {
    flex-basis: 60px;
    font-size: 16px;
    color: #333;
  }
}
.show-epicSupplier {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-top: 20px;
  margin-bottom: 20px;
  .epic-label {
    font-size: 16px;
    flex-basis: 114px;
    flex-shrink: 0;
    color: #333;
  }
  .epic-value {
    font-size: 16px;
    color: #333;
  }
}
</style>
