import { reactive, ref, toRefs } from 'vue';
type FormData = {
  businessId: string | number;
  follower: string;
  followerIds: any[];
  operateType: string | number;
  followerType: string | number;
  [propName: string]: any;
};
export default (params) => {
  const formRef = ref(); // 表单校验对象使用
  const formData: FormData = reactive({
    businessId: 0,
    follower: '',
    followerIds: [],
    operateType: '',
    followerType: 1,
    ...params,
  });
  const customFormData = reactive({
    shortArrays: [],
  });
  const rules = reactive({
    followerIds: [
      {
        required: true,
        message: '请选择跟单员',
        trigger: 'blur',
      },
    ],
  });
  const setFormData = (paramsData: any) => {
    for (const key in formData) {
      if (Object.prototype.hasOwnProperty.call(paramsData, key)) {
        formData[key] = paramsData[key];
      }
    }
  };
  return { rules, formData, formRef, setFormData, ...toRefs(customFormData) };
};
