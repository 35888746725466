
import { httpPost } from '@/api';
import ChooseMan from '@/components/ChooseMan/ChooseMan.vue';
import DictSelect from '@/components/DictSelect/DictSelect.vue';
import {
  ajaxLoading,
  errorMessage,
  formatTime,
  successMessage,
  setTitleLabel,
} from '@/utils/publicMethods';

import { computed, defineComponent, PropType, ref } from 'vue';
import useFormData from './useData';
export default defineComponent({
  components: { ChooseMan, DictSelect },
  props: {
    /** 表单新增编辑状态 1新增2编辑 3删除 */
    formType: {
      type: Number as PropType<number>,
    },
    /** 事件id  */
    eventId: {
      type: [Number, String],
    },
    /** 事件状态 1.线索 2.客户 3.商机 */
    eventType: {
      type: Number as PropType<number>,
    },
    /** 事件状态 1.线索 2.客户 3.商机 */
    data: {
      type: Object as PropType<any>,
    },
    scoreName: {
      type: String as PropType<string>,
    },
  },
  emits: ['close'],
  setup(props, ctx) {
    let paramData = {
      formType: props.formType,
      eventId: props.eventId,
      eventType: props.eventType,
      operateType: props.formType,
    };
    if ((props.formType as number) > 1) {
      paramData = Object.assign(paramData, props.data);
    }
    const { rules, formData, formRef } = useFormData(paramData);
    const scoreNameData = ref(props.scoreName);
    const close = (status = false) => {
      ctx.emit('close', status);
    };
    /** 提交表单 */
    const saveFormData = async () => {
      formRef.value.validate(async (valid) => {
        if (valid) {
          ajaxLoading.lock();
          formData.deadline = formatTime(formData.deadline, 'YYYY-MM-DD');
          const res = await httpPost('/malicrm/task/operateTask', formData);
          ajaxLoading.unLock();
          if (res.code === 200) {
            if (props.formType === 1) {
              successMessage('添加成功', 2000);
            } else {
              successMessage('编辑成功', 2000);
            }
            close(true);
          } else {
            errorMessage(res.message);
          }
        } else {
          return false;
        }
      });
    };
    const readOnly = computed(() => {
      return props.formType === 3;
    });
    return {
      scoreNameData,
      close,
      formData,
      saveFormData,
      rules,
      formRef,
      setTitleLabel,
      readOnly,
    };
  },
});
