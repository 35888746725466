
import { httpGet, httpPost } from '@/api';
import { reactive, ref, toRefs } from 'vue';
import { erpConfirm, errorMessage, exportFile, getRouterParams, successMessage } from '@/utils/publicMethods';
import router from '@/router';
import BaseInfo from './components/BaseInfo/BaseInfo.vue';
import CustomerInfo from './components/CustomerInfo/CustomerInfo.vue';
import OpportunityForm from './components/OpportunityForm/OpportunityForm.vue';
import useVisible from '@/hooks/useVisible';
import ViewContact from './components/ViewContact/index.vue';
import ViewCommunicateTitle from './components/ViewCommunicate/index.vue';
import ViewTask from './components/ViewTask/index.vue';
import ViewProduct from './components/ViewProduct/index.vue';
import RiseMali from './components/RiseMali/index.vue';
import RiseKehu from './components/RiseKehu/index.vue';
import ViewFunding from './components/ViewFunding/index.vue';
import ViewWDocumentary from './components/ViewWDocumentary/index.vue';
import ViewFundingMan from './components/ViewFundingMan/index.vue';
import ViewContract from './components/ViewContract/index.vue';
import ViewElecontarct from './components/ViewElecontarct/index.vue';
import ViewAttachment from './components/ViewAttachment/index.vue';
export default {
  name: 'OpportunityDetail',
  components: {
    BaseInfo,
    CustomerInfo,
    OpportunityForm,
    ViewContact,
    ViewCommunicateTitle,
    ViewTask,
    ViewProduct,
    RiseMali,
    RiseKehu,
    ViewFunding,
    ViewWDocumentary,
    ViewFundingMan,
    ViewContract,
    ViewElecontarct,
    ViewAttachment,
  },
  props: {},
  setup() {
    // 获取路由参数
    const { id, type: paramType, isRecycle, backFrom } = getRouterParams('OpportunityDetail');
    // 当前列表状态
    const type = ref(paramType as number);
    const detailsData = ref();
    const riseMaliRef = ref();
    const riseKehuRef = ref();
    const viewFundingRef = ref();
    const viewWDocumentaryRef = ref();
    const viewFundingManRef = ref();
    const load = ref(true);
    /** 编辑线索展示 */
    const { visible, showVisible } = useVisible();

    const leftTitleNames = reactive({
      ViewContactTitle: '联系人',
      ViewCommunicateTitle: '沟通记录',
      ViewTaskTitle: '任务',
      ViewProductTitle: '产品',
      ViewRiseMaliTitle: '抬头（马力指定）',
      ViewRiseKehuTitle: '抬头（客户指定）',
      ViewFundingTitle: '资金方',
      ViewWDocumentaryTitle: '单证跟单员',
      ViewFundingManTitle: '资管跟单员',
      ViewContractTitle: '合同',
      ViewElecontarctTitle: '电子合同',
      ViewAttachmentTitle: '附件',
    });
    // 返回操作
    const back = () => {
      if (backFrom) {
        router.push(backFrom);
      }
    };
    const exportOrderElement = () => {
      erpConfirm('确定导出订单合同吗？').then(() => {
        exportFile({
          type: 'POST',
          data: {
            id,
          },
          url: '/malicrm/business/exportOrderContract',
        });
      });
    };
    // 初始化数据
    const initData = async (status = true) => {
      if (status) {
        load.value = false;
      }
      const res = await httpGet(`/malicrm/business/businessDetail/${id}`);
      if (res.code === 200) {
        detailsData.value = res.result;
        load.value = true;
      } else {
        if (res.code === 9999100) {
          errorMessage(res.message);
          back();
        } else {
          errorMessage(res.message);
          back();
        }
      }
    };
    const updateDetails = () => {
      console.log('updateDetails');
    };
    const closeVisible = (status) => {
      visible.value = false;
      if (status) {
        initData(false);
      }
    };
    const refashProduct = () => {
      initData(true);
    };
    const getViewContactTitle = (str) => {
      leftTitleNames.ViewContactTitle = str;
    };
    const getViewCommunicateTitle = (str) => {
      leftTitleNames.ViewCommunicateTitle = str;
    };
    const getViewTaskTitle = (str) => {
      leftTitleNames.ViewTaskTitle = str;
    };
    const getViewProductTitle = (str) => {
      leftTitleNames.ViewProductTitle = str;
    };
    const getViewRiseMaliTitle = (str) => {
      leftTitleNames.ViewRiseMaliTitle = str;
    };
    const getViewRiseKehuTitle = (str) => {
      leftTitleNames.ViewRiseKehuTitle = str;
    };
    const getViewFundingTitle = (str) => {
      leftTitleNames.ViewFundingTitle = str;
    };
    const getViewWDocumentaryTitle = (str) => {
      leftTitleNames.ViewWDocumentaryTitle = str;
    };
    const getViewFundingManTitle = (str) => {
      leftTitleNames.ViewFundingManTitle = str;
    };
    const getViewContractTitle = (str) => {
      leftTitleNames.ViewContractTitle = str;
    };
    const getViewElecontarctTitle = (str) => {
      leftTitleNames.ViewElecontarctTitle = str;
    };
    const getViewAttachmentTitle = (str) => {
      leftTitleNames.ViewAttachmentTitle = str;
    };
    const deleteForm = () => {
      erpConfirm('确定删除吗？').then(async () => {
        const params = {
          id,
          operateType: 1,
          version: detailsData.value.version,
          customerId: detailsData.value.customerId,
        };
        const res = await httpPost('/malicrm/business/deReBusiness', params);
        if (res.code === 200) {
          successMessage('已移入回收站');
          router.push('/opportunity/opportunityList');
        } else {
          errorMessage(res.message);
        }
      });
    };
    initData();
    return {
      load,
      id,
      type,
      isRecycle,
      detailsData,
      back,
      ...toRefs(leftTitleNames),
      getViewContactTitle,
      getViewCommunicateTitle,
      getViewTaskTitle,
      getViewProductTitle,
      getViewRiseMaliTitle,
      getViewRiseKehuTitle,
      getViewFundingTitle,
      getViewWDocumentaryTitle,
      getViewFundingManTitle,
      getViewContractTitle,
      getViewElecontarctTitle,
      getViewAttachmentTitle,
      visible,
      showVisible,
      closeVisible,
      exportOrderElement,
      deleteForm,
      refashProduct,
      riseMaliRef,
      riseKehuRef,
      viewFundingRef,
      viewWDocumentaryRef,
      viewFundingManRef,
      updateDetails,
    };
  },
};
