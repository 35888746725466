<template>
  <!--仓储服务 -->
  <div class="mt-20" v-if="checked">
    <div class="mali-proitem__header">仓储服务</div>
    <div class="mali-proitem__body" v-if="checked">
      <div class="mali-proitem__inner">
        <div class="mali-group__left">
          <div class="mali-proitem__label">仓库名称</div>
          <div class="mali-proitem__form">
            <el-input
              v-model="filterData.ccStorageName"
              :readonly="true"
            ></el-input>
          </div>
        </div>
        <div class="mali-group__right">
          <div class="mali-proitem__label">仓库地址</div>
          <div class="mali-proitem__form">
            <city-select
              :readonly="true"
              :data="[
                filterData.ccAddressProvinceId,
                filterData.ccAddressCityId,
                filterData.ccAddressAreaId,
              ]"
              size="small"
              class="inline-city"
            ></city-select>
            <el-input
              class="mali-input__370"
              v-model="filterData.ccAddressDetail"
              :readonly="true"
            ></el-input>
          </div>
        </div>
      </div>
      <div class="mali-proitem__inner">
        <div class="mali-group__left">
          <div class="mali-proitem__label">预计入库时间</div>
          <div class="mali-proitem__form">
            <el-input
              v-model="filterData.ccInstoreDate"
              :readonly="true"
            ></el-input>
          </div>
        </div>
        <div class="mali-group__left">
          <div class="mali-proitem__label">期望存储天数</div>
          <div class="mali-proitem__form">
            <el-input
              v-model="filterData.ccStorageDays"
              :readonly="true"
            ></el-input>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CitySelect from '@/components/CitySelect/CitySelect.vue';
export default {
  components: {
    CitySelect,
  },
  props: {
    checked: {
      // 是否选择该服务
      type: [Boolean],
      default: true,
    },
    data: {
      // 产品信息
      type: [Object],
      default: function () {
        return {
          ccAddressCity: '深圳市',
          ccAddressDetail: 'string',
          ccAddressDistrict: '南山区',
          ccAddressProvince: '广东省',
          ccInstoreDate: '2019-03-29',
          ccStorageDays: 7,
          ccStorageName: 'string',
          id: 0,
        };
      },
    },
  },
  computed: {
    filterData() {
      const obj = {};
      for (const key in this.data) {
        if (this.data[key] === '' || this.data[key] === null) {
          obj[key] = '-';
        } else {
          obj[key] = this.data[key];
        }
      }
      return obj;
    },
  },
  methods: {
    emptyName(val) {
      if (val === '' || val === null) {
        return '-';
      } else {
        return val;
      }
    },
  },
};
</script>

<style></style>
