<template>
  <CrmFormLayout :title="titleLabel" @close="cancel">
    <div class="gray-info-abs">
      <i class="el-icon-warning"></i>请上传已经审核通过的，需要客户签署的电子合同。在此上传的合同将推送到牧集APP中引导客户在线签署。
    </div>
    <el-form ref="form" label-position="top" :show-message="true" :rules="formType != 3 ? rules : {}" :model="formData" style="margin-top: 20px">
      <div class="mali-edit__form">
        <el-row :gutter="80">
          <el-col :span="12">
            <el-form-item label="合同编号" prop="contractNo">
              <el-input
                v-model="formData.contractNo"
                :maxlength="50"
                clearable
                placeholder="请输入合同编号"
                :disabled="formType >= 2 && customData.status === 2"
                class="mali-full__input"
              >
              </el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </div>
      <el-form-item label prop="attachmentDto.url">
        <div class="upload-box ele" style="margin-top: 20px">
          <div class="uploadTitle">
            <h1>
              待签署的电子合同文件
              <i class="star" v-if="formType < 3">*</i>
            </h1>
          </div>
          <FileContent
            :max="1"
            :size="10"
            :readOnly="formType > 1"
            :fromType="formType > 1 ? 3 : 2"
            @success="getFileUrls"
            fileType="pdf"
            :fileList="fileUrlsArray"
            tips="允许类型：pdf  大小上限10M  只支持上传1个文件"
          ></FileContent>
        </div>
      </el-form-item>
    </el-form>
    <div class="crm-button__group" v-if="formType != 3">
      <button class="crm-form__button primary" @click="submit">
        {{ formType == 1 ? '预览合同并签章' : '保存' }}
      </button>
      <button class="crm-form__button cancel" @click="cancel">取消</button>
    </div>
    <div class="crm-button__group" v-else>
      <button class="crm-form__button primary" @click="cancel">关闭</button>
    </div>
  </CrmFormLayout>
  <!--pdf预览弹窗-->
  <el-dialog
    v-loading="pdfDialog.loading"
    element-loading-text="正在签署，请稍等"
    element-loading-spinner="el-icon-loading"
    element-loading-background="rgba(0, 0, 0, 0.8)"
    v-model="pdfDialog.visible"
    v-if="pdfDialog.visible"
    custom-class="print-dialog"
    title="马力签章"
    width="1291px"
    top="4vh"
    
    @close="closePdfDialog"
    :modal-append-to-body="false"
  >
    <PdfPreview @close="close" @sure="pdfSubmit" :positionData="positionData" :rawPdfUrl="pdfUrl" :contractPrincipalName='customData.customerContract.principalName'></PdfPreview>
  </el-dialog>
</template>
<script>
import FileContent from '@/components/FileContent/FileContent.vue';
import PdfPreview from './PdfPreview.vue';
import { ajaxLoading } from '@/utils/publicMethods';
export default {
  components: {
    FileContent,
    PdfPreview,
  },
  props: {
    customData: [Object],
    data: {
      // 回填表单数据
      type: [Object],
      default: function () {
        return {};
      },
    },
    formType: {
      // 表单操作类型 1 新增 2 修改
      type: [String, Number],
      default: 1,
    },
    status: {
      // 商机状态
      type: [String, Number],
      default: '',
    },
    eventId: {
      // 任务类型(来源)
      type: [String, Number],
      default: 3,
    },
  },
  computed: {
    readOnly() {
      return this.formType > 2;
    },
    titleLabel() {
      const arr = ['添加电子合同', '编辑电子合同'];
      return arr[this.formType - 1];
    },
  },

  data() {
    return {
      pdfDialog: {
        // pdf预览弹窗展示对象
        visible: false,
        loading: true,
      },
      pdfUrl: '',
      businessName: this.customData.name,
      contractName: this.customData.customerName,
      titleImage: '',
      fileNameArr: [],
      fileUrlArr: [],
      fileUrlsArray: [],
      fileType: '.pdf',
      keyword: '乙方（盖章）',
      formData: {
        id: null,
        attachmentDto: {
          name: '',
          url: '',
        },
        status: 1, // 签署状态
        operateType: this.formType,
        businessId: this.eventId,
        contractNo: '',
        prescribedRate: '',
        customerId: this.customData.customerId,
        isCounterSigned: false,
        version: null,
        uploadVos: [],
      },
      disabled: false,
      rules: {
        contractNo: [
          {
            required: true,
            message: '请输入合同编号',
            trigger: 'blur',
          },
        ],
        'attachmentDto.url': [
          {
            required: true,
            message: '请选择合同文件',
            trigger: 'blur',
          },
        ],
      },
      localFiles: null, // 本地文件信息
      fileId: null,
      positionData: [], // 马力签章坐标位置  关键字 乙方（盖章）
      ajaxLoading: true,
    };
  },
  methods: {
    closePdfDialog() {
      this.pdfDialog.visible = false;
    },
    getFileUrls(fileList) {
      if (fileList && fileList.length > 0) {
        this.formData.attachmentDto.name = fileList[0].name;
        this.formData.attachmentDto.url = fileList[0].url;
      } else {
        this.formData.attachmentDto.name = null;
        this.formData.attachmentDto.url = null;
      }
      console.log('formData', this.formData);
      this.$refs.form.validateField(['attachmentDto.url']);

      this.fileUrlArr = fileList;
      this.disabled = false;
    },
    isMassge() {
      // 点击添加文件时禁用保存按钮
      this.disabled = true;
    },
    submit() {
      if (this.ajaxLoading) {
        this.$refs.form.validate((valid) => {
          if (valid) {
            if (this.formData.attachmentDto.url && this.formData.attachmentDto.url !== '') {
              this.ajaxLoading = false;
              this.ajax({
                type: 'post',
                url: 'malicrm/eqb/electricContract/eqbElectricContractNoCheck',
                data: {
                  businessId: this.customData.id,
                  contractNo: this.formData.contractNo,
                },
                success: (res) => {
                  this.ajaxLoading = true;
                  if (res.code === 200) {
                    if (this.formType === 1) {
                      // 新增
                      this.submitForm();
                    } else {
                      this.pdfSubmit();
                    }
                  } else {
                    this.errorTip(res.message);
                  }
                },
              });
            } else {
              this.ajaxLoading = true;
              this.errorTip('请选择合同文件');
              this.disabled = true; // 防止保存按钮重复点击
              setTimeout(() => {
                this.disabled = false;
              }, 3000);
            }
          } else {
            return false;
          }
        });
      }
    },
    submitForm() {
      if (this.ajaxLoading) {
        this.ajaxLoading = false;
        this.getKeywordPos();
      }
    },
    getKeywordPos() {
      let keyWords = [
        {
          keyType: 1,
          keyword: '乙方（盖章）',
        },
        {
          keyType: 2,
          keyword: '甲方（签章）',
        },
      ];
      if (this.customData.isQhdc) {
        keyWords = [
          {
            keyType: 1,
            keyword: '乙方（盖章）',
          },
          {
            keyType: 2,
            keyword: '甲方（签章）',
          },
          {
            keyType: 2,
            keyword: '委托方（签章）',
          },
        ];
      }
      if (this.customData.isXhfw) {
        keyWords = [
          {
            keyType: 1,
            keyword: '乙方（盖章）',
          },
          {
            keyType: 2,
            keyword: '甲方（签章）',
          },
        ];
      }
      this.ajax({
        type: 'post',
        data: {
          fileName: this.formData.attachmentDto.name,
          fileUrl: this.formData.attachmentDto.url,
          keyWords: keyWords,
        },
        url: 'malicrm/app/contract/keywordPos',
        success: (res) => {
          this.ajaxLoading = true;
          if (res.code === 200) {
            this.positionData = res.result;
            this.pdfDialog.visible = true;
            this.pdfUrl = this.formData.attachmentDto.url;
          }
        },
      });
    },
    cancel(status) {
      // 取消操作
      this.$emit('close', status);
    },
    close() {
      this.pdfDialog.visible = false;
    },
    pdfSubmit() {
      ajaxLoading.lock();
      this.pdfDialog.loading = true;
      // 确认签章
      const params = {
        businessId: this.customData.id,
        contractNo: this.formData.contractNo,
        fileSaveDto: {
          fileName: this.formData.attachmentDto.name,
          fileUrl: this.formData.attachmentDto.url,
        },
        id: this.formData.id,
        keyword: this.keyword,
        posX: 70,
        posY: -25,
        status: this.formData.status,
        uploadVos: this.positionData,
        version: this.formData.version,
      };
      this.fullscreenLoading = true;
      this.ajax({
        type: 'post',
        data: params,
        url: 'malicrm/eqb/electricContract/saveEqbElectricContract',
        loading: true,
        success: (res) => {
          this.pdfDialog.loading = false;
          if (res.code === 200) {
            if (this.formType === 1) {
              this.successTip('马力签章成功');
            } else {
              this.successTip('保存成功');
            }

            this.$emit('close', true);
          } else {
            this.errorTip(res.message);
            ajaxLoading.unLock();
          }
          this.fullscreenLoading = false;
        },
      });
    },
  },
  created() {
    if (this.formType > 1) {
      // 修改就回填数据过来
      this.formData.businessId = this.data.businessId;
      this.formData.version = this.data.version;
      this.formData.contractNo = this.data.contractNo;
      this.formData.id = this.data.id;
      this.formData.status = this.data.status;
      this.formData.attachmentDto.name = this.data.fileName;
      this.formData.attachmentDto.url = this.data.fileUrl;
      this.fileUrlsArray = [
        {
          name: this.data.signleSignedFileName,
          url: this.data.signleSignedFileUrl,
        },
      ];
    }
  },
};
</script>

<style lang="scss" scoped>
.gray-info-abs {
  position: absolute;
  top: 0;
  height: 40px;
  display: flex;
  align-items: center;
  font-size: 14px;
  color: #888888;
  .el-icon-warning {
    margin-right: 4px;
    color: #c7c8cd;
  }
}
</style>
