
import { defineComponent, ref } from 'vue';
import Pagination from '@/components/Pagination/Pagination.vue';
import { erpConfirm, errorMessage, setTitleNum, successMessage, hasPermission } from '@/utils/publicMethods';
import useVisible from '@/hooks/useVisible';
import usePagination from '@/hooks/usePagination';
import ViewForm from './form.vue';
import { httpGet, httpPost } from '@/api';
export default defineComponent({
  props: ['eventId', 'eventType', 'scoreName', 'isHidden', 'source', 'status', 'businessNo', 'typeId', 'BusinessId', 'customerName', 'isBusiness'],
  components: { Pagination, ViewForm },
  emits: ['getSize', 'success'],
  setup(props, ctx) {
    const queryData = {
      businessId: props.eventId,
      followerType: 1,
    };
    const { paginationRef, tableData, refashTable, empty } = usePagination();
    const formType = ref(1); // 1新增2编辑 3删除
    const rowData = ref();
    const fundingName = ref('');
    const { visible } = useVisible();
    const isShowElDialog = ref(false);
    // 获取表格数据操作
    const getTableDatas = (data: Array<any>, total: number) => {
      tableData.value = data;
      ctx.emit('getSize', setTitleNum('单证跟单员', total));
    };
    // 新增编辑操作
    const operateTask = (type, row?) => {
      formType.value = type;
      if (row) {
        rowData.value = row;
      }
      visible.value = true;
    };
    // 表单关闭操作，重新触发数据更新
    const closeVisible = (status) => {
      visible.value = false;
      refashTable();
      if (status) {
        ctx.emit('success');
      }
    };
    const openDialog = async (row) => {
      console.log('isShowTanc');
      isShowElDialog.value = true;
      const res = await httpGet(`/malicrm/business/getFundingName/${row.fundingId}`);
      if (res.code === 200) {
        fundingName.value = res.result;
      } else {
        errorMessage(res.message);
      }
    };
    const delTask = (row) => {
      erpConfirm('确定删除该条记录？').then(async () => {
        const url = '/malicrm/business/operateFollower';
        const res = await httpPost(url, {
          businessId: row.businessId,
          followerIds: row.followerIds,
          operateType: '3',
          followerType: 1,
        });
        if (res.code === 200) {
          successMessage('已删除');
          refashTable();
          ctx.emit('success');
        } else {
          errorMessage(res.message);
        }
      });
    };
    return {
      delTask,
      tableData,
      queryData,
      getTableDatas,
      operateTask,
      closeVisible,
      visible,
      formType,
      paginationRef,
      rowData,
      hasPermission,
      empty,
      openDialog,
      fundingName,
      isShowElDialog,
    };
  },
});
