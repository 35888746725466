<template>
  <!--国际采购服务 -->
  <div class="mt-20">
    <div class="mali-proitem__header">其他费用（{{ feeTitle }}）</div>
    <div class="mali-proitem__body">
      <table class="productCate__table finan-table mt-20" style="width: 100%; margin-top: 0px" cellspacing="0" cellpadding="0">
        <tr>
          <th width="50%">
            <span>费用名称</span>
          </th>
          <th width="50%">
            <span>收费标准</span>
          </th>
        </tr>
        <tr v-for="(item, index) in data" :key="index">
          <template v-if="item.settleType === 1">
            <td>
              <div class="flex-center">
                <div>操作费<span class="feeNameTip">（垫资的情况下才收取此费用）</span></div>
              </div>
            </td>
          </template>
          <template v-else>
            <td class="port__inner">{{ emptyName(item.feeName) }}</td>
          </template>
          <td class="port__inner">{{ emptyName(item.feeStandard) }}{{ item.feeUnitDesc }}</td>
        </tr>
      </table>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    settleType: [Number],
    data: {
      type: [Array],
      default: function () {
        return [];
      },
    },
  },
  computed: {
    feeTitle() {
      let res = '按天计费';
      if (this.settleType === 2) {
        res = '包干价';
      }
      return res;
    },
  },
  methods: {
    emptyName(val) {
      if (val === '' || val === null) {
        return '-';
      } else {
        return val;
      }
    },
    appendUnit(val) {
      if (val) {
        return val + '%';
      } else {
        return '-';
      }
    },
  },
};
</script>

<style></style>
