
import { httpPost } from '@/api';
import ChooseMan from '@/components/ChooseMan/ChooseMan.vue';
import {
  ajaxLoading,
  errorMessage,
  setTitleLabel,
  successMessage,
} from '@/utils/publicMethods';
import { defineComponent, computed } from 'vue';
import useFormData from './useData';
export default defineComponent({
  components: { ChooseMan },
  props: {
    /** 新增编辑表单状态 1新增 2编辑 */
    formType: {
      type: [Number],
      default: 1,
    },
    data: {
      type: [Object],
    },
    BusinessId: {
      type: [Number, String],
    },
  },
  emits: ['close'],
  setup(props, ctx) {
    let params: any = {};
    params = Object.assign(
      { businessId: props.BusinessId, operateType: props.formType },
      params
    );
    const { rules, formData, formRef, setFormData } = useFormData(params);
    const readOnly = computed(() => {
      return props.formType > 2;
    });
    const chooseManClose = () => {
      formRef.value.validateField('followerIds');
    };
    const close = (status = false) => {
      ctx.emit('close', status);
    };

    const submitData = async () => {
      const params = {
        ...formData,
      };
      ajaxLoading.lock();
      const res = await httpPost('/malicrm/business/operateFollower', params);
      if (res.code === 200) {
        ajaxLoading.unLock();
        if (props.formType === 1) {
          successMessage('保存成功', 2000);
        } else {
          successMessage('编辑成功', 2000);
        }
        close(true);
      } else {
        ajaxLoading.unLock();
        errorMessage(res.message);
      }
    };
    /** 提交表单 */
    const saveFormData = async () => {
      formRef.value.validate((valid) => {
        if (valid) {
          submitData();
        } else {
          return false;
        }
      });
    };

    if (props.formType > 1) {
      let database = JSON.stringify(props.data);
      database = JSON.parse(database);
      formData.followerIds = (database as any).followerIds;
      formData.follower = (database as any).follower;
    }
    return {
      close,
      formData,
      saveFormData,
      setFormData,
      rules,
      formRef,
      setTitleLabel,
      readOnly,
      chooseManClose,
    };
  },
});
