<template>
  <div>
    <!-- 保险费 -->
    <div style="margin-bottom: 20px">
      <div class="mali-proitem__header">保险费</div>
      <div class="mali-proitem__flex no-margin">
        <div class="mali-flex__label" style="margin-right: 40px">
          保险费率
          <br />（‰）
        </div>
        <div class="mali-flex__form mg-32" style="width: 340px">
          <div class="flex-between">
            {{ empty(showData.insuranceRateDisplay) }}
          </div>
        </div>
      </div>
    </div>
    <!-- 多品名费用 -->
    <div class="mali-proitem__header mt-20" style="border-bottom: 0">多品名费用</div>
    <table class="productCate__table" cellspacing="0" cellpadding="0" style="margin-bottom: 20px">
      <tr>
        <th>收费标准</th>
        <th>最小值</th>
        <th>最大值</th>
        <th>费用(元)</th>
      </tr>
      <tr v-for="(item, index) in showData.multiProductFeeConfigList" :key="index">
        <td style="height: 40px; vertical-align: middle; text-align: center">
          {{ empty(item.feeTypeDesc) }}
        </td>
        <td style="height: 40px; vertical-align: middle; text-align: center">
          {{ empty(item.configMinDisplay) }}
        </td>
        <td style="height: 40px; vertical-align: middle; text-align: center">
          {{ empty(item.configMaxDisplay) }}
        </td>
        <td style="height: 40px; vertical-align: middle; text-align: center">
          {{ empty(item.feeDisplay) }}
        </td>
      </tr>
    </table>
    <!-- 多品类费用 -->
    <div class="mt-20" style="margin-bottom: 20px">
      <div class="mali-proitem__header">多品类费用</div>
      <div class="mali-proitem__flex no-margin">
        <div class="mali-proitem__item" style="margin-right: 48px">
          <div class="mali-flex__label" style="margin-right: 15px">
            开始收费
            <br />的品种数
          </div>
          <div class="mali-flex__form mg-32" style="width: 340px">
            <div class="flex-between">
              {{ empty(showData.multiVarietyFeeStartCountDisplay) }}
            </div>
          </div>
        </div>
        <div class="mali-proitem__item">
          <div class="mali-flex__label" style="margin-right: 11px">
            费用（元/品种）
            <br />
          </div>
          <div class="mali-flex__form mg-32" style="width: 340px">
            <div class="flex-between">
              {{ empty(showData.multiVarietyFeeDisplay) }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 多次提货费 -->
    <div class="mali-proitem__header mt-20" style="border-bottom: 0">多次提货费</div>
    <table class="productCate__table" cellspacing="0" cellpadding="0" style="margin-bottom: 20px">
      <tr>
        <th>最小值</th>
        <th>最大值</th>
        <th>费用(元/次)</th>
      </tr>
      <tr v-for="(item, index) in showData.multiPickupFeeConfigList" :key="index">
        <td style="height: 40px; vertical-align: middle; text-align: center">
          {{ empty(item.configMinDisplay) }}
        </td>
        <td style="height: 40px; vertical-align: middle; text-align: center">
          {{ empty(item.configMaxDisplay) }}
        </td>
        <td style="height: 40px; vertical-align: middle; text-align: center">
          {{ empty(item.feeDisplay) }}
        </td>
      </tr>
    </table>
    <!-- 指定汇率 -->
    <div class="mt-20" style="margin-bottom: 20px">
      <div class="mali-proitem__header">指定汇率</div>
      <div class="mali-proitem__flex no-margin">
        <div class="mali-flex__label" style="margin-right: 40px">指定汇率</div>
        <div class="mali-flex__form mg-32" style="width: 340px">
          <div class="flex-between" v-if="load">
            {{ empty(prescribedRateDisplay) }}
          </div>
          <div class="flex-between" v-else>{{ prescribedRateFixed }}</div>
        </div>
      </div>
    </div>
    <div class="crm-button__group" style="padding: 20px">
      <button class="crm-form__button primary" @click="cancel">关闭</button>
    </div>
  </div>
</template>

<script>
export default {
  props: ['customerContract', 'currency', 'oppId'],
  components: {},
  data() {
    return {
      rate: [],
      prescribedRateFixed: null,
      load: false,
      showData: {},
    };
  },
  created() {
    this.getRateData();
    this.getRateDataByBussinessId(this.oppId, this.isTrans);
  },
  mounted() {
    this.showData = Object.assign({}, this.customerContract);
  },
  computed: {
    prescribedRateDisplay() {
      return this.rate[this.currency - 1];
    },
  },
  methods: {
    empty(data) {
      if (data && data !== '') {
        return data;
      } else {
        return '-';
      }
    },
    getRateDataByBussinessId(oppId, isTrans) {
      if (!oppId) {
        return;
      }
      let url = `malicrm/business/getPrescribedRate/${oppId}`;
      if (isTrans) {
        url = '/malicrm/common/getPrescribedRate';
      }
      this.ajax({
        type: 'GET',
        url,
        loading: true,
        success: (res) => {
          if (res.code === 200) {
            if (res.result) {
              this.prescribedRateFixed = res.result;
              this.load = false;
            } else {
              this.load = true;
              this.getRateData();
            }
          }
        },
      });
    },
    getRateData() {
      this.ajax({
        type: 'GET',
        url: 'malicrm/common/getPrescribedRate',
        loading: true,
        success: (res) => {
          if (res.code === 200) {
            const data = res.result;
            this.rate = Object.values(data.rate);
          }
        },
      });
    },
    cancel() {
      this.$emit('cancel', true);
    },
  },
};
</script>

<style lang="scss" scoped>
.mali-proitem__item {
  display: flex;
  align-items: center;
}
.no-margin {
  padding: 20px 20px 30px 20px;
  .mali-flex__form {
    margin: 0;
  }
}
</style>
