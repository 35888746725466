<template>
  <div>
    <table class="accept-fee-table" cellspacing="0" cellpadding="0">
      <tr>
        <th width="49%">
          <span>自支付尾款之日起至甲方付款提货之日止</span>
        </th>
        <th width="49%">
          <span>服务费(元/公斤)</span>
        </th>
      </tr>
      <tr v-for="(item, index) in showData" :key="index">
        <td>
          <span>{{ item.label }}</span>
        </td>
        <td>
          <span>{{ emptyName(item.fee) }}</span>
        </td>
      </tr>
    </table>
  </div>
</template>
<script>
export default {
  props: ['lumpVos'],
  data() {
    return {
      showData: [{ configMin: null, configMax: null, feeDesc: null }],
      dayArr: ['1-30天', '1-45天', '1-60天', '1-75天', '1-90天'],
    };
  },
  created() {
    if (this.lumpVos && this.lumpVos.length > 0) {
      const lumpVos = [
        {
          label: '1-30天',
          configMin: 1,
          configMax: 30,
          fee: null,
          contractId: null,
          orderId: null,
        },
        {
          label: '1-45天',
          configMin: 31,
          configMax: 45,
          fee: null,
          contractId: null,
          orderId: null,
        },
        {
          label: '1-60天',
          configMin: 46,
          configMax: 60,
          fee: null,
          contractId: null,
          orderId: null,
        },
        {
          label: '1-75天',
          configMin: 61,
          configMax: 75,
          fee: null,
          contractId: null,
          orderId: null,
        },
        {
          label: '1-90天',
          configMin: 76,
          configMax: 90,
          fee: null,
          contractId: null,
          orderId: null,
        },
      ];
      this.lumpVos.forEach((v) => {
        if (v.configMin >= 1 && v.configMax <= 30) {
          lumpVos[0].fee = v.fee;
        }
        if (v.configMin >= 31 && v.configMax <= 45) {
          lumpVos[1].fee = v.fee;
        }
        if (v.configMin >= 46 && v.configMax <= 60) {
          lumpVos[2].fee = v.fee;
        }
        if (v.configMin >= 61 && v.configMax <= 75) {
          lumpVos[3].fee = v.fee;
        }
        if (v.configMin >= 76 && v.configMax <= 90) {
          lumpVos[4].fee = v.fee;
        }
      });
      this.showData = lumpVos;
    }
  },
  methods: {
    emptyName(val) {
      if (val === '' || val === null) {
        return '-';
      } else {
        return val;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.accept-fee-table {
  background: #fff;
  width: 100%;
  box-sizing: border-box;
  border-right: 1px solid #e3e4e6;
  border-bottom: 1px solid #e3e4e6;
  tr {
    th {
      border-left: 1px solid #e3e4e6;
      border-top: 1px solid #e3e4e6;
      height: 49px;
      line-height: 42px;
      text-align: center;
      -webkit-box-sizing: border-box;
      box-sizing: border-box;
      vertical-align: middle;
      font-size: 14px;
      color: #333333;
    }
    td {
      vertical-align: middle;
      border-left: 1px solid #e3e4e6;
      border-top: 1px solid #e3e4e6;
      padding: 6px;
      padding-top: 12px;
      padding-bottom: 12px;
      color: #1a1a1a;
      text-align: center;
      span {
        font-size: 14px;
        color: #1a1a1a;
      }
    }
  }
}
</style>
