import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-719d2f1a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "mali-edit__form" }
const _hoisted_2 = { class: "crm-button__group" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ChooseMan = _resolveComponent("ChooseMan")!
  const _component_el_form_item = _resolveComponent("el-form-item")!
  const _component_el_col = _resolveComponent("el-col")!
  const _component_el_row = _resolveComponent("el-row")!
  const _component_el_form = _resolveComponent("el-form")!
  const _component_CrmFormLayout = _resolveComponent("CrmFormLayout")!

  return (_openBlock(), _createBlock(_component_CrmFormLayout, {
    title: _ctx.setTitleLabel(_ctx.formType, ['添加', '编辑']) + '单证跟单员',
    onClose: _ctx.close
  }, {
    default: _withCtx(() => [
      _createVNode(_component_el_form, {
        model: _ctx.formData,
        "label-position": "top",
        ref: "formRef",
        rules: _ctx.rules
      }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            _createVNode(_component_el_row, { gutter: 80 }, {
              default: _withCtx(() => [
                _createVNode(_component_el_col, { span: 12 }, {
                  default: _withCtx(() => [
                    _createVNode(_component_el_form_item, {
                      label: "跟单员",
                      prop: "followerIds"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_ChooseMan, {
                          titleLabel: "跟单员",
                          onClose: _ctx.chooseManClose,
                          modelValue: _ctx.formData.followerIds,
                          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.formData.followerIds) = $event)),
                          name: _ctx.formData.follower,
                          "onUpdate:name": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.formData.follower) = $event)),
                          defaultCreated: false,
                          defaultChecked: _ctx.formType === 1 && !_ctx.formData.follower.length,
                          placeholder: "请选择跟单员",
                          limt: 10
                        }, null, 8, ["onClose", "modelValue", "name", "defaultChecked"])
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                })
              ]),
              _: 1
            })
          ])
        ]),
        _: 1
      }, 8, ["model", "rules"]),
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("button", {
          class: "crm-form__button primary",
          onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.saveFormData && _ctx.saveFormData(...args)))
        }, " 保存 "),
        _createElementVNode("button", {
          class: "crm-form__button cancel",
          onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.close(false)))
        }, " 取消 ")
      ])
    ]),
    _: 1
  }, 8, ["title", "onClose"]))
}