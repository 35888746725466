<template>
  <div v-loading="loading" element-loading-text="正在提交">
    <el-form ref="form" label-position="top" :show-message="true" :rules="rules" :model="formData">
      <div class="mali-edit__form">
        <el-row :gutter="80">
          <el-col :span="12">
            <el-form-item label="大类" prop="name">
              <el-select v-model="disableData.category" placeholder :disabled="true" class="mali-full__input">
                <el-option v-for="(item, index) in Options.goods_category_label" :key="index" :label="item.label" :value="item.value"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="国家" prop="name">
              <el-select v-model="disableData.country" placeholder :disabled="true" class="mali-full__input">
                <el-option v-for="item in countryList" :key="item.country" :label="item.countryName" :value="item.country"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="厂号" prop="name">
              <el-select
                v-model="disableData.plantNo"
                placeholder
                class="mali-full__input tag-scroll h-40"
                multiple
                :multiple-limit="6"
                style="height: 40px"
                :disabled="true"
                size="mini"
              >
                <el-option v-for="(item, index) in plantList" :key="index" :label="item" :value="item"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="中文件套名" prop="packageName">
              <el-select filterable remote :remote-method="queryPackage" v-model="formData.packageName" placeholder="请输入中文件套名" class="mali-full__input">
                <el-option v-for="item in packageList" :key="item.id" :label="item.plantNoNameCn" :value="item.id"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="每柜重量(kg)" prop="weight">
              <el-input
                :maxlength="9"
                v-model="formData.weight"
                placeholder="请输入每柜重量(kg)"
                clearable
                class="mali-full__input"
                @blur="changeWeight($event, 'weight', 100000, 5)"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item :label="'价格（' + tradeCurrencyName + '/kg）'" prop="price">
              <el-input
                :maxlength="7"
                v-model="formData.price"
                :placeholder="'请输入价格（' + tradeCurrencyName + '/kg）'"
                clearable
                class="mali-full__input"
                @blur="changeWeight($event, 'price', 10000, 4)"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </div>
    </el-form>
    <!-- 新增修改 -->
    <div class="crm-button__group">
      <button class="crm-form__button primary" @click="submit">保存</button>
      <button class="crm-form__button cancel" @click="cancel">取消</button>
    </div>
    <!-- 查看 -->
  </div>
</template>
<script>
import { store } from '@/store';
export default {
  components: {},
  props: {
    qhdcForm: [Object],
    tradeCurrency: [String, Number],
  },
  computed: {
    tradeCurrencyName() {
      const data = ['', '美元', '澳元', '欧元'];
      return data[this.tradeCurrency];
    },
    loading() {
      return this.$store.getters.ajaxLoading;
    },
  },
  mounted() {
    console.log('disableData', this.disableData);
    // this.disableData = Object.assign({}, this.qhdcForm);
  },
  data() {
    return {
      disableData: this.qhdcForm,
      formData: {
        packageName: '',
        weight: null,
        price: null,
      },
      Options: {
        goods_category_label: store.state.dict.options.goods_category_label,
      },
      rules: {
        packageName: [
          {
            required: true,
            message: '请输入中文件套名',
            trigger: 'blur',
          },
        ],
      },
      countryList: [], // 国家下拉列表
      plantList: [], // 厂号下拉列表
      packageList: [],
    };
  },
  methods: {
    submit() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.getProduct();
        }
      });
    },
    getProduct() {
      this.ajax({
        type: 'GET',
        url: `malicrm/label/getProductPackageByOfferId/${this.formData.packageName}`,
        loading: true,
        success: (res) => {
          const { category, country, plantNo, partsList } = res.result;
          const data = [];
          partsList.forEach((v) => {
            const item = {
              labelId: v.labelId,
              url: v.labelUrl,
              category: category,
              country: country,
              id: '',
              nameCn: v.nameCn,
              nameEn: v.nameEn,
              plantNo: plantNo,
              productId: null,
              unitPrice: this.formData.price,
              weight: this.formData.weight ? this._formatFloat(this.formData.weight * (v.rate / 100), 3) : '',
              packageCount: null, // 件数
              subLevelStandard: null, // 二级标
            };
            data.push(item);
          });
          this.formData = {
            packageName: '',
            weight: null,
            price: null,
          };
          this.$emit('get', JSON.parse(JSON.stringify(data)));
        },
      });
    },
    queryPackage(name) {
      this.ajax({
        type: 'POST',
        url: 'malicrm/offer/queryProductPackage',
        data: {
          nameCn: name,
          category: this.qhdcForm.category,
          country: this.qhdcForm.country,
          plantNo: this.qhdcForm.plantNo,
        },
        loading: true,
        success: (res) => {
          this.packageList = res.result;
        },
      });
    },
    getCountry() {
      // 获取国家信息
      this.ajax({
        type: 'POST',
        url: 'malicrm/approval/queryCountry',
        data: {
          category: this.qhdcForm.category,
          country: null,
          plantNo: null,
        },
        loading: true,
        success: (res) => {
          this.countryList = res.result;
        },
      });
    },
    getPlantNo() {
      // 获取厂号
      this.ajax({
        type: 'POST',
        url: 'malicrm/approval/queryPlantNo',
        data: {
          category: this.qhdcForm.category,
          country: this.qhdcForm.country,
          plantNo: null,
        },
        loading: true,
        success: (res) => {
          this.plantList = res.result;
        },
      });
    },
    changeWeight(e, name, maxValue, subDot) {
      let value = e.target.value;
      value = value.replace(/[^\d.]/g, ''); // 清除“数字”和“.”以外的字符
      value = value.replace(/\.{2,}/g, '.'); // 只保留第一个. 清除多余的
      value = value.replace('.', '$#$').replace(/\./g, '').replace('$#$', '.');
      // 只能输入两个小数
      if (name === 'weight') {
        value = value.replace(/^(-)*(\d+)\.(\d\d\d).*$/, '$1$2.$3'); // eslint-disable-line
      } else {
        value = value.replace(/^(-)*(\d+)\.(\d\d).*$/, '$1$2.$3'); // eslint-disable-line
      }

      if (value.indexOf('.') < 0 && value !== '') {
        // 以上已经过滤，此处控制的是如果没有小数点，首位不能为类似于 01、02的金额
        if (value.substr(0, 1) === 0) {
          value = value.substr(1);
        }
      }
      if (value >= maxValue) {
        const index = value.indexOf('.');
        if (index > -1) {
          const intStr = value.substr(0, index - 1);
          const dotStr = value.substr(index);
          value = intStr + dotStr;
        } else {
          value = value.substr(0, subDot);
        }
      }
      this.formData[name] = value ? Number(value) : null;
    },
    cancel() {
      this.$emit('cancel', true);
    },
  },
};
</script>

<style></style>
