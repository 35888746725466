<template>
  <div>
    <!--货品信息 -->
    <table
      class="productCate__table"
      v-if="type === 1"
      :style="type === 4 ? { marginTop: '20px' } : {}"
      cellspacing="0"
      cellpadding="0"
    >
      <tr>
        <th class="th-200">
          <span class>中文品名</span>
        </th>
        <th class="th-320" v-if="type !== 4">
          <span>英文品名</span>
        </th>
        <th v-if="type === 1" style="width: 50px">标签</th>

        <th class="th-140" v-if="type !== 4">
          <span class>价格({{ unit }}/kg)</span>
        </th>

        <th class="th-120">
          <span class>重量(kg)</span>
        </th>
        <th class="th-120" v-if="type !== 4">
          <span class>货值({{ unit }})</span>
        </th>
      </tr>
      <tr v-for="(item, index) in data" :key="index">
        <td class="port__inner" style="max-width: 110px">
          <div class="name_cn_product">
            <el-tooltip
              class="item"
              effect="dark"
              :content="item.nameCn"
              placement="top-start"
              :disabled="
                !item.nameCn || (item.nameCn && item.nameCn.length <= 8)
              "
            >
              <span class="name_cn_product">{{ emptyName(item.nameCn) }}</span>
            </el-tooltip>
          </div>
        </td>
        <td class="port__inner" style="max-width: 110px" v-if="type !== 4">
          <div class="name_cn_product">
            <el-tooltip
              class="item"
              effect="dark"
              :content="item.nameEn"
              placement="top-start"
              :disabled="
                !item.nameEn || (item.nameEn && item.nameEn.length <= 16)
              "
            >
              <span class="name_cn_product">{{ emptyName(item.nameEn) }}</span>
            </el-tooltip>
          </div>
        </td>
        <td style="vertical-align: middle; text-align: center" v-if="type == 1">
          <div style="font-size: 0">
            <img
              v-if="!item.isDisable && !item.url"
              src="../../../../../../assets/images/img_icon_biaoqian.png"
              title
              class="goods-tag"
              style="margin: 0 auto"
              @click.stop="clickTag('')"
            />

            <img
              v-else
              src="../../../../../../assets/images/img_icon_biaoqian_s.png"
              title
              class="goods-tag"
              style="margin: 0 auto"
              @click.stop="clickTag(item.url)"
            />
          </div>
        </td>

        <td class="port__inner" v-if="type !== 4">
          {{ emptyName(item.unitPriceString) }}
        </td>
        <td class="port__inner">
          {{ emptyName(_thousandBitSeparator(item.weight)) }}
        </td>
        <td class="port__inner th-120" v-if="type !== 4">
          {{ emptyName(item.valueString) }}
        </td>
      </tr>
    </table>
    <table
      class="productCate__table"
      v-else
      :style="type === 4 ? { marginTop: '20px' } : {}"
      cellspacing="0"
      cellpadding="0"
    >
      <tr>
        <th class="th-120" style="width: 70px">
          <span>大类</span>
        </th>
        <th class="th-200">
          <span class>中文品名</span>
        </th>
        <th class="th-320" v-if="type !== 4">
          <span>英文品名</span>
        </th>
        <th v-if="type === 1" style="width: 50px">标签</th>
        <th class="th-120">
          <span class>原产国</span>
        </th>
        <th class="th-120">
          <span class>厂号</span>
        </th>
        <th class="th-140" v-if="type !== 4">
          <span class>价格({{ unit }}/kg)</span>
        </th>
        <th class="th-120" v-if="type === 2 || type === 3">
          <span>二级标准</span>
        </th>
        <th class="th-120" v-if="type === 2 || type === 3">
          <span>件数</span>
        </th>
        <th class="th-120">
          <span class>重量(kg)</span>
        </th>
        <th class="th-120" v-if="type !== 4">
          <span class>货值({{ unit }})</span>
        </th>
      </tr>
      <tr v-for="(item, index) in data" :key="index">
        <td class="port__inner" style="min-width: 60px">
          {{ emptyName(item.categoryName) }}
        </td>
        <td class="port__inner" style="max-width: 110px">
          <div class="name_cn_product">
            <el-tooltip
              class="item"
              effect="dark"
              :content="item.nameCn"
              placement="top-start"
              :disabled="
                !item.nameCn || (item.nameCn && item.nameCn.length <= 8)
              "
            >
              <span class="name_cn_product">{{ emptyName(item.nameCn) }}</span>
            </el-tooltip>
          </div>
        </td>
        <td class="port__inner" style="max-width: 110px" v-if="type !== 4">
          <div class="name_cn_product">
            <el-tooltip
              class="item"
              effect="dark"
              :content="item.nameEn"
              placement="top-start"
              :disabled="
                !item.nameEn || (item.nameEn && item.nameEn.length <= 16)
              "
            >
              <span class="name_cn_product">{{ emptyName(item.nameEn) }}</span>
            </el-tooltip>
          </div>
        </td>
        <td style="vertical-align: middle; text-align: center" v-if="type == 1">
          <div style="font-size: 0">
            <img
              v-if="!item.isDisable && !item.url"
              src="../../../../../../assets/images/img_icon_biaoqian.png"
              title
              class="goods-tag"
              style="margin: 0 auto"
              @click.stop="clickTag('')"
            />
            <img
              v-else
              src="../../../../../../assets/images/img_icon_biaoqian_s.png"
              title
              class="goods-tag"
              style="margin: 0 auto"
              @click.stop="clickTag(item.url)"
            />
          </div>
        </td>
        <td class="port__inner">{{ emptyName(item.countryName) }}</td>
        <td class="port__inner" style="max-width: 110px">
          <div class="name_cn_product">
            <el-tooltip
              class="item"
              effect="dark"
              :content="item.plantNo"
              placement="top-start"
              :disabled="
                !item.plantNo || (item.plantNo && item.plantNo.length < 11)
              "
            >
              <span class="name_cn_product">{{ emptyName(item.plantNo) }}</span>
            </el-tooltip>
          </div>
        </td>
        <td class="port__inner" v-if="type !== 4">
          {{ emptyName(item.unitPriceString) }}
        </td>
        <!-- 二级标准 -->
        <td class="port__inner" v-if="type == 2 || type == 3">
          {{ emptyName(item.subLevelStandard) }}
        </td>
        <!-- 件数 -->
        <td class="port__inner" v-if="type == 2 || type == 3">
          {{ emptyName(item.packageCount) }}
        </td>

        <td class="port__inner">
          {{ emptyName(_thousandBitSeparator(item.weight)) }}
        </td>
        <td class="port__inner th-120" v-if="type !== 4">
          {{ emptyName(item.valueString) }}
        </td>
      </tr>
    </table>
    <el-dialog :append-to-body="true" v-model:visible="dialogVisible">
      <img width="100%" :src="dialogImageUrl" />
    </el-dialog>
  </div>
</template>

<script>
export default {
  props: {
    type: [String, Number],
    currencyString: [String],
    data: {
      type: [Array],
      default: function () {
        return [];
      },
    },
  },
  data() {
    return {
      dialogImageUrl: '',
      dialogVisible: false,
    };
  },
  computed: {
    unit() {
      const str = ['美元', '美元', '元', '元', ''];
      if (this.type === 1) {
        return this.currencyString;
      } else {
        return str[Number(this.type)];
      }
    },
  },
  methods: {
    _thousandBitSeparator(num) {
      if (typeof num === 'number') {
        num = String(num);
      }
      let str = '';
      if (num) {
        if (num && num.indexOf('.') !== -1) {
          str = num.replace(/(\d)(?=(\d{3})+\.)/g, function ($0, $1) {
            return $1 + ',';
          });
        } else {
          str = num.replace(/(\d{1,3})(?=(\d{3})+$)/g, function ($0, $1) {
            return $1 + ',';
          });
        }
      } else {
        str = '';
      }
      return str;
    },
    clickTag(url) {
      if (url) {
        const link = document.createElement('a');
        link.href = url;
        link.target = '_blank';
        link.click();
      }
    },
    emptyName(val) {
      if (val === '' || val === null) {
        return '-';
      } else {
        return val;
      }
    },
  },
};
</script>

<style>
.name_cn_product {
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  display: block;
}
.mt-10 {
  margin-top: 10px;
}
.goods-tag {
  cursor: pointer;
}
</style>
