<template>
  <CrmFormLayout :title="formType === 1 ? '新增抬头' : '编辑抬头'" @close="close">
    <el-form ref="form" label-position="top" :show-message="true" :rules="formType === 3 ? rulese : rules" :model="formData">
      <div class="mali-edit__form input-fix">
        <el-row :gutter="80">
          <el-col :span="12" v-if="isBusiness">
            <el-form-item label="资金方" prop="fundingId">
              <el-select
                v-model="formData.fundingId"
                placeholder="请输入资金方"
                class="mali-full__input"
                filterable
                remote
                @change="getupdataList('')"
                @blur="initriseList"
                :filter-method="getriseList"
              >
                <el-option v-for="item in riseList" :key="item.shortName" :label="item.shortName" :value="item.id"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12" v-if="isBusiness">
            <el-form-item label="抬头" prop="fundingQuotaId" :rules="[{ required: true, message: '请输入抬头', trigger: 'blur' }]">
              <el-select
                ref="consigneeShortName"
                v-model="consigneeData"
                placeholder="请输入抬头"
                class="mali-full__input"
                filterable
                value-key="consigneeConcat"
                remote
                @focus="getupheadList('')"
                @change="hcagupheadList"
                :filter-method="getupheadList"
                v-if="loadConsignee"
              >
                <el-option v-for="item in headList" :key="item.consigneeConcat" :label="item.consigneeShortName" :value="item">
                  <span style="float: left">{{ item.consigneeShortNamenum }}</span>
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12" v-else>
            <el-form-item label="抬头" prop="consigneeShortName">
              <el-select
                v-model="formData.consigneeShortName"
                placeholder="请输入抬头"
                class="mali-full__input"
                filterable
                remote
                @focus="getupheadList('')"
                @change="getupheadList"
                :filter-method="getupheadList"
              >
                <el-option v-for="item in headList" :key="item.shortName" :label="item.shortName" :value="item.shortName"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
      </div>
    </el-form>
    <div class="crm-button__group">
      <button class="crm-form__button primary" @click="submit" v-if="formType < 3">保存</button>
      <button class="crm-form__button cancel" @click="close" v-if="formType < 3">取消</button>
    </div>
  </CrmFormLayout>
</template>

<script>
export default {
  components: {},
  props: {
    data: {
      // 回填表单数据
      type: [Object],
      default: function () {
        return {};
      },
    },
    formType: {
      // 表单操作类型 1 新增 2 修改 3查看 查看没有操作按钮
      type: [String, Number],
      default: 1,
    },
    source: {
      // 任务类型(来源)
      type: [String, Number],
      default: 1,
    },
    eventId: {
      // 事件ID
      type: [String, Number],
      default: 1,
    },
    BusinessId: {
      // 商机ID
      type: [String],
      default: '',
    },
    businessNo: {
      // 商机编号
      type: [String],
      default: '',
    },
    customerId: {
      // 客户ID
      type: [String, Number],
      default: '',
    },
    isBusiness: {
      type: [Boolean],
      default: false,
    },
    customerName: {
      // 客户名
      type: [String],
      default: '',
    },
  },
  data() {
    return {
      defaultTxt: '根据不同类型显示不同的名字,参考原型',
      signDate: '超级玛丽',
      contactId: null,
      rulese: '',
      wer: false,
      riseList: [],
      headList: [],
      formData: {
        consigneeShortName: '',
        fundingId: '',
        usedAmount: '',
        fundingQuotaId: '',
        businessId: this.BusinessId,
        businessNo: this.businessNo,
      },
      consigneeData: {
        // 绑定的抬头对象
        fundingQuotaId: null, // 占用资金额度id
        consigneeShortName: null, // 抬头名称
        consigneeConcat: null, // 抬头唯一标识符
      },
      rules: {
        fundingId: [
          {
            required: true,
            message: '请输入资金方',
            trigger: 'blur',
          },
        ],
        consigneeShortName: [{ required: true, message: '请输入抬头', trigger: 'blur' }],
      },
      loadConsignee: true,
    };
  },
  methods: {
    focusHeadList() {
      console.log('dd');
    },
    initriseList() {
      // 失去焦点重置下拉，如果不加这个失去焦点 就搜索不到数据，也无法重置条件
      if ((this.riseList && this.riseList.length === 0) || !this.riseList) {
        this.getriseList('');
      }
    },
    vischange(wer) {
      this.wer = wer;
    },
    hcagupheadList(item) {
      // 不要屏蔽这个方法！！！！！ 这个处理bug7427用的
      this.formData.fundingQuotaId = item.fundingQuotaId;
      this.formData.usedAmount = item.usedAmount;
      this.formData.consigneeShortName = item.consigneeShortName;
    },
    getupdataList() {
      //清除数据
      this.consigneeData = null;
      this.formData.consigneeShortName = '';
      this.headList = null;
      this.loadConsignee = false;
      // 可能是select bug ，抬头会有空项渲染 这是不好的体验修改，后期要查看原因的
      setTimeout(() => {
        this.loadConsignee = true;
        this.getupheadList('');
      }, 0);
    },
    // 搜索资金方
    getriseList(value) {
      this.ajax({
        type: 'POST',
        url: '/malicrm/funding/queryByShortName',
        data: {
          fundingShortName: value,
        },
        loading: true,
        success: (res) => {
          if (res.code === 200) {
            this.riseList = res.result;
          } else {
            this.errorTip(res.message);
          }
        },
      });
    },
    // 搜索抬头
    getupheadList(value) {
      if (!this.isBusiness) {
        this.getnozjinList(value);
        return;
      }
      if (!this.formData.fundingId) {
        return;
      }

      this.ajax({
        type: 'POST',
        url: '/malicrm/business/queryConsignee',
        data: {
          fundId: this.formData.fundingId,
          businessId: this.BusinessId,
          name: value,
          source: '1',
        },
        loading: true,
        success: (res) => {
          if (res.code === 200) {
            if (res.result && res.result.length <= 0) {
              this.headList = [];
            } else {
              this.headList = res.result;
              if (this.headList && this.headList.length) {
                this.headList.forEach((item) => {
                  item.consigneeShortNamenum = item.consigneeShortName + '  剩余额度:' + item.leftAmountString;
                });
              }
            }
          } else {
            this.errorTip(res.message);
          }
        },
      });
    },
    // 搜索无资金方抬头
    getnozjinList(value) {
      this.headList = [];
      this.ajax({
        type: 'POST',
        url: '/malicrm/consignee/queryByName',
        data: {
          portName: value,
          consigneeType: '1',
        },
        loading: true,
        success: (res) => {
          if (res.code === 200) {
            this.headList = res.result;
          } else {
            this.errorTip(res.message);
          }
        },
      });
    },
    chackname(e) {
      this.formData.name = e.replace(/[\u0391-\uFFE5A-Za-z]/g, '');
    },
    submit() {
      // 提交操作
      this.$refs.form.validate((valid) => {
        if (valid) {
          // console.log('this.formData', this.formData);
          this.submitForm();
        } else {
          return false;
        }
      });
      // 保存操作,调用新增
    },
    submitForm() {
      // 提交表单
      // 将 '' 或  []  转为null
      this.formData.operateType = this.formType;
      const params = Object.assign({}, this.formData);
      this.ajax({
        type: 'POST',
        url: '/malicrm/business/operateMlConsignee',
        data: {
          ...params,
        },
        loading: true,
        success: (res) => {
          if (res.code === 200) {
            if (this.formType === 1) {
              this.successTip('保存成功', 2000);
            } else {
              this.successTip('编辑成功', 2000);
            }
            this.$emit('close', true);
          } else {
            this.errorTip(res.message);
          }
        },
      });
    },
    close() {
      // 取消操作
      this.$emit('close');
    },
  },
  created() {
    this.getriseList('');
    if (this.formType > 1) {
      // 修改查看就回填数据过来
      const database = JSON.parse(JSON.stringify(this.data));
      this.consigneeData = {
        fundingQuotaId: this.data.fundingQuotaId,
        consigneeShortName: this.data.shortName,
        consigneeConcat: this.data.consigneeConcat,
      };

      for (const key in database) {
        if (database[key] === '-') {
          database[key] = '';
        }
      }
      if (this.isBusiness && database.fundingId) {
        this.ajax({
          type: 'GET',
          url: '/malicrm/business/getFundingName/' + database.fundingId,
          data: null,
          loading: true,
          success: (res) => {
            if (res.code === 200) {
              this.getriseList('');
            } else {
              this.errorTip(res.message);
            }
          },
        });
      }
      console.log(database);
      this.formData.fundingId = database.fundingId; //
      this.formData.consigneeShortName = database.shortName; //
      this.formData.id = database.id; //
      this.formData.fundingQuotaId = database.fundingQuotaId; //
    }
    if (!this.isBusiness) {
      this.getnozjinList('');
    } else {
      this.getupheadList('');
    }
  },
};
</script>
<style scope>
.username-div {
  width: 420px;
  height: 40px;
  color: #000;
  background: rgba(255, 255, 255, 1);
  border: 1px solid rgba(227, 228, 230, 1);
  border-radius: 2px;
}
.username-div-active {
  width: 420px;
  height: 40px;
  line-height: 40px;
  background: #f5f7fa;
  border: 1px solid rgba(227, 228, 230, 1);
  border-radius: 2px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding: 0 10px;
  color: #1a1a1a;
}
.contact-item2-input {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  /* height: 40px; */
}
.contact-item2-input .el-input__inner {
  height: 40px !important;
}
/* input[disabled]{color:rgb(0, 0, 0)!important;opacity:1!important;} */
.input-fix input[disabled],
.input-fix input:disabled,
.input-fix input.disabled {
  color: #333 !important;
  -webkit-text-fill-color: #333 !important;
  -webkit-opacity: 1 !important;
  opacity: 1 !important;
}
.el-textarea.is-disabled .el-textarea__inner {
  background-color: #f5f7fa !important;
  border-color: #e4e7ed !important;
  color: #000 !important;
  cursor: not-allowed !important;
}
.contact-item2-input .username {
  width: 260px;
  height: 40px;
  background: rgba(255, 255, 255, 1);
  border-radius: 2px;
}
.contact-item {
  width: 420px;
  height: 40px;
  background: rgba(255, 255, 255, 1);
  border-radius: 2px;
}
.contact-item .el-input__inner {
  height: 40px !important;
}
.contact-item2-input .line-bg {
  width: 10px;
  height: 2px;
  background: rgba(230, 230, 230, 1);
  border-radius: 1px;
  margin-left: 5px;
  margin-right: 5px;
}
.contact-item2-input .gender {
  width: 140px;
  height: 40px;
  line-height: 40px;
  background: rgba(255, 255, 255, 1);

  border-radius: 2px;
}
</style>
