<template>
  <CrmFormLayout :title="titleLabel" @close="close">
    <el-form ref="form" label-position="top" :show-message="true" :rules="formType != 3 ? rules : {}" :model="formData">
      <div class="mali-edit__form">
        <el-row :gutter="80">
          <el-col :span="12">
            <el-form-item label="合同编号" prop="contractNo">
              <el-input v-model="formData.contractNo" :maxlength="50" clearable placeholder="请输入合同编号" :disabled="isContractNoDisable" class="mali-full__input"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item
              label="签约时间"
              prop="signDate"
              v-if="formData.isCounterSigned && !readOnly"
            >
              <el-date-picker
                v-if="formData.signDate || !readOnly"
                v-model="formData.signDate"
                type="date"
                prefix-icon="null"
                placeholder="上传双签合同时必须按照合同内容填写"
                :disabled="readOnly"
                :editable="false"
                class="mali-full__input"
              ></el-date-picker>
              <el-input v-model="sapcedata" :maxlength="50" v-else :disabled="readOnly" class="mali-full__input"></el-input>
            </el-form-item>
            <el-form-item
              label="签约时间"
              v-else
            >
              <el-date-picker
                v-if="formData.signDate || !readOnly"
                v-model="formData.signDate"
                type="date"
                prefix-icon="null"
                placeholder="上传双签合同时必须按照合同内容填写"
                :disabled="readOnly"
                :editable="false"
                class="mali-full__input"
              ></el-date-picker>
              <el-input v-model="sapcedata" :maxlength="50" v-else :disabled="readOnly" class="mali-full__input"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="客户" prop="customerId">
              <el-tooltip v-if="contractName.length > 27" class="item_uaser" effect="dark" :content="contractName" placement="top-start">
                <div class="username-div-active">{{ contractName }}</div>
              </el-tooltip>
              <div v-else class="username-div-active">{{ contractName }}</div>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="商机" prop="businessId">
              <el-tooltip v-if="businessName.length > 27" class="item_uaser" effect="dark" :content="businessName" placement="top-start">
                <div class="username-div-active">{{ businessName }}</div>
              </el-tooltip>
              <div v-else class="username-div-active">{{ businessName }}</div>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="生效时间" prop="startDate ">
              <el-date-picker
                v-model="formData.startDate"
                v-if="formData.startDate || !readOnly"
                type="date"
                prefix-icon="null"
                placeholder="请选择生效时间"
                :disabled="readOnly"
                :editable="false"
                class="mali-full__input"
              ></el-date-picker>
              <el-input v-model="sapcedata" :maxlength="50" v-else :disabled="readOnly" class="mali-full__input"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="到期时间" prop="endDate ">
              <el-date-picker
                v-model="formData.endDate"
                v-if="!readOnly || formData.endDate"
                type="date"
                prefix-icon="null"
                placeholder="请选择到期时间"
                :disabled="readOnly"
                :editable="false"
                class="mali-full__input"
              ></el-date-picker>
              <el-input v-model="sapcedata" :maxlength="50" v-else :disabled="readOnly" class="mali-full__input"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="合同金额(元)" prop="amount">
              <el-input
                v-model="formData.amount"
                @blur="getAmount($event, 'amount')"
                :maxlength="12"
                clearable
                placeholder="请输入合同金额(元)"
                :disabled="readOnly"
                class="mali-full__input"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="审核状态" prop="auditStatus">
              <el-select v-model="formData.auditStatus" class="mali-full__input" placeholder="请选择审核状态" :class="readOnly ? 'mali-full__Arrow_clo' : ''" :disabled="readOnly">
                <el-option label="待审核" :value="0"></el-option>
                <el-option label="已审核" :value="1"></el-option>
              </el-select>
            </el-form-item>
          </el-col>

          <el-col :span="12">
            <el-form-item label="是否已双签" prop="isCounterSigned">
              <el-select
                v-model="formData.isCounterSigned"
                class="mali-full__input"
                ref="isCounterSigned"
                placeholder="请选择"
                :class="readOnly ? 'mali-full__Arrow_clo' : ''"
                :disabled="canTransToOrder || readOnly"
                @change="clearType"
              >
                <el-option key="true" label="是" :value="true"></el-option>
                <el-option key="2" label="否" :value="false"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item
              label="客户指定汇率"
              prop="prescribedRate"
              v-if="formData.isCounterSigned && !readOnly"
            >
              <el-input
                v-if="!readOnly | formData.prescribedRate"
                v-model="formData.prescribedRate"
                @blur="formatRate($event)"
                :maxlength="5"
                clearable
                :placeholder="readOnly ? '' : '上传双签合同时必须按照合同内容填写'"
                class="mali-full__input"
                :disabled="readOnly || (status === 2 && formType !== 1)"
              ></el-input>
              <el-input v-model="sapcedata" :maxlength="50" v-else :disabled="readOnly" class="mali-full__input"></el-input>
            </el-form-item>
            <el-form-item
              label="客户指定汇率"
              v-else
            >
              <el-input
                v-if="!readOnly | formData.prescribedRate"
                v-model="formData.prescribedRate"
                @blur="formatRate($event)"
                :maxlength="5"
                clearable
                :placeholder="readOnly ? '' : '上传双签合同时必须按照合同内容填写'"
                class="mali-full__input"
                :disabled="readOnly || (status === 2 && formType !== 1)"
              ></el-input>
              <el-input v-model="sapcedata" :maxlength="50" v-else :disabled="readOnly" class="mali-full__input"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </div>
      <div class="mali-edit__title">{{ formType > 2 ? '合同文件' : '选择合同文件' }}<i class="req" v-if="formType !== 3"></i></div>
      <div class="mali-edit__form">
        <el-row :gutter="80">
          <el-col :span="24">
            <el-form-item label="" prop="attachmentDto.url">
              <FileContent :max="1" :fromType="formType" @success="getFileUrls" :fileList="fileUrlsArray"></FileContent>
            </el-form-item>
          </el-col>
        </el-row>
      </div>
      <div class="mali-edit__form">
        <el-row :gutter="80">
          <el-col :span="24">
            <el-form-item :label="formType > 3 ? '备注' : '合同备注'" prop="remark">
              <el-input v-model="formData.remark" :maxlength="1000" :disabled="readOnly" class="mali-full__input" type="textarea" resize="none"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </div>
    </el-form>
    <div class="mail-form__detail" v-if="formType === 3">
      创建人：{{ formData.creator }}
      <span class="word-space"></span>
      创建时间：{{ formData.createdTime }}
      <span class="word-space"></span>
      更新人：{{ formData.lastEditOperator }}
      <span class="word-space"></span>
      更新时间：{{ formData.lastEditTime }}
    </div>
    <div class="crm-button__group">
      <button class="crm-form__button primary" @click="submit" v-if="formType < 3">保存</button>
      <button class="crm-form__button cancel" @click="close" v-if="formType < 3">取消</button>
      <button class="crm-form__button primary" @click="close" v-if="formType > 2">关闭</button>
    </div>
  </CrmFormLayout>
</template>
<script>
import FileContent from '@/components/FileContent/FileContent.vue';
import { formatTime, erpConfirm } from '@/utils/publicMethods';
export default {
  components: {
    FileContent,
  },
  props: {
    customData: [Object],
    data: {
      // 回填表单数据
      type: [Object],
      default: function () {
        return {};
      },
    },
    canTransToOrder: {
      type: [Boolean],
      default: true,
    },
    formType: {
      // 表单操作类型 1 新增 2 修改
      type: [String, Number],
      default: 1,
    },
    status: {
      // 商机状态
      type: [String, Number],
      default: '',
    },
    eventId: {
      // 任务类型(来源)
      type: [String, Number],
      default: 3,
    },
    source: {
      // 任务类型(来源)
      type: [String, Number],
      default: 3,
    },
  },
  computed: {
    loading() {
      return this.$store.getters.ajaxLoading;
    },
    readOnly() {
      return this.formType > 2;
    },
    titleLabel() {
      const arr = ['新建合同', '编辑合同', '合同详情'];
      return arr[this.formType - 1];
    },
  },
  data() {
    return {
      sapcedata: '-',
      businessName: this.customData ? this.customData.name : '',
      contractName: this.customData ? this.customData.customerName : '',
      isContractNoDisable: null,
      titleImage: '',
      fileNameArr: [],
      successDialogData: {
        dialogVisible: false,
        title: '上传双签合同成功后，商机状态将更新为客户已签约，确定要上传吗？',
        confirmButtonText: '确定',
        cancelButtonText: '取消',
      },
      fileUrlArr: [],
      fileUrlsArray: [],
      fileType: '.pdf, .doc, .docx, .jpg, .png, .gif, .txt,.xls,.xlsx,.zip,.PDF,.DOC,.DOCX,.JPG,.PNG,.GIF,.TXT,.XLS,.XLSX,.ZIP',
      formData: {
        prescribedRate: '',
        signDate: '',
        auditStatus: 0,
        allSubordinates: null,
        amount: '',
        attachmentDto: {
          eventId: this.eventId ? this.eventId : '',
          id: '',
          name: '',
          operateType: 1,
          source: 3,
          url: '',
        },
        operateType: this.formType,
        businessId: this.eventId,
        contractNo: this.customData ? this.customData.businessContractNo : '',
        customerId: this.customData ? this.customData.customerId : '',
        endDate: '',
        remark: '',
        startDate: '',
        isCounterSigned: false, // 判断销售合同号是否允许编辑
      },
      disabled: false,
      rules: {
        contractNo: [
          {
            required: true,
            message: '请输入合同编号',
            trigger: 'blur',
          },
        ],
        amount: [
          {
            pattern: /\d{0,9}(\.\d{0,2})?/,
            message: '请输入合法数字',
            trigger: 'blur',
          },
        ],
        prescribedRate: [
          {
            required: true,
            message: '请输入客户指定汇率',
            trigger: 'blur',
          }
        ],
        signDate: [
          {
            required: true,
            message: '请输入签约时间',
            trigger: 'blur',
          }
        ],
        customerId: [
          {
            required: true,
            message: '请输入客户',
            trigger: 'blur',
          },
        ],
        businessId: [
          {
            required: true,
            message: '请选择商机',
            trigger: 'blur',
          },
        ],
        auditStatus: [
          {
            required: true,
            message: '请选择审核状态',
            trigger: 'blur',
          },
        ],
        'attachmentDto.url': [
          {
            required: true,
            message: '请选择合同文件',
            trigger: 'blur',
          },
        ],
      },
    };
  },
  methods: {
    clearType() {
      // this.$refs.form.clearValidate();
    },
    formatRate(e) {
      // 格式化汇率格式
      let value = e.target.value;
      value = value.replace(/[^\d.]/g, ''); // 清除“数字”和“.”以外的字符
      value = value.replace(/\.{2,}/g, '.'); // 只保留第一个. 清除多余的
      value = value.replace('.', '$#$').replace(/\./g, '').replace('$#$', '.');
      // 只能输入两个小数
      value = value.replace(/^(-)*(\d+)\.(\d\d).*$/, '$1$2.$3'); // eslint-disable-line
      if (value.indexOf('.') < 0 && value !== '') {
        // 以上已经过滤，此处控制的是如果没有小数点，首位不能为类似于 01、02的金额
        if (value.substr(0, 1) === 0) {
          value = value.substr(1);
        }
      }
      if (value.lastIndexOf('.') === value.length - 1) {
        value = value.replace('.', '');
      }
      if (value >= 100) {
        const index = value.indexOf('.');
        if (index > -1) {
          const intStr = value.substr(0, index - 1);
          const dotStr = value.substr(index);
          value = intStr + dotStr;
        } else {
          value = value.substr(0, 2);
        }
      }
      if (Number(value) === 0) {
        this.formData.prescribedRate = null;
      } else {
        this.formData.prescribedRate = value;
      }
    },
    getAmount(e, prop) {
      let value = e.target.value;
      value = value.replace(/[^\d.]/g, ''); // 清除“数字”和“.”以外的字符
      value = value.replace(/\.{2,}/g, '.'); // 只保留第一个. 清除多余的
      value = value.replace('.', '$#$').replace(/\./g, '').replace('$#$', '.');
      // 只能输入两个小数
      value = value.replace(/^(-)*(\d+)\.(\d\d).*$/, '$1$2.$3'); // eslint-disable-line
      if (value.indexOf('.') < 0 && value !== '') {
        // 以上已经过滤，此处控制的是如果没有小数点，首位不能为类似于 01、02的金额
        // eslint-disable-next-line eqeqeq
        if (value.substr(0, 1) == '0') {
          // eslint-disable-line

          value = value.substr(1);
        }
      }
      if (value.lastIndexOf('.') === value.length - 1) {
        value = value.replace('.', '');
      }
      this.formData[prop] = value;
    },
    getDetails(id) {
      this.ajax({
        type: 'GET',
        url: `malicrm/contract/contractDetail/${id}`,
        loading: true,
        success: (res) => {
          if (res.code === 200) {
            const data = res.result;
            this.businessName = data.businessName;
            this.contractName = data.customerName;
            this.formData.id = id;
            this.formData = Object.assign(this.formData, data);
            this.formData.attachmentDto.name = data.fileName;
            this.formData.attachmentDto.url = data.fileUrl;
            this.formData.contractNo = data.contractNo;
            // 新旧销售合同号逻辑修改判断 11.02
            if (this.customData.businessContractNo) {
              // 如果是新商机则都禁用不可编辑
              this.isContractNoDisable = true;
            } else {
              // 旧版商机，按照原先旧逻辑出里
              this.isContractNoDisable = this.readOnly || (this.status === 2 && this.formType !== 1);
            }

            if (this.formType === 3 && this.formData.amount) {
              this.formData.amount = this.formData.amount.toLocaleString();
            }
            this.fileUrlsArray = [
              {
                name: data.fileName,
                url: data.fileUrl,
              },
            ];
            if (!this.formData.remark && this.formType === 3) {
              this.formData.remark = '-';
            }
            if (!this.formData.amount && this.formType === 3) {
              this.formData.amount = '-';
            }
          } else {
            this.errorTip(res.message);
          }
        },
      });
    },
    getFileUrls(fileList) {
      if (fileList && fileList.length > 0) {
        this.formData.attachmentDto.name = fileList[0].name;
        this.formData.attachmentDto.url = fileList[0].url;
        this.$refs.form.validateField(['attachmentDto.url']);
      } else {
        this.formData.attachmentDto.name = null;
        this.formData.attachmentDto.url = null;
      }
      this.disabled = false;
    },
    isMassge() {
      // 点击添加文件时禁用保存按钮
      this.disabled = true;
    },
    submit() {
      // 提交操作
      this.$refs.form.validate((valid) => {
        if (valid) {
          if (this.formData.attachmentDto.url && this.formData.attachmentDto.url !== '') {
            if (this.formData.isCounterSigned) {
              erpConfirm('上传双签合同成功后，商机状态将更新为客户已签约，确定要上传吗？').then(() => {
                this.submitForm();
              });
              this.successDialogData.dialogVisible = true;
            } else {
              this.submitForm();
            }
          } else {
            this.errorTip('请选择合同文件');
            this.disabled = true; // 防止保存按钮重复点击
            setTimeout(() => {
              this.disabled = false;
            }, 3000);
          }
        } else {
          return false;
        }
      });
    },
    submitForm() {
      // 提交表单
      if (!this.disabled) {
        this.disabled = true;
        const params = Object.assign({}, this.formData);
        params.signDate = formatTime(params.signDate, 'YYYY-MM-DD');
        params.startDate = formatTime(params.startDate, 'YYYY-MM-DD');
        params.endDate = formatTime(params.endDate, 'YYYY-MM-DD');
        this.ajax({
          type: 'POST',
          url: 'malicrm/contract/operateContract',
          data: {
            ...params,
          },
          loading: true,
          success: (res) => {
            if (res.code === 200) {
              if (this.formType === 1) {
                this.successTip('添加成功', 2000);
              } else {
                this.successTip('编辑成功', 2000);
              }
              this.$emit('close', true);
              this.successDialogData.dialogVisible = false;
              this.disabled = false;
            } else {
              this.errorTip(res.message);
              this.successDialogData.dialogVisible = false;
              this.disabled = false;
            }
          },
        });
      }
    },
    close() {
      // 取消操作
      this.$emit('close', false);
    },
  },
  created() {
    if (this.formType > 1) {
      // 修改就回填数据过来
      this.getDetails(this.data.id);
    } else {
      this.formData.source = 3;
    }
    if (this.customData && this.customData.businessContractNo) {
      this.isContractNoDisable = true;
    } else {
      this.isContractNoDisable = false;
    }
  },
};
</script>

<style lang="scss" scoped>
.username-div-active {
  width: 420px;
  height: 40px;
  line-height: 40px;
  background: #f5f7fa;
  border: 1px solid rgba(227, 228, 230, 1);
  border-radius: 2px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding: 0 10px;
  color: #1a1a1a;
}
.mail-form__detail {
  .word-space {
    display: inline-block;
    width: 16px;
    height: 10px;
  }
}
</style>
