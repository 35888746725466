
import { defineComponent, ref } from 'vue';
import Pagination from '@/components/Pagination/Pagination.vue';
import usePagination from '@/hooks/usePagination';
import { erpConfirm, errorMessage, setTitleNum, showFiles, hasPermission, ajaxLoading } from '@/utils/publicMethods';
import useVisible from '@/hooks/useVisible';

import ViewForm from './form.vue';
import { httpPost } from '@/api';
export default defineComponent({
  /** data 客户或 商机信息 */
  props: ['isHidden', 'customData', 'status', 'eventId'],
  components: { Pagination, ViewForm },
  emits: ['getSize', 'success'],
  setup(props, ctx) {
    const queryData = {
      eventId: props.eventId,
      pageSize: 5,
    };
    const { paginationRef, tableData, refashTable, empty } = usePagination();
    const formType = ref(1); // 1新增2编辑 3删除
    const rowData = ref();
    const { visible } = useVisible();
    // 获取表格数据操作
    const getTableDatas = (data: Array<any>, total: number) => {
      tableData.value = data;
      ctx.emit('getSize', setTitleNum('电子合同', total));
    };
    const downLoad = (row) => {
      showFiles(row.signedFileName, row.signedFileUrl, false);
    };
    // 新增编辑操作
    const operateTask = (type, row?) => {
      formType.value = type;
      if (row) {
        rowData.value = row;
      }
      visible.value = true;
    };
    // 表单关闭操作，重新触发数据更新
    const closeVisible = (status) => {
      ajaxLoading.unLock();
      visible.value = false;
      refashTable();
      if (status) {
        ctx.emit('success');
      }
    };
    const delTask = (row) => {
      erpConfirm('确定撤销本合同吗？').then(async () => {
        const url = '/malicrm/eqb/electricContract/cancelEqbElectricContractAuth';
        const res = await httpPost(url, {
          ...row,
          operateType: 3,
        });
        if (res.code === 200) {
          refashTable();
          ctx.emit('success');
        } else {
          errorMessage(res.message);
        }
      });
    };
    return {
      delTask,
      tableData,
      queryData,
      getTableDatas,
      operateTask,
      closeVisible,
      visible,
      formType,
      paginationRef,
      hasPermission,
      rowData,
      empty,
      showFiles,
      downLoad,
    };
  },
});
